import { get } from 'lodash';
import * as React from 'react';
import { Query, useMutation } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

// Components
import { asyncValidator, errorHandler } from '@components/Form';
import Modal from '@components/Modal';

import Account from './components/Account';
import Item from './components/Item';

// Containers
import AccountForm from './containers/AccountForm';
import CodeForm from './containers/CodeForm';

// Data
import {
  ACCOUNT_CODE_FORM_ID,
  ACCOUNT_CODE_MODAL_ID,
  PROVIDER_FORM_ID,
  PROVIDER_MODAL_ID
} from './data';

// GraphQL
import createAccountMutation from './graphql/createAccount.graphql';
import updateAccountMutation from './graphql/updateAccount.graphql';
import getProvidersQuery from './graphql/getProviders.graphql';

import enterCodeMutation from './graphql/enterCode.graphql';

// Services
import { closeModal } from '@services/modals';

// Styles
import styles from './Providers.scss';

const Providers = () => {
  const dispatch = useDispatch();

  // Account
  const [createAccount] = useMutation(createAccountMutation);
  const [updateAccount] = useMutation(updateAccountMutation);

  // Code
  const [enterCode] = useMutation(enterCodeMutation);

  const handleCodeFormSubmit = ({ id, code }) =>
    enterCode({
      refetchQueries: [{ query: getProvidersQuery }],
      variables: { id: parseInt(id, 10), code }
    })
      .then(() => dispatch(closeModal(ACCOUNT_CODE_MODAL_ID)))
      .catch(errorHandler);

  const handleAccountFormSubmit = ({ id, name, providerId, ...values }) => {
    const mutate = id ? updateAccount : createAccount;
    const prefs = JSON.stringify(values);

    return mutate({
      refetchQueries: [{ query: getProvidersQuery }],
      variables: { id: parseInt(id || providerId, 10), name, prefs }
    })
      .then(() => dispatch(closeModal(PROVIDER_MODAL_ID)))
      .catch(errorHandler);
  };

  return (
    <Query pollInterval={1000} query={getProvidersQuery}>
      {({ data, loading }) => {
        const accounts = get(data, 'getABAccounts', []);
        const providers = get(data, 'getABProviders', []);

        return (
          <div className={styles.Root}>
            {!loading && (
              <>
                {accounts && accounts.length > 0 && (
                  <div className={styles.Section}>
                    <h2 className={styles.Title}>
                      <FormattedMessage
                        defaultMessage="My accounts"
                        id="providers.my.title"
                      />
                    </h2>

                    <div className={styles.Grid}>
                      {accounts.map(account => {
                        const provider = providers.find(
                          ({ id }) =>
                            id.toString() === account.providerId.toString()
                        );

                        return (
                          <Account
                            {...account}
                            key={account.id}
                            provider={provider}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className={styles.Section}>
                  <h2 className={styles.Title}>
                    <FormattedMessage
                      defaultMessage="Providers"
                      id="providers.title"
                    />
                  </h2>

                  <div className={styles.Grid}>
                    {providers.map(provider => (
                      <Item {...provider} key={provider.id} />
                    ))}
                  </div>
                </div>
              </>
            )}

            <Modal id={ACCOUNT_CODE_MODAL_ID} title="providers.enterCode.title">
              {({ data }) => (
                <CodeForm
                  {...data}
                  form={ACCOUNT_CODE_FORM_ID}
                  onSubmit={handleCodeFormSubmit}
                />
              )}
            </Modal>

            <Modal id={PROVIDER_MODAL_ID} title="providers.add.title">
              {({ data }) =>
                data && (
                  <AccountForm
                    asyncValidate={
                      data.validate && asyncValidator(data.validate)
                    }
                    form={`${PROVIDER_FORM_ID}_${data.id}`}
                    initialValues={data.initialValues}
                    onSubmit={handleAccountFormSubmit}
                    pattern={data.pattern}
                  />
                )
              }
            </Modal>
          </div>
        );
      }}
    </Query>
  );
};

export default Providers;
