import moment from 'moment';
import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Responsive from 'react-responsive';
import { compose, withHandlers, withState } from 'recompose';

// Components
import Block from '@components/Block';
import Button from '@components/Button';
import { CustomInput } from '@components/Form/components/Input';

import Item from './components/Item';
import Table from './components/Table';

// Containers
import Tree from './containers/Tree';

// GraphQL
import getStructureQuery from '@graphql/getStructure.graphql';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Partners.scss';
import { USER_MODAL_ID } from '@containers/User';

const Partners = ({
  history,
  // Handlers
  handleChangeMode,
  handleCompressClick,
  handlePartnerClick,
  setSearchString,
  // State
  mode,
  isCompressed,
  searchString
}: PartnersPropTypes): React.Element<'div'> => (
  <Query query={getStructureQuery}>
    {({ data, loading }) => {
      const productTypes = get(data, 'getProductTypes', []);
      const threshold: number = get(data, 'getSummary.inputThreshold', 0);

      const date = get(data, 'getStructure.updatedAt');
      const formattedArray: Array = get(data, 'getStructure.structure', []).map(
        item => {
          const avatar: number = get(item, 'user.avatar');
          const achieved: boolean = get(item, 'inputBase', 0) > threshold;

          return {
            id: get(item, 'user.id'),
            attributes: {
              date: moment(get(item, 'user.createdAt')).format('DD.MM.YYYY'),
              email: get(item, 'user.email'),
              level: get(item, isCompressed ? 'levelCompressed' : 'levelAbs'),
              phone: get(item, 'user.phone'),
              plan: get(item, 'user.plan.name'),
              skype: get(item, 'user.skype'),
              telegram: get(item, 'user.telegram')
            },
            image: avatar && `${window.API_URL}/files/link/${avatar}`,
            isAchieved: achieved,
            name: `${get(item, 'user.firstname') || ''} ${get(
              item,
              'user.surname'
            ) || ''}`,
            nodeSvgShape: {
              shape: 'circle',
              shapeProps: {
                fill: avatar ? `url(#${get(item, 'user.id')})` : 'default',
                stroke: achieved ? '#0bb07b' : '#8a94a6',
                strokeWidth: 4,
                r: 40
              }
            },
            parentId: isCompressed
              ? get(item, 'sponsorCompressed.id')
              : get(item, 'sponsor.id'),
            products: (get(item, 'user.products') || []).map(
              ({ productTypeId }) =>
                productTypes.find(({ id }) => id === productTypeId.toString())
            ),
            userIdTo: get(item, 'user.id')
          };
        }
      );

      const filteredArray =
        formattedArray &&
        formattedArray.length > 1 &&
        formattedArray.filter(
          ({ id, attributes, name }) =>
            id.toString().indexOf(searchString) > -1 ||
            attributes.email.toLowerCase().indexOf(searchString) > -1 ||
            name.toLowerCase().indexOf(searchString) > -1
        );

      return (
        <div className={style.Root} id="partners:root">
          <Responsive minWidth={769}>
            <div className={style.Actions}>
              <Button color="success" onClick={handleChangeMode} size="small">
                <FormattedMessage
                  defaultMessage={mode === 'table' ? 'Graph' : 'Table'}
                  id={`partners.view.${mode === 'table' ? 'graph' : 'table'}`}
                />
              </Button>

              {threshold !== 0 && mode === 'graph' && (
                <Button onClick={handleCompressClick} size="small">
                  <FormattedMessage
                    defaultMessage={!isCompressed ? 'Compressed' : 'Origin'}
                    id={`partners.actions.${
                      !isCompressed ? 'compressed' : 'origin'
                    }`}
                  />
                </Button>
              )}

              <span className={style.Date}>
                {moment(date).format('DD MMM HH:mm')}
              </span>
            </div>

            {formattedArray && formattedArray.length > 0 && (
              <>
                {mode === 'table' ? (
                  <Block
                    className={style.Table}
                    title={
                      <FormattedMessage
                        defaultMessage="Partner list ({count})"
                        id="partners.title"
                        values={{ count: formattedArray.length }}
                      />
                    }
                  >
                    <div className={style.Search}>
                      <CustomInput
                        disableTrim
                        name="search"
                        onChange={event =>
                          setSearchString(
                            get(event, 'target.value', '').toLowerCase()
                          )
                        }
                        placeholder="partners.search.placeholder"
                        value={searchString}
                      />
                    </div>

                    <Table
                      data={filteredArray || []}
                      onTrClick={partner => handlePartnerClick(partner)}
                    />
                  </Block>
                ) : (
                  <Tree
                    data={formattedArray}
                    loading={loading}
                    onClick={partner => handlePartnerClick(partner)}
                  />
                )}
              </>
            )}
          </Responsive>

          <Responsive maxWidth={768}>
            <Block
              title={
                <FormattedMessage
                  defaultMessage="Partner list ({count})"
                  id="partners.title"
                  values={{ count: filteredArray.length }}
                />
              }
            >
              {(filteredArray || []).map(partner => (
                <Item
                  {...partner}
                  key={partner.id}
                  onClick={() => handlePartnerClick(partner)}
                />
              ))}
            </Block>
          </Responsive>
        </div>
      );
    }}
  </Query>
);

export default compose(
  connect(
    null,
    { openModal }
  ),
  withState('isCompressed', 'setCompressStatus', false),
  withState('mode', 'setMode', 'table'),
  withState('searchString', 'setSearchString', ''),
  withHandlers({
    handleCompressClick: ({
      isCompressed,
      setCompressStatus
    }): Function => (): void => setCompressStatus(!isCompressed),
    handleChangeMode: ({ mode, setMode }): Function => (): void =>
      setMode(mode === 'graph' ? 'table' : 'graph'),
    handlePartnerClick: ({ openModal }): Function => partner =>
      openModal(USER_MODAL_ID, { partner, title: 'partners.modal.title' })
  })
)(Partners);

export type PartnersPropTypes = {
  center: {
    x: number,
    y: number
  }
};
