import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTable from 'react-table';

// Style
import style from './Table.scss';

const Table = props => (
  <div className={style.Root}>
    <ReactTable
      {...props}
      minRows={0}
      noDataText={
        <FormattedMessage
          defaultMessage="No rows found"
          id="common.table.empty"
        />
      }
    />
  </div>
);

export default Table;
