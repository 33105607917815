import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Form, { Input, Select } from '@components/Form';

// Constants
import { IN, PRODUCT } from '@constants/purposes';

// Style
import style from './Form.scss';

const TransferForm = ({
  // Commission
  commissionAmount,
  commissionError,
  rateId,
  total,
  // Form
  error,
  handleSubmit,
  submitting,
  // Props
  accounts,
  currencies,
  partner,
  withdrawMode,
  // State
  currencyIdFrom,
  isLoaded,
  isTransfer
}) => (
  <Form
    className={style.Root}
    error={commissionError || error}
    onSubmit={handleSubmit}
  >
    {partner && (
      <div className={style.Partner}>
        <Avatar className={style.Avatar} value={get(partner, 'image')} />

        <div className={style.Info}>
          <div className={style.Name}>{get(partner, 'name')}</div>
          <div className={style.Email}>{`ID: ${get(partner, 'userIdTo')}, ${get(
            partner,
            'attributes.email'
          )}`}</div>
        </div>
      </div>
    )}

    <div className={style.Form}>
      {!withdrawMode && (
        <div className={style.Group}>
          <Select
            label="withdraw.form.currencyIdFrom.label"
            name="currencyIdFrom"
          >
            {currencies
              .filter(({ active }) => !!active)
              .map(({ id, name, precision, purposes, rate }): React.Element<
                'option'
              > => {
                const account = accounts.find(
                  ({ currencyId }) => currencyId === id
                );

                const isIn = purposes.indexOf(IN) > -1;

                return (
                  (account || isIn) && (
                    <option key={id} value={id}>
                      {account
                        ? `${name} ${(account.value || 0).toFixed(
                            precision || 4
                          )} ($${(rate * (account.value || 0)).toFixed(2)})`
                        : id}
                    </option>
                  )
                );
              })}
          </Select>

          <i className={classNames(style.Icon, 'fal fa-long-arrow-right')} />

          <Select label="transfer.form.currencyIdTo.label" name="currencyIdTo">
            {currencies
              .filter(({ active }) => !!active)
              .filter(
                ({ id, purposes }) =>
                  purposes.indexOf(PRODUCT) > -1 ||
                  (purposes.indexOf(IN) > -1 && id === currencyIdFrom)
              )
              .map(({ id, name }): React.Element<'option'> => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </Select>
        </div>
      )}

      {withdrawMode && (
        <Select
          label="transfer.form.currencyIdFrom.label"
          name="currencyIdFrom"
        >
          {currencies
            .filter(({ active }) => !!active)
            .filter(({ purposes }) => purposes.indexOf(IN) > -1)
            .map(({ id, name, precision, rate }): React.Element<'option'> => {
              const account = accounts.find(
                ({ currencyId }) => currencyId === id
              );

              return (
                account && (
                  <option key={id} value={id}>
                    {`${name} ${(account.value || 0).toFixed(precision)} ($${(
                      rate * (account.value || 0)
                    ).toFixed(2)})`}
                  </option>
                )
              );
            })}
        </Select>
      )}

      {withdrawMode && (
        <Input label="withdraw.form.address.label" name="address" />
      )}

      <Input label="transfer.form.amount.label" name="amount" type="number" />

      {!withdrawMode && (
        <Input label="transfer.form.comment.label" name="comment" />
      )}
    </div>

    {!!commissionAmount && (
      <div className={style.Commission}>
        {isTransfer && (
          <div className={style.CommissionInfo}>
            <strong>
              <FormattedMessage id="transfer.commission.info.title" />
            </strong>
            <br />
            <FormattedMessage id="transfer.commission.info.description" />
          </div>
        )}

        <div className={style.CommissionAmount}>
          <FormattedMessage
            id="transfer.commission.amount"
            values={{ amount: commissionAmount }}
          />
        </div>

        <div className={style.Total}>
          <FormattedMessage id="transfer.commission.total" values={{ total }} />
        </div>
      </div>
    )}

    <div className={style.Actions}>
      <Button
        color="success"
        disabled={!rateId && !withdrawMode}
        fullWidth
        loaded={submitting || isLoaded}
        type="submit"
      >
        <FormattedMessage
          id={`${
            withdrawMode && !isTransfer ? 'withdraw' : 'transfer'
          }.form.submit`}
        />
      </Button>
    </div>
  </Form>
);

export default TransferForm;
