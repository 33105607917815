import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Responsive from 'react-responsive';

// Components
import Avatar from '@components/Avatar';
import Block from '@components/Block';
import Table from '@components/Table';
import { CustomInput } from '@components/Form/components/Input';

import Item from './components/Item';

// Constants
import { NOT_VERIFIED } from '@constants/statuses';

// GraphQL
import getUsers from './graphql/getUsers.graphql';

// Style
import style from './Users.scss';

const COLUMNS = [
  {
    accessor: 'id',
    Header: 'ID',
    resizable: false,
    sortable: false,
    width: 80
  },
  {
    accessor: 'firstname',
    Cell: ({ original, value }) => (
      <div className={style.User}>
        <Avatar className={style.UserAvatar} value={get(original, 'avatar')} />

        <div className={style.UserInfo}>
          <div className={style.UserName}>{`${get(original, 'surname') ||
            ''} ${get(original, 'firstname') || ''}`}</div>

          <div className={style.UserEmail}>{get(original, 'email')}</div>
        </div>
      </div>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="User" id="common.field.user" />
    ),
    style: {
      justifyContent: 'flex-start'
    },
    width: 300
  },
  {
    accessor: 'plan.name',
    Header: () => (
      <FormattedMessage defaultMessage="Plan" id="common.field.plan" />
    ),
    width: 100
  },
  {
    accessor: 'createdAt',
    Header: () => (
      <FormattedMessage
        defaultMessage="Register date"
        id="common.field.date.registration"
      />
    ),
    width: 160
  },
  {
    accessor: 'verifiedStatus',
    Cell: ({ original, value }) => {
      const isBlocked: boolean = get(original, 'blocked');

      return (
        <div
          className={classNames(style.Status, {
            [style.StatusIsErred]: isBlocked || value === NOT_VERIFIED
          })}
        >
          <FormattedMessage
            defaultMessage={
              get(original, 'blocked')
                ? 'Blocked'
                : value === NOT_VERIFIED
                ? 'Unconfirmed'
                : 'Confirmed'
            }
            id={`users.status.${
              get(original, 'blocked')
                ? 'blocked'
                : value === NOT_VERIFIED
                ? 'unconfirmed'
                : 'confirmed'
            }`}
          />
        </div>
      );
    },
    Header: () => (
      <FormattedMessage defaultMessage="Status" id="common.field.status" />
    ),
    width: 140
  }
];

const Admin = ({ history }) => {
  const [searchString, setSearchString] = React.useState('');

  return (
    <Query query={getUsers}>
      {({ data, loading }) => {
        const tableData: Array = get(data, 'getUsers', []).filter(
          ({ id, email, firstname }) =>
            id.indexOf(searchString) > -1 ||
            email.toLowerCase().indexOf(searchString) > -1 ||
            (firstname || '').toLowerCase().indexOf(searchString) > -1
        );

        return (
          <Block
            className={style.Root}
            title={
              <FormattedMessage
                defaultMessage="User list ({count})"
                id="users.table.title"
                values={{ count: tableData.length }}
              />
            }
          >
            <Responsive minWidth={769}>
              <div className={style.Search}>
                <CustomInput
                  disableTrim
                  name="search"
                  onChange={event =>
                    setSearchString(
                      get(event, 'target.value', '').toLowerCase()
                    )
                  }
                  placeholder="partners.search.placeholder"
                  value={searchString}
                />
              </div>

              <Table
                columns={COLUMNS}
                data={tableData}
                getTrProps={(state, { original }) => ({
                  onClick: () => history.push(`/users/${original.id}`)
                })}
                loading={loading}
              />
            </Responsive>

            <Responsive maxWidth={768}>
              {tableData.map((user): React.Element<typeof Item> => (
                <Item
                  {...user}
                  key={user.id}
                  onClick={() => history.push(`/users/${user.id}`)}
                />
              ))}
            </Responsive>
          </Block>
        );
      }}
    </Query>
  );
};

export default Admin;
