import classNames from 'classnames';
import * as React from 'react';

// Style
import style from './Avatar.scss';

const Avatar = ({
  alt = 'DD',
  className,
  value,
  verified
}: AvatarPropTypes): React.Element<'div'> => (
  <div className={classNames(className, style.Root)}>
    <div className={style.Wrapper}>
      {value ? (
        <img
          alt={alt}
          className={style.Image}
          src={
            typeof value === 'number'
              ? `${window.API_URL}/files/link/${value}`
              : value
          }
        />
      ) : (
        <i className={classNames('fas fa-user', style.Icon)} />
      )}
    </div>

    {verified && (
      <i className={classNames('fas fa-badge-check', style.Badge)} />
    )}
  </div>
);

export default Avatar;
export type AvatarPropTypes = {
  alt?: string,
  className?: string,
  value: number | string
};
