import Cookies from 'js-cookie';
import { get, has } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import { compose } from 'recompose';

// GraphQL
import currentUserQuery from '@graphql/currentUser.graphql';

// i18n
import { EN, RU, VN } from '@i18n/lang';
import en from '@i18n/en-US';
import ru from '@i18n/ru-RU';
import vn from '@i18n/vi-VN';

// Services
import { FAILED_RELOGIN_COOKIE_ID, isAuthorized } from '@services/session';

// Style
import style from './App.scss';

// Views
import Landing from '@views/Landing';
import Main from '@views/Main';
import Sign from '@views/Sign';

// Utils
import { resolveUrl } from '@utils/url';

const MESSAGE = {
  [EN]: en,
  [RU]: ru,
  [VN]: vn
};

const App = ({
  isAuthorized,
  location,
  match
}: AppPropTypes): React.Element<'div'> => {
  React.useMemo(() => {
    const match = matchPath(location.pathname, {
      path: '/login/:refCode'
    });

    if (has(match, 'params.refCode')) {
      Cookies.set('refCode', get(match, 'params.refCode'));
    }
  }, [location.pathname]);

  return (
    <Query query={currentUserQuery}>
      {({ data, loading }) => {
        const userLang: string =
          get(data, 'currentUser.lang') ||
          navigator.language ||
          navigator.userLanguage ||
          EN;

        const langId = Object.keys(MESSAGE).find(
          key => key.indexOf(userLang.split('-')[0]) > -1
        );

        return (
          <IntlProvider locale="en" messages={get(MESSAGE, langId, en)}>
            <FormattedMessage
              defaultMessage="DD personal account"
              id="app.title"
            >
              {title => (
                <Helmet>
                  <title>{title}</title>
                </Helmet>
              )}
            </FormattedMessage>

            <FormattedMessage
              defaultMessage="DD Affiliate Program Cabinet"
              id="app.description"
            >
              {description => (
                <Helmet>
                  <meta content={description} name="description" />
                </Helmet>
              )}
            </FormattedMessage>

            <div className={style.Root}>
              <Switch>
                <Route
                  path={resolveUrl([match.url, 'dm', ':code'])}
                  render={props => <Landing {...props} type="node" />}
                />

                <Route
                  path={resolveUrl([match.url, 'sign'])}
                  render={props =>
                    !isAuthorized ? <Sign {...props} /> : <Redirect to="/" />
                  }
                />

                <Route
                  path={resolveUrl([match.url, ''])}
                  render={props =>
                    isAuthorized ? (
                      <Main {...props} />
                    ) : (
                      <Redirect
                        to={`/sign/${
                          !Cookies.get(FAILED_RELOGIN_COOKIE_ID) &&
                          Cookies.get('refCode')
                            ? 'up'
                            : 'in'
                        }`}
                      />
                    )
                  }
                />
              </Switch>
            </div>
          </IntlProvider>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  isAuthorized: isAuthorized(state)
});

export default compose(connect(mapStateToProps))(App);
export type AppPropTypes = {
  isAuthorized: boolean,
  match: {
    url: string
  }
};
