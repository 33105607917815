import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Table from '@components/Table';

// Style
import style from './Table.scss';
import Avatar from '@components/Avatar';
import { get } from 'lodash';

const COLUMNS = [
  {
    accessor: ({ id }) => Number(id),
    id: 'id',
    Header: 'id',
    resizable: false,
    width: 60
  },
  {
    accessor: 'attributes.level',
    Header: () => (
      <FormattedMessage defaultMessage="Level" id="common.field.level" />
    ),
    resizable: false,
    width: 80
  },
  {
    accessor: 'name',
    Cell: ({ original, value }) => (
      <div className={style.User}>
        <Avatar className={style.UserAvatar} value={get(original, 'image')} />

        <div className={style.UserInfo}>
          <div className={style.UserName}>{value}</div>

          <div className={style.UserEmail}>
            {get(original, 'attributes.email')}
          </div>
        </div>
      </div>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="User" id="common.field.user" />
    ),
    style: {
      justifyContent: 'flex-start'
    },
    width: 240
  },
  {
    accessor: 'attributes.date',
    Header: () => (
      <FormattedMessage
        defaultMessage="Register Date"
        id="common.field.date.registration"
      />
    ),
    resizable: false,
    sortMethod: (a, b) => moment(a).isBefore(b),
    width: 140
  },
  {
    accessor: 'attributes.plan',
    Header: () => (
      <FormattedMessage defaultMessage="Plan" id="common.field.plan" />
    ),
    width: 100
  },
  {
    accessor: 'products',
    Cell: ({ value }) => value && value.map(({ name }) => name).join(', '),
    Header: () => (
      <FormattedMessage defaultMessage="Products" id="common.field.products" />
    ),
    style: { whiteSpace: 'break-spaces' },
    width: 200
  }
];

const PartnersTable = ({ data = [], loading, onTrClick }) => (
  <Table
    columns={COLUMNS}
    data={data}
    defaultSorted={[{ id: 'attributes.level', desc: false }]}
    getTrProps={(state, { original }) => ({
      className: classNames({
        [style.RowIsAchieved]: original.isAchieved
      }),
      onClick: () => onTrClick(original)
    })}
    loading={loading}
    minRows={0}
    search
  />
);

export default PartnersTable;
