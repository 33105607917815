import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Responsive from 'react-responsive';
import { Route, Switch } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';

// Constants
import { ADMIN } from '@constants/roles';

// Containers
import Buy from '@containers/Buy';
import Confirm from '@containers/Confirm';
import Docs from '@containers/Docs';
import HelpCode from '@containers/HelpCode';
import Transfer from '@containers/Transfer';
import Verify from '@containers/Verify';
import UserModal from '@containers/User';

import Menu from './containers/Menu';

// Contexts
import CurrentUserContext from '@contexts/CurrentUser';

// Ducks
import { MENU } from './ducks';

// GraphQL
import currentUserQuery from '@graphql/currentUser.graphql';

// Style
import style from './Main.scss';

// Utils
import { resolveUrl } from '@utils/url';

// Views
import Admin from '@views/Admin';
import Dashboard from '@views/Dashboard';
import Logs from '@views/Logs';
import Partners from '@views/Partners';
import Products from '@views/Products';
import Providers from '@views/Providers';
import Settings from '@views/Settings';
import Tasks from '@views/Tasks';
import Transactions from '@views/Transactions';
import Withdraws from '@views/Withdraws';
import User from '@views/User';

const Main = ({
  handleMenuItemClick,
  handleMenuTriggerClick,
  location,
  match,
  menuIsOpened
}: MainPropsType): React.Element<'div'> => (
  <Query query={currentUserQuery}>
    {({ data }) => {
      const isAdmin: boolean =
        get(data, 'currentUser.roles', []).indexOf(ADMIN) > -1;
      const links: Array = get(data, 'getConfig.menu', []);

      const user = get(data, 'currentUser', {});
      const userId: number = parseInt(user.id || 0, 10);

      let headerTitle: string = null;

      MENU.forEach(
        ({ links }): Array =>
          links.forEach(({ exact, title, to }): void => {
            if (
              (exact && to === location.pathname) ||
              (!exact && location.pathname.indexOf(to) !== -1 && !headerTitle)
            ) {
              headerTitle = title;
            }
          })
        // eslint-disable-next-line function-paren-newline
      );

      return (
        <CurrentUserContext.Provider
          value={{ currentUser: get(data, 'currentUser') }}
        >
          <div
            className={classNames(style.Root, {
              [style.RootIsOpened]: menuIsOpened
            })}
          >
            <Responsive maxWidth={767}>
              <div className={style.Header}>
                <button
                  className={style.HeaderAction}
                  onClick={handleMenuTriggerClick}
                  type="button"
                >
                  <i
                    className={classNames(
                      'fal',
                      menuIsOpened ? 'fa-times' : 'fa-bars',
                      style.HeaderIcon
                    )}
                  />
                </button>

                <div className={style.HeaderTitle}>
                  <FormattedMessage
                    defaultMessage="DD"
                    id={headerTitle || 'empty'}
                  />
                </div>

                {window.LOGO && (
                  <div className={style.HeaderLogo}>
                    <img
                      alt="Logo"
                      className={style.LogoImage}
                      src={window.LOGO}
                    />
                  </div>
                )}
              </div>
            </Responsive>

            <div className={style.Sidebar}>
              {window.LOGO && (
                <Responsive minWidth={768}>
                  <div className={style.Logo}>
                    <img
                      alt="Logo"
                      className={style.LogoImage}
                      src={window.LOGO}
                    />
                  </div>
                </Responsive>
              )}

              <div className={style.Menu}>
                <Menu
                  admin={isAdmin}
                  links={links}
                  onItemClick={handleMenuItemClick}
                  user={user}
                />
              </div>
            </div>

            <div className={style.Container}>
              <Switch>
                {isAdmin && (
                  <Route
                    path={resolveUrl([match.url, 'admin'])}
                    render={props => <Admin {...props} userId={userId} />}
                  />
                )}

                {isAdmin && (
                  <Route
                    component={User}
                    path={resolveUrl([match.url, 'users', ':id'])}
                  />
                )}

                <Route
                  component={Logs}
                  path={resolveUrl([match.url, 'logs'])}
                />

                <Route
                  component={Partners}
                  path={resolveUrl([match.url, 'partners'])}
                />

                <Route
                  component={Products}
                  path={resolveUrl([match.url, 'products'])}
                />

                <Route
                  component={Providers}
                  path={resolveUrl([match.url, 'providers'])}
                />

                <Route
                  component={Settings}
                  path={resolveUrl([match.url, 'settings'])}
                />

                <Route
                  component={Tasks}
                  path={resolveUrl([match.url, 'tasks', ':page?'])}
                />

                <Route
                  component={Transactions}
                  path={resolveUrl([match.url, 'transactions'])}
                />

                <Route
                  path={resolveUrl([match.url, 'withdraws'])}
                  render={props => <Withdraws {...props} userId={userId} />}
                />

                <Route
                  component={Dashboard}
                  path={resolveUrl([match.url, ''])}
                />
              </Switch>
            </div>

            <Verify />
            <UserModal />
            <Buy />
            <HelpCode />
            <Transfer />
            <Confirm />
            <Docs />
          </div>
        </CurrentUserContext.Provider>
      );
    }}
  </Query>
);

export default compose(
  withState('menuIsOpened', 'setMenuOpenState', false),
  withHandlers({
    handleMenuItemClick: ({ setMenuOpenState }): Function => (): void =>
      setMenuOpenState(false),
    handleMenuTriggerClick: ({
      menuIsOpened,
      setMenuOpenState
    }): Function => (): void => setMenuOpenState(!menuIsOpened)
  })
)(Main);

export type MainPropsType = {
  match: {
    url: string
  }
};
