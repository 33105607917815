import { get } from 'lodash';
import * as React from 'react';

// Components
import Modal from '@components/Modal';

// Constants
import { PENDING } from '@constants/statuses';
import { VERIFICATION_TYPE_ADMIN } from '@constants/verification';

// Containers
import ConfirmForm from './containers/ConfirmForm';
import RequestForm from './containers/RequestForm';
import Status from './containers/Status';
import User from './containers/User';

// Ducks
import { VERIFY_MODAL_ID } from './ducks';

const Verify = () => (
  <Modal id={VERIFY_MODAL_ID} title="verify.modal.title">
    {({ data, type }) =>
      type === VERIFICATION_TYPE_ADMIN ? (
        <div>
          <User id={get(data, 'user.id')} />
          {get(data, 'status') === PENDING ? (
            <ConfirmForm initialValues={{ id: get(data, 'id') }} />
          ) : (
            <Status {...data} />
          )}
        </div>
      ) : (
        <RequestForm initialValues={{ type }} />
      )
    }
  </Modal>
);

export default Verify;
