import classNames from 'classnames';
import { get, isObject } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

// Components
import Button from '@components/Button';
import Modal from '@components/Modal';

import Toggle from './Toggle';

// Hooks
import useProvider from '../Providers.hooks';

// Services
import { openModal } from '@services/modals';

// Styles
import styles from './Account.scss';

const Result = ({ counters, result }) => {
  const [pattern, setPattern] = React.useState(null);

  React.useEffect(() => {
    if (counters && isObject(result)) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(
        `<counters>${counters}</counters>`,
        'text/xml'
      );

      if (xml) {
        const pattern = Array.prototype.map.call(
          xml.getElementsByTagName('counters')[0].children,
          child => ({
            format: child.getAttribute('format'),
            label: child.getAttribute('name'),
            type: child.getAttribute('type'),
            value: get(result, child.getAttribute('id')),
            units: child.getAttribute('units')
          })
        );

        setPattern(pattern);
      }
    }
  }, [counters, result]);

  return pattern ? (
    <div className={styles.List}>
      {pattern.map(({ format, label, type, value, units }, index) => (
        <div
          key={index}
          className={classNames(styles.Item, {
            [styles.ItemColorBlue]: !type || type === 'number',
            [styles.ItemColorGreen]: type === 'text',
            [styles.ItemColorOrange]: type === 'time',
            [styles.ItemColorPurple]: type === 'boolean'
          })}
        >
          <div className={styles.Label}>{label}</div>
          <div className={styles.Value}>
            {type === 'boolean' ? (value ? 'Yes' : 'No') : null}
            {type === 'time' && moment(value).format(format)}
            {type !== 'boolean' && type !== 'time' ? value : null}
            {units && <span className={styles.Units}>{units}</span>}
          </div>
        </div>
      ))}
    </div>
  ) : typeof result !== 'object' ? (
    result
  ) : null;
};

const ProductsAccount = ({
  id,
  active,
  canActivate,
  executionInfo,
  name,
  onDelete,
  prefs,
  provider,
  tasks = []
}) => {
  const dispatch = useDispatch();

  const task = tasks.find(({ task }) => task === '');
  const status = get(task, 'status', '');

  const codes = get(task, 'codes', []);

  const {
    error,
    errorTime,
    handleActivate,
    handleDelete,
    handleExecute,
    isFailed,
    isParsed,
    openCodeModal,
    openProviderModal,
    result,
    resultTime
  } = useProvider(provider, {
    id,
    active,
    task: {
      codes,
      resultError: get(task, 'resultError', ''),
      resultErrorTime: get(task, 'resultErrorTime'),
      resultSuccess: get(task, 'resultSuccess', ''),
      resultSuccessTime: get(task, 'resultSuccessTime'),
      status
    },
    name,
    payload: prefs
  });

  const handleErrorClick = () =>
    dispatch(openModal(`account-error-${id}`, { error, errorTime }));

  const inProgress: boolean = status === 'INPROGRESS' || status === 'INQUEUE';

  return (
    <div className={styles.Root}>
      <div className={styles.Info}>
        <div className={styles.Header}>
          <div>
            <div className={styles.Title}>
              {name}{' '}
              <FormattedMessage
                defaultMessage={active ? 'On' : 'Off'}
                id={`providers.account.${active ? 'on' : 'off'}.title`}
              >
                {title => (
                  <span
                    className={classNames(styles.Active, {
                      [styles.ActiveIsOff]: !active
                    })}
                    title={title}
                  >
                    <FormattedMessage
                      defaultMessage={active ? 'On' : 'Off'}
                      id={`providers.account.${active ? 'on' : 'off'}`}
                    />
                  </span>
                )}
              </FormattedMessage>
            </div>
            <div className={styles.Name}>
              {resultTime
                ? moment(resultTime).fromNow()
                : get(provider, 'name')}
            </div>
          </div>

          <div className={styles.HeaderRight}>
            {error && (
              <i
                className={classNames(
                  styles.ErrorTrigger,
                  'fad fa-exclamation-triangle'
                )}
                onClick={handleErrorClick}
              />
            )}
            <img
              alt={name}
              className={styles.Logo}
              src={`${window.API_URL}${get(provider, 'icon')}`}
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.Result, {
          [styles.ResultIsFailed]: isFailed
        })}
      >
        <Result counters={get(provider, 'counters')} result={result} />

        {tasks && (
          <div className={styles.TaskList}>
            {tasks.map(
              (
                {
                  resultError,
                  resultErrorTime,
                  resultSuccess,
                  resultSuccessTime,
                  status,
                  task,
                  ...other
                },
                index
              ) => {
                const time =
                  status === 'ERROR' ? resultErrorTime : resultSuccessTime;

                const handleTaskClick = () => {
                  if (status === 'ERROR' || status === 'SUCCESS_PARTIAL') {
                    let error = '';
                    const errorTime =
                      status === 'ERROR' ? resultErrorTime : resultSuccessTime;

                    try {
                      const json =
                        JSON.parse(
                          status === 'SUCCESS_PARTIAL'
                            ? resultSuccess
                            : resultError
                        ) || [];

                      error =
                        status === 'SUCCESS_PARTIAL'
                          ? (json.find(({ error }) => !!error) || {}).message
                          : get(json, '0.message');
                    } catch (e) {
                      // eslint-disable-next-line
                      console.log(e);
                    }

                    error &&
                      dispatch(
                        openModal(`account-error-${id}`, {
                          error,
                          errorTime
                        })
                      );
                  }
                };

                return (
                  task && (
                    <div
                      key={index}
                      className={classNames(styles.Task, {
                        [styles.TaskColorFailed]: status === 'ERROR',
                        [styles.TaskColorInProgress]: status === 'INPROGRESS',
                        [styles.TaskColorInQueue]: status === 'INQUEUE',
                        [styles.TaskColorSuccess]: status === 'SUCCESS',
                        [styles.TaskColorPartial]: status === 'SUCCESS_PARTIAL'
                      })}
                    >
                      <div className={styles.TaskInfo}>
                        <i
                          className={classNames(styles.TaskIcon, {
                            'far fa-hourglass-half': status === 'INQUEUE',
                            'fas fa-check-circle':
                              status === 'SUCCESS_PARTIAL' ||
                              status === 'SUCCESS',
                            'far fa-spinner-third': status === 'INPROGRESS',
                            'far fa-exclamation-triangle': status === 'ERROR'
                          })}
                          onClick={handleTaskClick}
                        />
                        <div className={styles.TaskName}>{task}</div>
                      </div>

                      {time && (
                        <span className={styles.TaskDate}>
                          {moment(time).fromNow()}
                        </span>
                      )}
                    </div>
                  )
                );
              }
            )}
          </div>
        )}
      </div>

      <div className={styles.Actions}>
        <Button
          color={status === 'INQUEUE' ? 'alert' : 'success'}
          icon="fal fa-sync"
          loaded={inProgress}
          onClick={handleExecute}
        />

        {isParsed && (
          <Button
            color="secondary"
            disabled={inProgress}
            icon="fal fa-cog"
            onClick={openProviderModal}
          />
        )}

        <Button
          color="danger"
          disabled={inProgress}
          icon="fal fa-trash-alt"
          onClick={handleDelete}
        />

        {codes && codes.length > 0 ? (
          <Button
            className="animate__animated animate__headShake animate__infinite"
            fullWidth
            onClick={openCodeModal}
          >
            <FormattedMessage
              defaultMessage="Enter code"
              id="providers.account.enterCode"
            />
          </Button>
        ) : (
          <Toggle
            disabled={inProgress || !canActivate}
            onChange={handleActivate}
            value={active}
          />
        )}
      </div>

      <Modal id={`account-error-${id}`} title="providers.process.error">
        {({ error, errorTime }) => (
          <div className={styles.Error}>
            <div className={styles.ErrorTime}>
              {moment(errorTime).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            {error}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ProductsAccount;
