import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Modal from '@components/Modal';

// Containers
import { BUY_MODAL_ID } from '@containers/Buy';
import { TRANSFER_MODAL_ID } from '@containers/Transfer';

// Constants
import { ADMIN } from '@constants/roles';

// Contexts
import CurrentUserContext from '@contexts/CurrentUser';

// Ducks
import { USER_MODAL_ID } from './ducks';

// Services
import { closeModals, openModal } from '@services/modals';

// Style
import style from './User.scss';

// Views
import { Field, Section } from '@views/User';

const User = ({
  handleBuyClick,
  handleProfileClick,
  handleTransferClick,
  history
}) => (
  <CurrentUserContext.Consumer>
    {({ currentUser }) => (
      <Modal id={USER_MODAL_ID}>
        {({ partner }) => {
          const email: string = get(partner, 'attributes.email');
          const phone: string = get(partner, 'attributes.phone');
          const plan: string = get(partner, 'attributes.plan');
          const telegram: string = get(partner, 'attributes.telegram');
          const skype: string = get(partner, 'attributes.skype');

          const isAdmin: boolean =
            get(currentUser, 'roles', []).indexOf(ADMIN) > -1;

          return (
            <div className={style.Root}>
              <div className={style.User}>
                <Avatar
                  className={style.Avatar}
                  value={get(partner, 'image')}
                />
                <div className={style.Name}>{get(partner, 'name')}</div>
              </div>

              <Section
                title={
                  <FormattedMessage
                    defaultMessage="Main Info"
                    id="user.profile.info"
                  />
                }
              >
                <Field
                  title={
                    <FormattedMessage
                      defaultMessage="Date"
                      id="common.field.date.registration"
                    />
                  }
                >
                  {get(partner, 'attributes.date')}
                </Field>

                <Field
                  title={
                    <FormattedMessage
                      defaultMessage="Level"
                      id="common.field.level"
                    />
                  }
                >
                  {get(partner, 'attributes.level')}
                </Field>

                <Field
                  title={
                    <FormattedMessage
                      defaultMessage="Plan"
                      id="common.field.plan"
                    />
                  }
                >
                  {plan}
                </Field>
              </Section>

              <Section
                title={
                  <FormattedMessage
                    defaultMessage="Contacts"
                    id="user.profile.contacts"
                  />
                }
              >
                {email && (
                  <Field
                    title={
                      <FormattedMessage
                        defaultMessage="Email"
                        id="common.field.email"
                      />
                    }
                  >
                    <a href={`mailto:${email}`}>{email}</a>
                  </Field>
                )}

                {phone && (
                  <Field
                    title={
                      <FormattedMessage
                        defaultMessage="phone"
                        id="common.field.phone"
                      />
                    }
                  >
                    <a href={`tel:${phone}`}>{phone}</a>
                  </Field>
                )}

                {telegram && (
                  <Field title="Telegram">
                    <a href={`tg://resolve?domain=${telegram}`}>{telegram}</a>
                  </Field>
                )}

                {skype && (
                  <Field title="Skype">
                    <a href={`skype:${skype}`}>{skype}</a>
                  </Field>
                )}
              </Section>

              <div className={style.Actions}>
                {isAdmin && (
                  <Button
                    color="secondary"
                    onClick={() => handleProfileClick(get(partner, 'userIdTo'))}
                  >
                    <FormattedMessage
                      defaultMessage="Profile"
                      id="user.profile.title"
                    />
                  </Button>
                )}

                <Button fullWidth onClick={() => handleTransferClick(partner)}>
                  <FormattedMessage
                    defaultMessage="Transfer"
                    id="user.actions.transfer"
                  />
                </Button>

                <Button fullWidth onClick={() => handleBuyClick(partner)}>
                  Buy Product
                </Button>
              </div>
            </div>
          );
        }}
      </Modal>
    )}
  </CurrentUserContext.Consumer>
);

export default compose(
  withRouter,
  connect(
    null,
    { closeModals, openModal }
  ),
  withHandlers({
    handleBuyClick: ({ openModal }) => partner =>
      openModal(BUY_MODAL_ID, { data: { partner } }),
    handleProfileClick: ({ closeModals, history }) => id => {
      closeModals();
      history.push(`/users/${id}`);
    },
    handleTransferClick: ({ openModal }) => partner =>
      openModal(TRANSFER_MODAL_ID, { partner })
  })
)(User);
