import * as React from 'react';

import style from './Field.scss';

const UserField = ({
  children,
  title,
  value
}: UserFieldPropTypes): React.Element<'div'> => (
  <div className={style.Root}>
    {title && <div className={style.Title}>{title}:</div>}

    <div className={style.Container}>{value || children}</div>
  </div>
);

export default UserField;
export type UserFieldPropTypes = {
  children: React.Node,
  title: ?string,
  value: ?string
};
