import classNames from 'classnames';
import * as React from 'react';

// Components
import { Field } from '@components/Form';

// Styles
import style from './Textarea.scss';

const FormTextarea = ({
  id,
  disabled,
  error,
  name,
  onChange,
  placeholder,
  value
}) => (
  <div
    className={classNames(style.Root, {
      [style.RootIsDisabled]: !!disabled,
      [style.RootIsErred]: !!error
    })}
  >
    <textarea
      className={style.Textarea}
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  </div>
);

export default props => (
  <Field {...props}>
    <FormTextarea />
  </Field>
);
