import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Utils
import { isExternal } from '@utils/url';

const Link = ({ children, className, to = '/' }) =>
  isExternal(to) ? (
    <a
      className={className}
      href={to}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  ) : (
    <RouterLink className={className} to={to}>
      {children}
    </RouterLink>
  );

export default Link;
