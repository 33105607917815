import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as DOMPurify from 'dompurify';

// Components
import Button from '@components/Button';
import Form, { Input } from '@components/Form';

// Data
import { ACCOUNT_CODE_FORM_ID, ACCOUNT_CODE_MODAL_ID } from '../data';

// Services
import { closeModal } from '@services/modals';

// Styles
import styles from './common.scss';

const ProvidersCodeForm = ({
  description,
  error,
  handleSubmit,
  image,
  inputType,
  submitting
}) => {
  const dispatch = useDispatch();
  const handleCancelClick = () => dispatch(closeModal(ACCOUNT_CODE_MODAL_ID));

  const cleanDescription = DOMPurify.sanitize(description || 'Please enter code');

  return (
    <Form error={error} onSubmit={handleSubmit}>
      {image && (
        <div className={styles.Captcha}>
          <img
            alt="captch"
            className={styles.CaptchImage}
            src={`data:image/png;base64, ${image}`}
          />
        </div>
      )}

      <Input
        disabled={submitting}
        label="providers.form.code.field.code"
        name="code"
        type={inputType === 'number' ? 'number' : 'text'}
      />

      <div style={{marginTop: "15px"}} dangerouslySetInnerHTML={{__html: cleanDescription}}/>

      <div className={styles.Actions}>
        <Button
          color="danger"
          disabled={submitting}
          onClick={handleCancelClick}
        >
          <FormattedMessage
            defaultMessage="Cancel"
            id="providers.item.cancel"
          />
        </Button>

        <Button color="success" loaded={submitting} type="submit">
          <FormattedMessage
            defaultMessage="Submit"
            id="providers.enterCode.submit"
          />
        </Button>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: ACCOUNT_CODE_FORM_ID
})(ProvidersCodeForm);


// Make all links with target=_blank. From here: https://github.com/cure53/DOMPurify/issues/317#issuecomment-912474068
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'

DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      node.setAttribute('target', '_self')
    }

    if (node.hasAttribute('target')) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
    }
  }
})

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
    node.removeAttribute(TEMPORARY_ATTRIBUTE)
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener')
    }
  }
})
