import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { get } from 'lodash';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, errorHandler, Input } from '@components/Form';

import Avatar from '../components/Avatar';
import Group from '../components/Group';

// Constants
import { VERIFICATION_TYPE_SIMPLE } from '@constants/verification';

// Containers
import { VERIFY_MODAL_ID } from '@containers/Verify';

// Ducks
import { SETTINGS_PROFILE_FORM_ID } from '../ducks';

// Graphql
import currentUserQuery from '@graphql/currentUser.graphql';
import updateProfileMutation from '../graphql/updateProfile.graphql';
import updateProfileWithAvatarMutation from '../graphql/updateProfileWithAvatar.graphql';

// Services
import { openModal } from '@services/modals';

// Style
import style from './common.scss';

const SettingsProfileForm = ({
  error,
  handleSimpleVerifyClick,
  handleSubmit,
  initialValues,
  submitting
}: SettingsProfileFormPropTypes): React.Element<typeof Form> => (
  <Form
    classNames={{ error: style.Error }}
    error={error}
    onSubmit={handleSubmit}
  >
    <Group icon="fal fa-user" title="settings.profile.group.about">
      <div className={style.About}>
        <div className={style.Avatar}>
          <Avatar name="avatar" />
        </div>

        <div className={style.Info}>
          <div className={style.Name}>{`${get(initialValues, 'firstname') ||
            ''} ${get(initialValues, 'middlename') || ''} ${get(
            initialValues,
            'surname'
          ) || ''}`}</div>
          <div className={style.Email}>{get(initialValues, 'email')}</div>
        </div>
      </div>
    </Group>

    <Group icon="fal fa-id-card" title="settings.profile.group.fullname">
      <Input
        disabled={submitting}
        label="settings.field.surname"
        name="surname"
      />
      <Input
        disabled={submitting}
        label="settings.field.firstname"
        name="firstname"
      />
      <Input
        disabled={submitting}
        label="settings.field.middlename"
        name="middlename"
      />
    </Group>

    <Group icon="fal fa-map-marker-alt" title="settings.profile.group.location">
      <Input
        disabled={submitting}
        label="settings.field.country"
        name="country"
      />
      <Input disabled={submitting} label="settings.field.city" name="city" />
      <Input
        disabled={submitting}
        label="settings.field.address"
        name="address"
      />
    </Group>

    <Group icon="fal fa-address-book" title="settings.profile.group.contacts">
      <Input disabled={submitting} label="settings.field.phone" name="phone" />
      <Input
        disabled={submitting}
        label="settings.field.telegram"
        name="telegram"
      />
      <Input disabled={submitting} label="settings.field.skype" name="skype" />
    </Group>

    <div className={style.Actions}>
      <Button
        color="success"
        icon="far fa-badge-check"
        loaded={submitting}
        onClick={handleSimpleVerifyClick}
        type="submit"
      >
        <FormattedMessage
          defaultMessage="Simple verify"
          id="settings.profile.simple"
        />
      </Button>

      <Button loaded={submitting} type="submit">
        <FormattedMessage
          defaultMessage="Update profile"
          id="settings.profile.submit"
        />
      </Button>
    </div>
  </Form>
);

export default compose(
  connect(
    null,
    { openModal }
  ),
  graphql(updateProfileMutation, { name: 'updateProfile' }),
  graphql(updateProfileWithAvatarMutation, { name: 'updateProfileWithAvatar' }),
  withHandlers({
    handleSimpleVerifyClick: ({ openModal }) => () =>
      openModal(VERIFY_MODAL_ID, {
        title: 'verify.modal.title.simple',
        type: VERIFICATION_TYPE_SIMPLE
      })
  }),
  reduxForm({
    form: SETTINGS_PROFILE_FORM_ID,
    asyncValidate: asyncValidator(
      yup.object().shape({
        country: yup
          .string()
          .nullable()
          .matches(/^[A-я]{2,}$/, 'error.form.country'),
        city: yup
          .string()
          .nullable()
          .matches(/^[A-я]+(?:[\s-][A-я]+)*$/, 'error.form.city'),
        firstname: yup
          .string()
          .nullable()
          .matches(/^[A-я .-]*$/, 'error.form.firstname'),
        middlename: yup
          .string()
          .nullable()
          .matches(/^[A-я .-]*$/, 'error.form.middlename'),
        phone: yup
          .string()
          .nullable()
          .test('phone', 'error.form.phone', (value: string): boolean => {
            const parsedPhone: Object =
              !!value && parsePhoneNumberFromString(value);

            return !value || (parsedPhone && parsedPhone.isValid());
          }),
        surname: yup
          .string()
          .nullable()
          .matches(/^[A-я .-]*$/, 'error.form.surname'),
        telegram: yup
          .string()
          .nullable()
          .matches(
            /^((https?:\/\/)?(t(elegram)?\.me|telegram\.org)\/|@)?([A-z0-9\\_]{5,32})\/?$/,
            'error.form.telegram'
          )
      })
    ),
    enableReinitialize: true,
    onSubmit: (
      values,
      dispatch,
      { updateProfile, updateProfileWithAvatar }
    ): Promise =>
      (values.avatar && values.avatar instanceof File
        ? updateProfileWithAvatar
        : updateProfile)({
        refetchQueries: [{ query: currentUserQuery }],
        variables: values
      })
        // eslint-disable-next-line
        .then(console.log)
        .catch(errorHandler)
  })
)(SettingsProfileForm);

export type SettingsProfileFormPropTypes = {
  error: ?string,
  handleSubmit: Function,
  submitting: boolean
};
