import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';

// Components
import Button from '@components/Button';

// Constants
import CURRENCIES from '@constants/currencies';
import PURPOSES from '@constants/purposes';

// Containers
import { TRANSFER_MODAL_ID } from '@containers/Transfer';

// Contexts
import CurrentUserContext from '@contexts/CurrentUser';

// Ducks
import { BUY_MODAL_ID } from '../ducks';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Account.scss';

const STAT_MAP = [
  {
    base: 'lastPeriodTotalBase',
    title: 'dashboard.accounts.period',
    value: 'lastPeriodTotal'
  }
  // {
  //   base: 'totalBase',
  //   title: 'Total',
  //   value: 'total'
  // }
];

const VARIANT = {
  MOBILE: 'mobile'
};

const DashboardAccount = ({
  address,
  currencyId,
  currencies = [],
  displayPrecision,
  name,
  precision,
  purposes = [],
  rate,
  readOnly,
  value = 0,
  variant,
  // Handlers
  handleBuyClick,
  handleMouseEnter,
  handleMouseLeave,
  handleWithdrawClick,
  // State
  isHovered,
  ...props
}: DashboardAccountPropTypes): React.Element<'div'> => {
  const isProduct: boolean = purposes.indexOf(PURPOSES.PRODUCT) > -1;
  const hasWithdraw: boolean = purposes.indexOf(PURPOSES.OUT) > -1;

  return (
    <CurrentUserContext.Consumer>
      {({ currentUser }) => (
        <div
          className={classNames(
            style.Root,
            {
              [style.RootVariantBTC]: currencyId === CURRENCIES.BTC,
              [style.RootVariantETH]: currencyId === CURRENCIES.ETH,
              [style.RootVariantODDB]: currencyId === CURRENCIES.ODDB,
              [style.RootVariantUSD]: currencyId === CURRENCIES.USD,
              [style.RootVariantMobile]: variant === VARIANT.MOBILE
            },
            {
              [style.RootIsHovered]: isHovered
            }
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={style.Wrapper}>
            <div className={style.Header}>
              <div className={style.Info}>
                <div className={style.Title}>{`1 ${name} = ${rate}$`}</div>
                <div className={style.Value}>
                  {value.toFixed(displayPrecision)}
                  <span className={style.Unit}>{name}</span>
                </div>
              </div>

              <div className={style.Currency}>
                <i
                  className={classNames(style.Icon, {
                    'fab fa-btc': currencyId === CURRENCIES.BTC,
                    'fab fa-ethereum': currencyId === CURRENCIES.ETH,
                    'fal fa-coin': currencyId === CURRENCIES.ODDB,
                    'fal fa-dollar-sign': currencyId === CURRENCIES.USD
                  })}
                />
              </div>
            </div>

            <div className={style.Footer}>
              {purposes
                .filter(
                  (item: string): boolean =>
                    [PURPOSES.IN, PURPOSES.PRODUCT].indexOf(item) > -1
                )
                .map((purpose: string, index: number): React.Element<'div'> => (
                  <div
                    key={index}
                    className={classNames(style.Purpose, {
                      [style.PurposeVariantIn]: purpose === PURPOSES.IN,
                      [style.PurposeVariantProduct]:
                        purpose === PURPOSES.PRODUCT
                    })}
                  >
                    <i
                      className={classNames(style.PurposeIcon, {
                        'fal fa-angle-up': purpose === PURPOSES.IN,
                        'fal fa-coins': purpose === PURPOSES.PRODUCT
                      })}
                    />
                    {purpose}
                  </div>
                ))}
            </div>

            <div className={style.Hidden}>
              <div className={style.Stats}>
                {STAT_MAP.map(({ base, title, value }, index: number) => {
                  const formattedBase: string = get(props, base, 0).toFixed(2);
                  const formattedValue: string = get(props, value, 0).toFixed(
                    4
                  );

                  return (
                    <div key={index} className={style.StatsItem}>
                      <div className={style.StatsTitle}>
                        <FormattedMessage id={title} />
                      </div>
                      <div className={style.StatsValue}>{formattedValue}</div>
                      <div
                        className={style.StatsBase}
                      >{`$${formattedBase}`}</div>
                    </div>
                  );
                })}
              </div>

              {!readOnly && (
                <div
                  className={classNames(style.Actions, {
                    [style.ActionsVariantSolo]: isProduct
                  })}
                >
                  <Button
                    key={currencyId}
                    className={style.Buy}
                    fullWidth={isProduct}
                    onClick={() => handleBuyClick(currentUser)}
                    size="small"
                  >
                    <FormattedMessage
                      defaultMessage={isProduct ? 'Buy' : 'Replenish'}
                      id={`dashboard.accounts.${
                        isProduct ? 'buy' : 'replenish'
                      }`}
                      values={{ currencyId }}
                    />
                  </Button>

                  {!isProduct && hasWithdraw && (
                    <Button onClick={handleWithdrawClick} size="small">
                      <FormattedMessage
                        defaultMessage="Withdraw"
                        id="dashboard.accounts.withdraw"
                      />
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </CurrentUserContext.Consumer>
  );
};

export default compose(
  connect(null, { openModal }),
  withState('isHovered', 'setHover', false),
  withHandlers({
    handleBuyClick: ({
      address,
      currencyId,
      name,
      openModal,
      purposes,
      readOnly
    }): Function => ({ id, avatar, email, firstname, surname }) => {
      purposes.indexOf(PURPOSES.PRODUCT) > -1
        ? openModal(TRANSFER_MODAL_ID, {
            currencyIdTo: currencyId,
            partner: {
              attributes: {
                email
              },
              image: avatar,
              name: `${firstname || ''} ${surname || ''}`,
              userIdTo: id
            }
          })
        : openModal(BUY_MODAL_ID, { address, currencyId, name });
    },
    handleMouseEnter: ({ readOnly, setHover }): Function => (): void =>
      !readOnly && setHover(true),
    handleMouseLeave: ({ readOnly, setHover }): Function => (): void =>
      !readOnly && setHover(false),
    handleWithdrawClick: ({
      currencyId,
      openModal,
      readOnly
    }): Function => (): void =>
      !readOnly &&
      openModal(TRANSFER_MODAL_ID, {
        currencyId,
        title: 'withdraw.modal.title',
        withdraw: true
      })
  })
)(DashboardAccount);

export type DashboardAccountPropTypes = {
  currencyId:
    | CURRENCIES.BTC
    | CURRENCIES.ETH
    | CURRENCIES.ODDB
    | CURRENCIES.USD,
  handleMouseEnter: Function,
  handleMouseLeave: Function,
  isHovered: boolean,
  purposes: [
    | PURPOSES.BASE
    | PURPOSES.IN
    | PURPOSES.OUT
    | PURPOSES.PRODUCT
    | PURPOSES.UPDATE
  ],
  rate: number,
  readOnly: ?boolean,
  value: ?number
};
