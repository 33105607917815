import { get } from 'lodash';
import type { SessionStateTypes } from './reducer';

export const getSession: Function = (state): SessionStateTypes =>
  get(state, 'services.session');

export const getToken: Function = (state): string =>
  get(getSession(state), 'token');

export const isAuthorized: Function = (state): boolean => {
  const { refreshToken, token } = getSession(state);

  return refreshToken || token;
};
