import Cookies from 'js-cookie';
import { get } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, Checkbox, Input } from '@components/Form';

// Ducks
import {
  SIGN_BASE_FORM,
  SIGN_IN_ID,
  SIGN_RECOVERY_ID,
  SIGN_UP_ID
} from '@views/Sign/ducks';

// Style
import style from './Form.scss';

const SignBaseForm = ({
  action,
  error,
  handleSubmit,
  id,
  secondary,
  submitting
}: SignBaseFormPropTypes): React.Element<typeof Form> => (
  <Form error={error} onSubmit={handleSubmit}>
    <Input
      disabled={submitting}
      label="sign.field.email"
      name="email"
      placeholder="sign.field.email.placeholder"
    />

    <Input
      disabled={submitting}
      disableTrim
      info={
        id === SIGN_IN_ID ? (
          <Link to="/sign/recovery">
            <FormattedMessage
              defaultMessage="Forgot password?"
              id="sign.forgot"
            />
          </Link>
        ) : null
      }
      label={`sign.field.${
        id === SIGN_RECOVERY_ID ? 'newPassword' : 'password'
      }`}
      name="password"
      placeholder={`sign.field.${
        id === SIGN_RECOVERY_ID ? 'newPassword' : 'password'
      }.placeholder`}
      type="password"
    />

    {id === SIGN_RECOVERY_ID && (
      <Input
        disabled={submitting}
        disableTrim
        label="sign.field.confirmPassword"
        name="confirmPassword"
        placeholder="sign.field.confirmPassword.placeholder"
        type="password"
      />
    )}

    {id === SIGN_UP_ID && (
      <>
        <Input
          disabled={submitting}
          label="sign.field.refcode"
          name="refcode"
          placeholder="sign.field.refcode.placeholder"
        />

        <Checkbox name="tou">
          <FormattedHTMLMessage id="sign.up.tou" />
        </Checkbox>

        <Checkbox name="pp">
          <FormattedHTMLMessage id="sign.up.pp" />
        </Checkbox>
      </>
    )}

    <div className={style.Actions}>
      <Button
        className={style.Submit}
        fullWidth
        loaded={submitting}
        type="submit"
      >
        <FormattedMessage defaultMessage={action} id={`sign.${id}.action`} />
      </Button>

      <div className={style.Secondary}>
        <FormattedMessage
          defaultMessage={get(secondary, 'title')}
          id={`sign.${id}.secondary.title`}
        />
        &nbsp;
        <Link to={get(secondary, 'link.to')}>
          <FormattedMessage
            defaultMessage={get(secondary, 'link.title')}
            id={`sign.${id}.secondary.link`}
          />
        </Link>
      </div>
    </div>
  </Form>
);

export default compose(
  withProps(({ id, location }) => {
    const refcode =
      queryString.parse(location.search).refcode || Cookies.get('refCode');

    return {
      asyncValidate: asyncValidator(
        yup.object().shape({
          email: yup
            .string()
            .required('error.form.required')
            .email('error.form.email'),
          password: yup
            .string()
            .required('error.form.required')
            .matches(/^\S*$/, 'error.form.trim'),
          ...(id === SIGN_RECOVERY_ID
            ? {
                confirmPassword: yup
                  .string()
                  .required('error.form.required')
                  .test(
                    'confirm-passwords',
                    'settings.security.form.confirm.error',
                    // eslint-disable-next-line
                    function(value) {
                      return value === get(this, 'parent.password');
                    }
                  )
              }
            : {}),
          ...(id === SIGN_UP_ID
            ? {
                pp: yup.boolean().required(),
                refcode: yup.string().required('error.form.required'),
                tou: yup.boolean().required()
              }
            : {})
        })
      ),
      initialValues: id === SIGN_UP_ID ? { refcode } : null
    };
  }),
  reduxForm({
    form: SIGN_BASE_FORM
  })
)(SignBaseForm);

export type SignBaseFormPropTypes = {
  action: string,
  error: ?string,
  handleSubmit: Function,
  id: string,
  secondary: {
    link: {
      to: string,
      title: string
    },
    title: string
  },
  submitting: boolean
};
