import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';

// Components
import Modal from '@components/Modal';

// Containers
import Form from './containers/Form';

// Ducks
import { CONFIRM_MODAL_ID } from './ducks';

// GraphQL
import currentUserQuery from '@graphql/currentUser.graphql';

const Confirm = () => (
  <Query query={currentUserQuery}>
    {({ data, loading }) => (
      <Modal id={CONFIRM_MODAL_ID}>
        {props => {
          const confirmId: string = get(props, 'confirmId');
          const buyMode: boolean = get(props, 'buyMode');
          const userId = get(data, 'currentUser.id');
          const withdrawMode: boolean = get(props, 'withdrawMode');

          return (
            confirmId &&
            !loading && (
              <Form
                buyMode={buyMode}
                initialValues={{ confirmId, userId }}
                withdrawMode={withdrawMode}
              />
            )
          );
        }}
      </Modal>
    )}
  </Query>
);

export default Confirm;
