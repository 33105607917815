import * as yup from 'yup';

// Components
import { Checkbox, Input, Json, Select, Textarea } from '@components/Form';

export const parseToFields = (data: string) => {
  if (data) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(data, 'text/xml');

    if (xml) {
      const defaultValue = {};
      const validateShape = {};

      let script;

      const pattern = Array.prototype.map.call(
        xml.getElementsByTagName('PreferenceScreen')[0].children,
        child => {
          let Component = Input;
          const name = child.getAttribute('key');

          // Select component
          switch (child.tagName) {
            case 'CheckBoxPreference':
              Component = Checkbox;
              break;
            case 'EditTextPreference':
              Component =
                child.getAttribute('singleLine') === 'false'
                  ? child.getAttribute('inputType') === 'json'
                    ? Json
                    : Textarea
                  : Input;
              break;
            case 'ListPreference':
              Component = Select;
              break;
            case 'script':
              script = child.textContent;
              break;
            default:
              Component = Input;
              break;
          }

          // Fill options if exists
          let options = null;

          if (child.hasAttribute('entryValues')) {
            const labels = child.getAttribute('entries').split('|');
            const values = child.getAttribute('entryValues').split('|');

            if (child.hasAttribute('order')) {
              const parsedValues = values.map((value, index) => {
                const splittedValue = value.split(':');

                return {
                  label: labels[index],
                  order: parseInt(splittedValue[1], 10),
                  value: splittedValue[0]
                };
              });

              parsedValues
                .sort(
                  (a, b) => (a.order < b.order ? 1 : a.order > b.order ? -1 : 0)
                  // eslint-disable-next-line function-paren-newline
                )
                .forEach(({ label, value }, index) => {
                  labels[index] = label;
                  values[index] = value;
                });
            }

            options = values.map((value, index) => ({
              label: labels[index] || value,
              value
            }));
          }

          // Fill validate object
          if (child.getAttribute('obligatory')) {
            validateShape[name] = yup.string().required('error.form.required');
          }

          const multiple = child.getAttribute('multiple') === 'true';

          if (child.getAttribute('defaultValue')) {
            const value = child.getAttribute('defaultValue');

            switch (child.tagName) {
              case 'CheckBoxPreference':
                defaultValue[name] = value === 'true';
                break;
              default:
                defaultValue[name] = multiple ? value.split('|') : value;
                break;
            }
          }

          return (
            name && {
              Component,
              name,
              options,
              description: child.getAttribute('dialogMessage'),
              disabled:
                child.hasAttribute('enabled') &&
                child.getAttribute('enabled') === 'false',
              jsonSchema: child.getAttribute('jsonSchema'),
              label: child.getAttribute('title'),
              multiple,
              type:
                child.getAttribute('inputType') === 'textPassword'
                  ? 'password'
                  : 'text'
            }
          );
        }
      );

      // eslint-disable-next-line no-eval
      const checkPreferences = new Function('prefs', script ? `${script}\nreturn checkPreferences(prefs)` : `return true`);

      const validate = yup
        .object()
        .shape(validateShape)
        .test(
          'customValidation',
          'Error',
          async function (value) {
            try {
              return checkPreferences(value);
            } catch (e) {
              return this.createError({path: this.path, message: e.message});
            }
          }
        );

      return {
        defaultValue,
        pattern: pattern.filter(item => !!item),
        validate
      };
    }
  }

  return null;
};
