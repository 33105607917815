import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import Block from '@components/Block';
import Button from '@components/Button';

import Field from '../components/Field';
import Section from '../components/Section';

// Containers
import { TRANSFER_MODAL_ID } from '@containers/Transfer';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Profile.scss';

const UserProfile = ({
  address,
  avatar,
  city,
  country,
  id,
  email,
  firstname,
  middlename,
  phone,
  plan,
  skype,
  sponsor,
  surname,
  telegram,
  // Handlers
  handleTransferClick
}: UserProfilePropTypes): React.Element<typeof Block> => (
  <Block
    title={
      <FormattedMessage
        defaultMessage="User #{id}"
        id="user.profile.title"
        values={{ id }}
      />
    }
  >
    <div className={style.Root}>
      <div className={style.Left}>
        <Avatar className={style.Avatar} value={avatar} />

        <div className={style.Actions}>
          <Button color="danger" size="small">
            <FormattedMessage defaultMessage="Block" id="user.actions.block" />
          </Button>

          <Button onClick={handleTransferClick} size="small">
            <FormattedMessage
              defaultMessage="Block"
              id="user.actions.transfer"
            />
          </Button>
        </div>
      </div>

      <div className={style.Right}>
        <div className={style.Name}>
          {[surname, firstname, middlename].filter(item => !!item).join(', ')}
        </div>

        <Section
          title={
            <FormattedMessage defaultMessage="Info" id="user.profile.info" />
          }
        >
          <Field title="ID" value={id} />

          {plan && (
            <Field
              title={
                <FormattedMessage
                  defaultMessage="Plan"
                  id="common.field.plan"
                />
              }
            >
              {plan.name}
            </Field>
          )}

          {sponsor && (
            <Field
              title={
                <FormattedMessage
                  defaultMessage="Sponsor"
                  id="common.field.sponsor"
                />
              }
            >
              <Link to={`/users/${get(sponsor, 'id')}`}>
                {`${get(sponsor, 'firstname') || ''} ${get(
                  sponsor,
                  'surname'
                ) || ''}`}
              </Link>
            </Field>
          )}

          <Field
            title={
              <FormattedMessage
                defaultMessage="Email"
                id="common.field.email"
              />
            }
          >
            <a href={`mailto:${email}`}>{email}</a>
          </Field>

          <Field
            title={
              <FormattedMessage
                defaultMessage="Address"
                id="common.field.address"
              />
            }
            value={[country, city, address].filter(item => !!item).join(', ')}
          />
        </Section>

        <Section
          title={
            <FormattedMessage
              defaultMessage="Contacts"
              id="user.profile.contacts"
            />
          }
        >
          <Field
            title={
              <FormattedMessage
                defaultMessage="Phone"
                id="common.field.phone"
              />
            }
          >
            <a href={`tel:${phone}`}>{phone}</a>
          </Field>

          <Field title="Telegram">
            <a href={`tg://resolve?domain=${telegram}`}>{telegram}</a>
          </Field>

          <Field title="Skype">
            <a href={`skype:${skype}`}>{skype}</a>
          </Field>
        </Section>
      </div>
    </div>
  </Block>
);

export default compose(
  connect(
    null,
    { openModal }
  ),
  withHandlers({
    handleTransferClick: ({
      avatar,
      email,
      firstname,
      id,
      openModal,
      surname
    }): Function => (): void =>
      openModal(TRANSFER_MODAL_ID, {
        partner: {
          attributes: {
            email
          },
          image: avatar,
          name: `${firstname} ${surname}`,
          userIdTo: id
        }
      })
  })
)(UserProfile);

export type UserProfilePropTypes = {
  id: number
};
