import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import Modal from '@components/Modal';
import Table from '@components/Table';

import Field from './Field';
import Status from './Status';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Table.scss';

const TransactionsTable = ({
  currentUserId,
  data,
  loading,
  openModal,
  pageSize,
  showPagination = false,
  sortable = true
}: TransactionsTablePropTypes): React.Element<'div'> => {
  const handleLogClick = log => openModal('log', { log });

  const COLUMNS = [
    {
      accessor: 'id',
      Cell: ({ original }) => (
        <Field {...original} currentUserId={currentUserId} />
      ),
      Header: (
        <FormattedMessage
          defaultMessage="Transaction"
          id="transactions.table.transaction"
        />
      ),
      sortable: false,
      width: 600
    },
    {
      accessor: 'status',
      Cell: ({ original, value }) => (
        <div className={style.Status}>
          <Status {...original} value={value} />
          {original.log && (
            <span
              className={style.StatusLog}
              onClick={handleLogClick.bind(null, original.log)}
            >
              Log
            </span>
          )}
        </div>
      ),
      Header: (
        <FormattedMessage
          defaultMessage="Status"
          id="transactions.table.status"
        />
      ),
      sortable,
      width: 120
    },
    {
      accessor: 'comment',
      Cell: ({ value }) => (
        <div
          className={classNames(style.Comment, {
            [style.CommentVariantSystem]: (value || '').charAt(0) === '!'
          })}
        >
          {(value || '').replace(/^!/, '')}
        </div>
      ),
      Header: (
        <FormattedMessage
          defaultMessage="Comment"
          id="transactions.table.comment"
        />
      ),
      sortable: false,
      width: 200
    }
  ];

  return (
    <div className={style.Root}>
      <Table
        columns={COLUMNS}
        data={data}
        defaultPageSize={pageSize}
        loading={loading}
        minRows={0}
        resizable={false}
        showPagination={showPagination}
      />

      <Modal id="log" title="Transaction log">
        {({ log }) => <div className={style.Log}>{log}</div>}
      </Modal>
    </div>
  );
};

export default connect(
  null,
  { openModal }
)(TransactionsTable);
export type TransactionsTablePropTypes = {
  data: [Object],
  loading: boolean,
  pageSize?: number,
  showPagination?: boolean,
  sortable: ?boolean
};
