import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Avatar from '@components/Avatar';

// Style
import style from './Item.scss';

const UsersItem = ({
  id,
  avatar,
  email,
  firstname,
  onClick,
  surname
}: UsersItemPropTypes): React.Element<'div'> => (
  <div className={style.Root} onClick={onClick} role="button" tabIndex={0}>
    <Avatar
      alt={`${firstname} ${surname}`}
      className={style.Avatar}
      value={avatar}
    />

    <div className={style.Info}>
      <div className={style.Name}>{`${firstname} ${surname}`}</div>
      <div className={style.Attributes}>
        <div className={style.Field}>
          <span className={style.Label}>ID:</span>
          <span className={style.Value}>{id}</span>
        </div>

        <div className={style.Field}>
          <span className={style.Label}>
            <FormattedMessage defaultMessage="ID" id="common.field.email" />:
          </span>
          <span className={style.Value}>{email}</span>
        </div>
      </div>
    </div>
  </div>
);

export default UsersItem;
export type UsersItemPropTypes = {
  avatar?: number | string,
  email: string,
  firstname?: string,
  id: number | string,
  onClick: (React.SyntheticEvent<HTMLDivElement>) => ?void,
  surname?: string
};
