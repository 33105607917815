import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

// Components
import Button from '@components/Button';
import Form, { errorHandler, Input } from '@components/Form';

// Ducks
import { VERIFY_REQUEST_FORM_ID } from '../ducks';

// GraphQL
import requestVerificationMutation from '@graphql/requestVerification.graphql';

// Services
import { closeModals } from '@services/modals';

// Style
import style from './common.scss';

const VerifyRequestForm = ({ error, handleSubmit, submitting }) => (
  <Form className={style.Root} error={error} onSubmit={handleSubmit}>
    <Input label="common.field.comment" name="comment" />

    <div className={style.Actions}>
      <Button fullWidth loaded={submitting} type="submit">
        <FormattedMessage defaultMessage="Confirm" id="sign.confirm.action" />
      </Button>
    </div>
  </Form>
);

export default compose(
  connect(
    null,
    { closeModals }
  ),
  graphql(requestVerificationMutation, { name: 'requestVerification' }),
  reduxForm({
    form: VERIFY_REQUEST_FORM_ID,
    onSubmit: (values, dispatch, { closeModals, requestVerification }) =>
      requestVerification({ variables: values })
        .then(closeModals)
        .catch(errorHandler)
  })
)(VerifyRequestForm);
