import arrayToTree from 'array-to-tree';
import * as React from 'react';
import Tree from 'react-d3-tree';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withState } from 'recompose';

// Style
import style from './Tree.scss';

const PartnersTree = ({
  data,
  loading,
  onClick,
  // State
  center
}: PartnersTreePropTypes): React.Element<typeof React.Fragment> => {
  const treeData: Array = arrayToTree(data, {
    customId: 'id',
    parentProperty: 'parentId'
  });

  return (
    <React.Fragment>
      <svg className={style.Images}>
        {data && data.length > 0 && (
          <defs>
            <pattern
              height={80}
              id="default"
              patternUnits="userSpaceOnUse"
              width={80}
              x={40}
              y={40}
            >
              <image
                height={80}
                width={80}
                x={0}
                xlinkHref="https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png"
                y={0}
              />
            </pattern>

            {data.map(
              item =>
                item.image ? (
                  <pattern
                    key={item.id}
                    height={80}
                    id={item.id}
                    patternUnits="userSpaceOnUse"
                    width={80}
                    x={40}
                    y={40}
                  >
                    <image
                      height={80}
                      width={80}
                      x={0}
                      xlinkHref={item.image}
                      y={0}
                    />
                  </pattern>
                ) : null
              // eslint-disable-next-line
            )}
          </defs>
        )}
      </svg>

      {!loading && (
        <Tree
          collapsible={false}
          data={treeData}
          nodeSize={{ x: 200, y: 200 }}
          onClick={onClick}
          orientation="vertical"
          textLayout={{ x: 48, y: -10 }}
          translate={center}
        />
      )}
    </React.Fragment>
  );
};

export default compose(
  withRouter,
  withState('center', 'setCenter', { x: 0, y: 0 }),
  lifecycle({
    componentDidMount() {
      const $root = document.getElementById('partners:root');

      this.props.setCenter({
        x: $root.clientWidth / 2,
        y: $root.clientHeight / 2
      });
    }
  })
)(PartnersTree);

export type PartnersTreePropTypes = {
  center: {
    x: number,
    y: number
  },
  handlePartnerClick: (SyntheticEvent<SVGElement>) => ?void,
  loading: boolean
};
