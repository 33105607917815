import Fingerprint2 from 'fingerprintjs2';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { compose, lifecycle, withState } from 'recompose';

// Containers
import Form from './containers/Form';

// Ducks
import {
  SIGN_CONFIRM_ID,
  SIGN_IN_ID,
  SIGN_RECOVERY_ID,
  SIGN_UP_ID
} from './ducks';

// Style
import style from './Sign.scss';

// Utils
import { resolveUrl } from '@utils/url';

const Sign = ({
  isMounted,
  match,
  sessionId
}: SignPropTypes): React.Element<'div'> => (
  <CSSTransition
    classNames={{
      enter: style.RootAnimateEnter,
      enterActive: style.RootAnimateEnterActive
    }}
    in={isMounted}
    timeout={400}
    unmountOnExit
  >
    <div className={style.Root}>
      <div className={style.Container}>
        <TransitionGroup className={style.Wrapper}>
          {[SIGN_CONFIRM_ID, SIGN_IN_ID, SIGN_RECOVERY_ID, SIGN_UP_ID].map(
            id => (
              <Route key={id} exact path={resolveUrl([match.url, id])}>
                {({ match }) => (
                  <CSSTransition
                    classNames={{
                      enter: style.WrapperAnimateEnter,
                      enterActive: style.WrapperAnimateEnterActive,
                      exit: style.WrapperAnimateExit,
                      exitActive: style.WrapperAnimateExitActive
                    }}
                    in={match != null}
                    timeout={800}
                    unmountOnExit
                  >
                    <Form key={id} id={id} sessionId={sessionId} />
                  </CSSTransition>
                )}
              </Route>
            )
          )}
        </TransitionGroup>
      </div>
    </div>
  </CSSTransition>
);

export default compose(
  withState('isMounted', 'setMount', false),
  withState('sessionId', 'setSessionId', false),
  lifecycle({
    componentDidMount() {
      const { setMount, setSessionId } = this.props;

      Fingerprint2.get(components => {
        const values: string = components.map(({ value }) => value).join('');
        const hash: string = Fingerprint2.x64hash128(values, 31);

        setSessionId(hash);
      });

      setMount(true);
    }
  })
)(Sign);

export type SignPropTypes = {
  id: string,
  isMounted: boolean,
  sessionId: string
};
