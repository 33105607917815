import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

// Components
import Button from '@components/Button';
import Modal from '@components/Modal';
import Item from './components/Item';

// Containers
import ActivateForm from './containers/ActivateForm';

// GraphQL
import aggregateBuy from '@graphql/aggregateBuy.graphql';
import getProductsQuery from '@graphql/getProducts.graphql';

// Services
import { openModal } from '@services/modals';

// Styles
import styles from './Products.scss';

const Products = ({ widget }) => {
  const dispatch = useDispatch();

  const handleActivateClick = () => dispatch(openModal('productActivate'));

  return (
    <Query query={getProductsQuery}>
      {({ data, loading }) => {
        const licenses = get(data, 'getProductTypes', []);
        const products = get(data, 'getProducts', []);

        return (
          <div className={styles.Root}>
            {!loading && (
              <>
                <div className={styles.Section}>
                  <div className={styles.Header}>
                    <h2 className={styles.Title}>
                      <FormattedMessage
                        defaultMessage="My products"
                        id="products.my.title"
                      />
                    </h2>

                    <Button onClick={handleActivateClick} size="small">
                      <FormattedMessage
                        defaultMessage="Activate"
                        id="products.activate.trigger"
                      />
                    </Button>
                  </div>

                  <div className={styles.Grid}>
                    {products.map(product => {
                      const license = licenses.find(
                        ({ id }) => id === product.productTypeId.toString()
                      );

                      return (
                        <Item {...license} key={license.id} product={product} />
                      );
                    })}
                  </div>
                </div>

                {!widget && (
                  <div className={styles.Section}>
                    <h2 className={styles.Title}>
                      <FormattedMessage
                        defaultMessage="Buy license"
                        id="products.buy.title"
                      />
                    </h2>

                    <div className={styles.Grid}>
                      {licenses
                        .filter(({ hidden }) => !hidden)
                        .map(license => (
                          <Item {...license} key={license.id} />
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}

            <Query query={aggregateBuy}>
              {({ data }) => {
                const userIdTo = get(data, 'currentUser.id', 0);

                return (
                  <>
                    <Modal id="productActivate" title="products.activate.title">
                      <ActivateForm
                        initialValues={{
                          userIdTo
                        }}
                        products={products}
                        productTypes={licenses}
                      />
                    </Modal>
                  </>
                );
              }}
            </Query>
          </div>
        );
      }}
    </Query>
  );
};

export default Products;
