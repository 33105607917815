import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Modal from '@components/Modal';

// Containers
import Form from './Form';

// Constants
import { FAILED, PENDING, PROCESSED } from '@constants/statuses';

// Ducks
import { WITHDRAW_REQUEST_MODAL_ID } from '../ducks';

// Services
import { closeModal } from '@services/modals';

// Style
import style from './Request.scss';

const WithdrawsRequest = ({
  handleCloseClick,
  isAccountant
}): React.Element<typeof Modal> => (
  <Modal id={WITHDRAW_REQUEST_MODAL_ID}>
    {({ data = {} }) => {
      const { address, createdAt, comment, id, status, tx, user } = data;
      const valueFrom =
        get(tx, 'from.valueFrom', 0) - get(tx, 'from.comissionFrom', 0);

      return (
        <div className={style.Root}>
          {comment && (
            <div
              className={classNames(style.Comment, {
                [style.CommentIsFailed]: status === FAILED,
                [style.CommentIsSucceeded]: status === PROCESSED
              })}
            >
              <i
                className={classNames(
                  'fas',
                  status === PROCESSED ? 'fa-check-circle' : 'fa-times',
                  style.CommentIcon
                )}
              />
              {comment}
            </div>
          )}

          <div className={style.Request}>
            <div className={style.RequestHeader}>
              <div className={style.User}>
                <Avatar
                  className={style.UserAvatar}
                  value={get(user, 'avatar')}
                />

                <div className={style.UserInfo}>
                  <div className={style.UserName}>
                    <a
                      href={`/users/${get(user, 'id')}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`${get(user, 'firstname')} ${get(user, 'surname')}`}
                    </a>
                  </div>

                  <div className={style.UserContact}>
                    <div className={style.Contact}>
                      <i
                        className={classNames(
                          'fas fa-envelope',
                          style.ContactIcon
                        )}
                      />
                      <a href={`mailto:${get(user, 'email')}`}>
                        {get(user, 'email')}
                      </a>
                    </div>

                    <div className={style.Contact}>
                      <i
                        className={classNames(
                          'fab fa-telegram',
                          style.ContactIcon
                        )}
                      />
                      <a href={`tg://resolve?domain=${get(user, 'telegram')}`}>
                        {get(user, 'telegram')}
                      </a>
                    </div>

                    <div className={style.Contact}>
                      <i
                        className={classNames(
                          'fab fa-skype',
                          style.ContactIcon
                        )}
                      />
                      <a href={`skype:${get(user, 'skype')}`}>
                        {get(user, 'skype')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={style.RequestMiddle}>
              <div className={style.Value}>
                <div className={style.Commission}>
                  {`Total: ${get(tx, 'from.valueFrom')} ${get(
                    tx,
                    'from.currencyFrom'
                  )}`}
                </div>

                <div className={style.Commission}>
                  {`Commission: ${get(tx, 'from.comissionFrom')} ${get(
                    tx,
                    'from.currencyFrom'
                  )}`}
                </div>

                <div className={style.ValueAmount}>
                  {`+${valueFrom.toFixed(8)} ${get(tx, 'from.currencyFrom')}`}
                </div>

                <div className={style.ValueBase}>
                  {`+$${(valueFrom * get(tx, 'from.rateFrom')).toFixed(2)}`}
                </div>
              </div>

              <i
                className={classNames(
                  'fal fa-long-arrow-down',
                  style.RequestArrow
                )}
              />

              <div className={style.Date}>
                <div className={style.DateBig}>
                  {moment(createdAt).format('DD.MM.YYYY')}
                </div>

                <div className={style.DateTime}>
                  {moment(createdAt).format('HH:mm:ss')}
                </div>
              </div>
            </div>

            <div className={style.RequestFooter}>
              <a className={style.Address} href={get(tx, 'to.explorer')}>
                <i className={classNames('fas fa-wallet', style.AddressIcon)} />
                {address}
              </a>
            </div>
          </div>

          {isAccountant && false && (
            <div className={style.Box}>
              <h3 className={style.Title}>История</h3>

              <div className={style.BoxContent}>123</div>
            </div>
          )}

          {status === PENDING && (
            <div className={style.Box}>
              <h3 className={style.Title}>
                <FormattedMessage
                  defaultMessage="Conclusion"
                  id="withdraws.request.conclusion"
                />
              </h3>

              <div className={style.BoxContent}>
                <Form
                  accountantMode={isAccountant}
                  initialValues={{ withdrawId: id }}
                />
              </div>
            </div>
          )}

          <div className={style.Actions}>
            <Button fullWidth onClick={handleCloseClick}>
              <FormattedMessage
                defaultMessage="Close"
                id="common.action.close"
              />
            </Button>
          </div>
        </div>
      );
    }}
  </Modal>
);

export default compose(
  connect(null, { closeModal }),
  withHandlers({
    handleCloseClick: ({ closeModal }): Function => (): void =>
      closeModal(WITHDRAW_REQUEST_MODAL_ID)
  })
)(WithdrawsRequest);
