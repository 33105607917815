import Cookies from 'js-cookie';

// Constants
import {
  FAILED_RELOGIN_COOKIE_ID,
  REFRESH_TOKEN_COOKIE_ID,
  TOKEN_COOKIE_ID
} from '@services/session/constants';

// Reducer
import type { SessionActionType } from '@services/session/reducer';

// Types
import { LOGOUT, SET_TOKEN } from '@services/session/types';

export const logout: Function = (): SessionActionType => {
  if (Cookies.get(TOKEN_COOKIE_ID)) {
    Cookies.remove(REFRESH_TOKEN_COOKIE_ID);
    Cookies.remove(TOKEN_COOKIE_ID);

    window.location.reload();

    return { type: LOGOUT };
  }

  return { type: null };
};

export const setToken: Function = ({
  refreshToken,
  refreshTokenTill,
  token,
  tokenTill
}): SessionActionType => {
  Cookies.remove(FAILED_RELOGIN_COOKIE_ID);

  Cookies.set(REFRESH_TOKEN_COOKIE_ID, refreshToken, {
    expires: new Date(refreshTokenTill)
  });
  Cookies.set(TOKEN_COOKIE_ID, token, { expires: new Date(tokenTill) });

  return {
    type: SET_TOKEN,
    payload: { refreshToken, token }
  };
};
