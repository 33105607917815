import classNames from 'classnames';
import * as React from 'react';
import { useMutation } from 'react-apollo';
import Countdown from 'react-countdown';

// Components
import { asyncValidator, errorHandler } from '@components/Form';

// Containers
import Form from '../containers/Form';

// GraphQL
import UPDATE_TASK from '../graphql/updateTask.graphql';

// Styles
import styles from './Item.scss';

// Utils
import { parseToFields } from '@utils/xml';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  TIMEDOUT: 'TIMEDOUT'
};

const TasksItem = ({
  id,
  completeTill,
  description,
  fields,
  icon,
  name,
  status,
  title,
  values
}) => {
  const [isOpened, setOpenState] = React.useState(false);

  // Mutations
  const [updateTask] = useMutation(UPDATE_TASK);

  // Handlers
  const handleCloseClick = React.useCallback(() => setOpenState(false), []);
  const handleOpenClick = React.useCallback(() => setOpenState(true), []);

  const handleCancelClick = React.useCallback(
    () =>
      updateTask({
        variables: { status: STATUS.CANCELLED, taskId: parseInt(id, 10) }
      }),
    [id, updateTask]
  );

  const handleSubmitForm = React.useCallback(
    async values => {
      try {
        await updateTask({
          variables: {
            status: STATUS.COMPLETED,
            taskId: parseInt(id, 10),
            values: JSON.stringify(values)
          }
        });
        return true;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [id, updateTask]
  );

  const { pattern, validate } = React.useMemo(
    () => (fields ? parseToFields(fields) : {}),
    [fields]
  );

  const initialValues = React.useMemo(() => {
    const result = {};

    try {
      const parsedValues = JSON.parse(values);
      pattern.forEach(({ name }) => {
        if (parsedValues[name]) {
          result[name] = parsedValues[name];
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }

    return result;
  }, [pattern, values]);

  return (
    <div
      className={classNames(
        styles.Root,
        { [styles.RootIsOpened]: isOpened },
        {
          [styles.RootStatusActive]: status === STATUS.ACTIVE,
          [styles.RootStatusCancelled]: status === STATUS.CANCELLED,
          [styles.RootStatusCompleted]: status === STATUS.COMPLETED,
          [styles.RootStatusFailed]: status === STATUS.FAILED,
          [styles.RootStatusTimedout]: status === STATUS.TIMEDOUT
        }
      )}
    >
      <div
        className={styles.Header}
        onClick={isOpened ? handleCloseClick : handleOpenClick}
      >
        <div className={styles.Status}>
          <i
            className={classNames(styles.StatusIcon, {
              'fad fa-play-circle': status === STATUS.ACTIVE,
              'fad fa-stop-circle': status === STATUS.CANCELLED,
              'fad fa-check-circle': status === STATUS.COMPLETED,
              'fad fa-times-circle': status === STATUS.FAILED,
              'fad fa-alarm-exclamation': status === STATUS.TIMEDOUT
            })}
          />

          {status === STATUS.ACTIVE && completeTill && (
            <div className={styles.Countdown}>
              <i
                className={classNames(styles.CountdownIcon, 'far fa-hourglass')}
              />

              <Countdown date={completeTill} />
            </div>
          )}
        </div>
        <div className={styles.Info}>
          <div className={styles.Title}>{title}</div>
          <div className={styles.Type}>
            <img
              alt={name}
              className={styles.Icon}
              src={`${window.API_URL}/${icon}`}
            />

            {name}
          </div>
        </div>
      </div>

      {isOpened && (
        <div className={styles.Body}>
          <div
            className={styles.Description}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <Form
            asyncValidate={asyncValidator(validate)}
            disabled={status !== STATUS.ACTIVE}
            form={id}
            initialValues={initialValues}
            onCancel={handleCancelClick}
            onClose={handleCloseClick}
            onSubmit={handleSubmitForm}
            pattern={pattern}
          />
        </div>
      )}
    </div>
  );
};

export default TasksItem;
