import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './Toggle.scss';

const ProvidersToggle = ({ disabled, onChange, value }) => {
  const handleClick = React.useCallback(() => onChange && onChange(!value), [
    onChange,
    value
  ]);

  return (
    <div
      className={classNames(styles.Root, {
        [styles.RootIsActive]: value,
        [styles.RootIsDisabled]: disabled
      })}
    >
      <div className={styles.Label}>
        <FormattedMessage
          defaultMessage="Auto-mining"
          id="providers.account.mining"
        />
      </div>
      <div className={styles.Container}>
        <div className={styles.State}>
          <FormattedMessage defaultMessage="Off" id="providers.account.off" />
        </div>
        <div className={styles.Control} onClick={handleClick}>
          <div className={styles.Circle} />
        </div>
        <div className={styles.State}>
          <FormattedMessage defaultMessage="On" id="providers.account.on" />
        </div>
      </div>
    </div>
  );
};

export default ProvidersToggle;
