import { get } from 'lodash';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, errorHandler, Input } from '@components/Form';

// Containers
import { BUY_MODAL_ID } from '@containers/Buy';
import { CONFIRM_MODAL_ID } from '@containers/Confirm';

// GraphQL
import buyMutation from '@graphql/buy.graphql';
import getBuyComissionQuery from '@graphql/getBuyComission.graphql';
import getPaymentHelpInfo from '@graphql/getPaymentHelpInfo.graphql';
import getProductsQuery from '@graphql/getProducts.graphql';
import getTransactionsQuery from '@graphql/getTransactions.graphql';
import getSponsor from '@graphql/getSponsorByCode.graphql';

// Services
import { closeModal, openModal } from '@services/modals';

// Styles
import styles from './ActivateForm.scss';

const ProductsActivateForm = ({
  commissionError,
  error,
  handleCancelClick,
  handleSubmit,
  isLoaded,
  isSuccess,
  productId,
  products,
  productTypeId,
  productTypes,
  submitting
}) => {
  const productFrom =
    productId &&
    productTypes.find(({ id }) => {
      const { productTypeId } =
        products.find(({ id }) => id === (productId || -1).toString()) || {};

      return id === (productTypeId || -1).toString();
    });

  const productTo = productTypes.find(
    ({ id }) => id === (productTypeId || -1).toString()
  );

  return (
    <Form
      classNames={{ root: styles.Root }}
      error={commissionError || error}
      onSubmit={handleSubmit}
    >
      {isSuccess ? (
        <>
          <div className={styles.Success}>
            <FormattedMessage
              defaultMessage="Status"
              id={`products.success.${productFrom ? 'upgrade' : 'get'}`}
            />
          </div>

          <Button color="success" fullWidth onClick={handleCancelClick}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="products.success.ok"
            />
          </Button>
        </>
      ) : (
        <>
          <div className={styles.Fieldset}>
            <Input label="products.activate.code" name="code" />
          </div>

          {productTo && (
            <div>
              <div className={styles.Product}>
                <div>
                  <div className={styles.Title}>{productTo.name}</div>
                  <div className={styles.Description}>
                    {productTo.description}
                  </div>
                </div>

                <div className={styles.Actions}>
                  <Button
                    color="success"
                    disabled={!productTypeId}
                    fullWidth
                    loaded={submitting || isLoaded}
                    type="submit"
                  >
                    <FormattedMessage
                      defaultMessage="Activate"
                      id={`products.activate.submit.${
                        productFrom ? 'upgrade' : 'get'
                      }`}
                      values={{ name: (productFrom || productTo).name }}
                    />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  );
};

export default compose(
  connect(null, { closeModal, openModal }),
  graphql(buyMutation, { name: 'buy' }),
  graphql(getBuyComissionQuery, { name: 'getBuyComission' }),
  graphql(getPaymentHelpInfo, { name: 'getPaymentHelpInfo' }),
  graphql(getSponsor, { name: 'getSponsor' }),
  withState('commissionError', 'setCommissionError', null),
  withState('currentCode', 'setCurrentCode', ''),
  withState('isLoaded', 'setLoadState', false),
  withState('isSuccess', 'setSuccessState', false),
  withState('productId', 'setProductId', false),
  withState('productTypeId', 'setProductTypeId', false),
  withHandlers({
    handleCancelClick: ({ closeModal }) => () => closeModal('productActivate')
  }),
  reduxForm({
    asyncValidate: asyncValidator(
      yup.object().shape({
        code: yup.string().required('error.form.required')
      })
    ),
    form: 'productActivate',
    onChange: (
      { code, userIdTo },
      dispatch,
      {
        closeModal,
        currentCode,
        getBuyComission,
        getPaymentHelpInfo,
        getSponsor,
        openModal,
        productTypes,
        setCommissionError,
        setCurrentCode,
        setLoadState,
        setProductId,
        setProductTypeId
      }
    ) => {
      if (code) {
        if (/^H@/.test(code)) {
          getPaymentHelpInfo
            .refetch({ code })
            .then(({ data: { getPaymentHelpInfo } }) => {
              getSponsor
                .refetch({ code: getPaymentHelpInfo.userCodeTo })
                .then(({ data }) => {
                  closeModal('productActivate');

                  openModal(BUY_MODAL_ID, {
                    data: {
                      initialValues: getPaymentHelpInfo,
                      isHelpCode: true,
                      product: productTypes.find(
                        ({ id }) =>
                          id === getPaymentHelpInfo.productTypeId.toString()
                      ),
                      partner: {
                        image: `${window.API_URL}/files/link/${data.getSponsor.avatar}`,
                        name: data.getSponsor.firstname,
                        attributes: {
                          email: data.getSponsor.email
                        },
                        userIdTo: data.getSponsor.id
                      }
                    }
                  });
                });
            });
        } else if (code !== currentCode) {
          setCurrentCode(code);
          setLoadState(true);

          getBuyComission
            .refetch({
              currencyIdFrom: `CODE:${code}`,
              userIdTo: parseInt(userIdTo, 10)
            })
            .then(({ data }) => {
              setCommissionError(null);
              setLoadState(false);
              setProductId(get(data, 'getBuyComission.productId'));
              setProductTypeId(get(data, 'getBuyComission.productTypeId'));
            })
            .catch(({ graphQLErrors }) => {
              let error: string = '';

              graphQLErrors.forEach(({ message }) => {
                error = message;
              });

              setCommissionError(error);
              setLoadState(false);
            });
        }
      }
    },
    onSubmit: (
      { code, userIdTo },
      dispatch,
      { buy, productId, productTypeId, setSuccessState }
    ) =>
      buy({
        refetchQueries: [
          {
            query: getTransactionsQuery
          },
          {
            query: getProductsQuery
          }
        ],
        variables: {
          currencyIdFrom: `CODE:${code}`,
          productId: productId ? parseInt(productId, 10) : undefined,
          productTypeId: parseInt(productTypeId, 10),
          userIdTo: parseInt(userIdTo, 10)
        }
      })
        .then(({ data }) => {
          const confirmId = get(data, 'buy.confirm.id');

          confirmId
            ? openModal(CONFIRM_MODAL_ID, {
                buyMode: true,
                confirmId,
                title: `confirm.modal.title.buy`
              })
            : setSuccessState(true);
        })
        .catch(errorHandler)
  })
)(ProductsActivateForm);

// <div className={styles.Actions}>
// <Button
//   color="success"
//   disabled={!productTypeId}
//   fullWidth
//   loaded={submitting || isLoaded}
//   type="submit"
// >
//   <FormattedMessage
//     defaultMessage="Activate"
//     id="products.activate.submit"
//   />
// </Button>
// </div>
