import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';

// GraphQL
import getUserQuery from '@graphql/getUser.graphql';

// Style
import style from './User.scss';

// Views
import { Field, Section } from '@views/User';

const VerifyUser = ({ id, history }) => (
  <Query query={getUserQuery} variables={{ id }}>
    {({ data, loading }) => {
      const {
        id,
        address,
        avatar,
        city,
        country,
        firstname,
        middlename,
        phone,
        surname,
        telegram
      } = get(data, 'getUser', {});

      return (
        <div className={style.Root}>
          <div className={style.Sidebar}>
            <Avatar className={style.Avatar} value={avatar} />

            <div className={style.Actions}>
              <Button fullWidth onClick={() => history.push(`/users/${id}`)}>
                Посмотреть профиль
              </Button>
            </div>
          </div>

          <div className={style.Info}>
            <Section
              title={
                <FormattedMessage
                  defaultMessage="Fullname"
                  id="verify.modal.user.fullname"
                />
              }
            >
              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Firstname"
                    id="common.field.firstname"
                  />
                }
                value={firstname}
              />

              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Surname"
                    id="common.field.surname"
                  />
                }
                value={surname}
              />

              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Middlename"
                    id="common.field.middlename"
                  />
                }
                value={middlename}
              />
            </Section>

            <Section
              title={
                <FormattedMessage
                  defaultMessage="Address"
                  id="verify.modal.user.address"
                />
              }
            >
              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Country"
                    id="common.field.country"
                  />
                }
                value={country}
              />

              <Field
                title={
                  <FormattedMessage
                    defaultMessage="City"
                    id="common.field.city"
                  />
                }
                value={city}
              />

              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Address"
                    id="common.field.address"
                  />
                }
                value={address}
              />
            </Section>

            <Section
              title={
                <FormattedMessage
                  defaultMessage="Address"
                  id="verify.modal.user.address"
                />
              }
            >
              <Field
                title={
                  <FormattedMessage
                    defaultMessage="Phone"
                    id="common.field.phone"
                  />
                }
              >
                <a href={`tel:${phone}`}>{phone}</a>
              </Field>

              <Field title="Telegram">
                <a href={`tg://resolve?domain=${telegram}`}>{telegram}</a>
              </Field>
            </Section>
          </div>
        </div>
      );
    }}
  </Query>
);

export default withRouter(VerifyUser);
