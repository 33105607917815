import { get, has } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';

// Components
import { errorHandler } from '@components/Form';

// Containers
import BaseForm from './BaseForm';
import ConfirmForm from './ConfirmForm';

// Ducks
import {
  // Confirm type
  SIGN_CONFIRM_TYPE_CREATE,
  SIGN_CONFIRM_TYPE_RECOVER,
  // View type
  SIGN_CONFIRM_ID,
  SIGN_IN_ID,
  SIGN_RECOVERY_ID,
  SIGN_UP_ID,
  VIEW
} from '../ducks';

// GraphQL
import authenticateMutation from '../graphql/authenticate.graphql';
import confirmCreateUserMutation from '../graphql/confirmCreateUser.graphql';
import confirmResetPasswordMutation from '../graphql/confirmResetPassword.graphql';
import createUserMutation from '../graphql/createUser.graphql';
import resetPasswordMutation from '../graphql/resetPassword.graphql';

// Services
import { setToken } from '@services/session';

// Style
import style from './Form.scss';

const SignForm = ({
  confirmId,
  description,
  handleSubmit,
  hasView,
  id,
  title,
  ...props
}: SignFormPropTypes) => {
  const formProps: Object = {
    ...props,
    id,
    form: id,
    onSubmit: handleSubmit
  };

  return (
    <React.Fragment>
      {hasView && (
        <div className={style.Root}>
          <h1 className={style.Title}>
            <FormattedMessage defaultMessage={title} id={`sign.${id}.title`} />
          </h1>

          {description && (
            <div className={style.Description}>
              <FormattedMessage
                defaultMessage={description}
                id={`sign.${id}.description`}
              />
            </div>
          )}

          <div className={style.Form}>
            {id === SIGN_CONFIRM_ID ? (
              <ConfirmForm {...formProps} />
            ) : (
              <BaseForm {...formProps} />
            )}
          </div>
        </div>
      )}

      {!hasView && <Redirect to="/404" />}
      {id === SIGN_CONFIRM_ID && !confirmId && <Redirect to="/sign/in" />}
    </React.Fragment>
  );
};

export default compose(
  graphql(authenticateMutation, { name: 'authenticate' }),
  graphql(confirmCreateUserMutation, { name: 'confirmCreateUser' }),
  graphql(confirmResetPasswordMutation, { name: 'confirmResetPassword' }),
  graphql(createUserMutation, { name: 'createUser' }),
  graphql(resetPasswordMutation, { name: 'resetPassword' }),
  connect(
    null,
    { setToken }
  ),
  withRouter,
  withProps(({ id, location }) => {
    const {
      confirmId,
      confirmType = SIGN_CONFIRM_TYPE_CREATE
    } = queryString.parse(location.search);

    return {
      id,
      confirmId,
      confirmType,
      hasView: has(VIEW, id),
      ...get(VIEW, id, {})
    };
  }),
  withHandlers({
    handleSubmit: ({
      authenticate,
      confirmId,
      confirmType,
      confirmCreateUser,
      confirmResetPassword,
      createUser,
      id,
      history,
      resetPassword,
      sessionId,
      setToken
    }): Function => (values: Object): Promise => {
      let mutate: Promise;

      switch (id) {
        case SIGN_CONFIRM_ID:
          mutate = (confirmType === SIGN_CONFIRM_TYPE_RECOVER
            ? confirmResetPassword
            : confirmCreateUser)({
            variables: { ...values, confirmId }
          }).then(() => {
            history.push('/sign/in');
          });
          break;
        case SIGN_IN_ID:
          mutate = authenticate({
            variables: { ...values, fingerprint: sessionId }
          }).then(({ data }) => {
            const refreshToken: Date = get(data, 'authenticate.refreshToken');
            const refreshTokenTill: Date = get(
              data,
              'authenticate.refreshTokenTill'
            );
            const token: string = get(data, 'authenticate.accessToken');
            const tokenTill: Date = get(data, 'authenticate.accessTokenTill');

            if (token) {
              setToken({ refreshToken, refreshTokenTill, token, tokenTill });
            }
          });
          break;
        case SIGN_RECOVERY_ID:
          mutate = resetPassword({ variables: values }).then(({ data }) => {
            const confirmId: string = get(data, 'resetPassword.id');
            history.push(
              `/sign/confirm?confirmId=${confirmId}&confirmType=${SIGN_CONFIRM_TYPE_RECOVER}`
            );
          });
          break;
        case SIGN_UP_ID:
          mutate = createUser({ variables: values }).then(({ data }) => {
            const confirmId: string = get(data, 'createUser.confirm.id');

            history.push(
              confirmId
                ? `/sign/confirm?confirmId=${confirmId}&confirmType=${SIGN_CONFIRM_TYPE_CREATE}`
                : '/sign/in'
            );
          });
          break;
        default:
          break;
      }

      return mutate.catch(errorHandler);
    }
  })
)(SignForm);

export type SignFormPropTypes = {
  confirmId: ?string,
  confirmType: ?string,
  description: ?string,
  handleSubmit: Function,
  hasView: boolean,
  id: string,
  title: string
};
