import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, withHandlers, withProps, withState } from 'recompose';

// Style
import style from './Ref.scss';

const DashboardRef = ({
  code,
  handleCopyCode,
  nodeIsCopied,
  tokenIsCopied
}: DashboardRefPropTypes): React.Element<'div'> => (
  <div className={style.Root}>
    <div className={style.Section} onClick={() => handleCopyCode('node')}>
      <i className={classNames('fal fa-coins', style.Icon)} />
      <div className={style.Label}>
        <FormattedMessage
          defaultMessage={nodeIsCopied ? 'Copied!' : 'DM'}
          id={nodeIsCopied ? 'common.copied' : 'dashboard.ref.code.copied'}
        />
      </div>
      <div className={style.Value}>{code}</div>
    </div>

    {false && (
      <div className={style.Section} onClick={() => handleCopyCode('token')}>
        <i className={classNames('fal fa-coins', style.Icon)} />
        <div className={style.Label}>
          <FormattedMessage
            defaultMessage={tokenIsCopied ? 'Copied!' : 'Token'}
            id={tokenIsCopied ? 'common.copied' : 'dashboard.ref.code.copied'}
          />
        </div>
        <div className={style.Value}>{code}</div>
      </div>
    )}
  </div>
);

export default compose(
  withProps(({ data }) => ({
    code: get(data, 'currentUser.refcode'),
    count: get(data, 'getStructure.structure', []).length
  })),
  withState('nodeIsCopied', 'setNodeCopyState', false),
  withState('tokenIsCopied', 'setTokenCopyState', false),
  withHandlers({
    handleCopyCode: ({
      code,
      setNodeCopyState,
      setTokenCopyState
    }): Function => (type): void => {
      const $input = document.createElement('input');
      const setCopyState =
        type === 'node' ? setNodeCopyState : setTokenCopyState;

      $input.value = `${window.location.origin}/dm/${code}`;
      document.body.appendChild($input);

      $input.select();
      $input.setSelectionRange(0, 99999);

      document.execCommand('copy');
      document.body.removeChild($input);

      setCopyState(true);
      setTimeout(() => setCopyState(false), 2000);
    }
  })
)(DashboardRef);

export type DashboardRefPropTypes = {
  code: ?string,
  count: ?string,
  handleCopyCode: Function,
  isCopied: boolean
};
