import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

// Assets
import logoFuturology from '@assets/logo-futurology.png';
import logoSba from '@assets/logo-sba.png';

// Components
import Avatar from '@components/Avatar';
import Link, { MainLinkPropTypes } from '../components/Link';

// Containers
import { DOCS_MODAL_ID } from '@containers/Docs';
import Form from './Lang';

// Contexts
import CurrentUserContext from '@contexts/CurrentUser';

// Ducks
import { MENU } from '../ducks';

// i18n
import { EN } from '@i18n/lang';

// Services
import { openModal } from '@services/modals';
import { logout } from '@services/session';

// Style
import style from './Menu.scss';

const MainMenu = ({
  admin: isAdmin,
  handleLinkClick,
  handleLogoutClick,
  links,
  onItemClick,
  user
}): React.Element<'div'> => (
  <div className={style.Root}>
    <div className={style.Logo}>
      <a
        className={style.LogoLink}
        href="https://fai.limited"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="FAI" src={logoFuturology} />
      </a>
    </div>

    {user && (
      <div className={style.User}>
        <Avatar value={user.photo} />
        <div className={style.UserInfo}>
          <div className={style.UserName}>{user.firstname}</div>
          <div className={style.UserMail}>{user.email}</div>
        </div>
      </div>
    )}

    <div className={style.Menu}>
      {MENU.map(
        ({ admin, links, title }, index: number): React.Element<'div'> =>
          (!admin || (admin && isAdmin)) && (
            <div key={index} className={style.Category}>
              <div className={style.Title}>
                <FormattedMessage defaultMessage={title} id={title} />
              </div>

              <div className={style.List}>
                {links.map(
                  (
                    { hidden, ...link }: MainLinkPropTypes,
                    index: number
                  ): React.Element<typeof Link> =>
                    !hidden && (
                      <Link {...link} key={index} onClick={onItemClick} />
                    )
                )}
              </div>
            </div>
          )
      )}

      {links && (
        <div className={style.Category}>
          <div className={style.Title}>
            <FormattedMessage defaultMessage="Links" id="menu.links" />
          </div>

          <div className={style.List}>
            {links.map(({ href, target, text }, index) => (
              <Link
                key={index}
                external
                icon="fal fa-info-circle"
                onClick={
                  href.indexOf('docs.google.com') > -1
                    ? event => handleLinkClick(event, { href, text })
                    : null
                }
                title={text}
                to={href}
              />
            ))}
          </div>
        </div>
      )}

      <div className={style.Category}>
        <div className={style.Title}>
          <FormattedMessage defaultMessage="Language" id="menu.language" />
        </div>

        <div className={style.List}>
          <div className={style.Lang}>
            <CurrentUserContext.Consumer>
              {({ currentUser }) => {
                const lang: string = get(
                  currentUser,
                  'lang',
                  navigator.language || EN
                );

                return currentUser && <Form initialValues={{ lang }} />;
              }}
            </CurrentUserContext.Consumer>
          </div>
        </div>
      </div>

      <div className={style.Category}>
        <div className={style.Title}>
          <FormattedMessage defaultMessage="Version" id="menu.version" />
          <div>
            {process.env.VERSION}
            <a
              href={`https://github.com/dukei/oasis-pa-ui/commit/${process.env.COMMIT}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              #{process.env.COMMIT.substr(0, 6)}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className={style.Logout}>
      <Link
        external
        icon="fal fa-sign-out"
        onClick={handleLogoutClick}
        title="menu.logout"
      />
    </div>
  </div>
);

export default compose(
  connect(null, { logout, openModal }),
  withHandlers({
    handleLinkClick: ({ openModal }): Function => (
      event,
      { href: src, text: title }
    ): ?void => {
      event.preventDefault();
      openModal(DOCS_MODAL_ID, { src, title });
    },
    handleLogoutClick: ({ logout }): Function => (): void => logout()
  })
)(MainMenu);
