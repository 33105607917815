import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

// Components
import Button from '@components/Button';
import Form, { Input } from '@components/Form';

// Data
import { PROVIDER_FORM_ID, PROVIDER_MODAL_ID } from '../data';

// Services
import { closeModal } from '@services/modals';

// Styles
import styles from './common.scss';

const ProvidersAccountForm = ({
  error,
  handleSubmit,
  initialValues,
  pattern,
  submitting
}) => {
  const dispatch = useDispatch();
  const handleCancelClick = () => dispatch(closeModal(PROVIDER_MODAL_ID));

  return (
    <Form
      classNames={{ root: styles.Root }}
      error={error}
      onSubmit={handleSubmit}
    >
      <Input label="providers.form.name.label" name="name" />

      <div className={styles.Settings}>
        {pattern.map(({ Component, ...field }, index) => (
          <Component {...field} key={index} />
        ))}
      </div>

      {error && (
        <div className={styles.Error}>
          <strong>Error:&nbsp;</strong>
          {error}
        </div>
      )}

      <div className={styles.Actions}>
        <Button
          color="secondary"
          disabled={submitting}
          onClick={handleCancelClick}
        >
          <FormattedMessage
            defaultMessage="Cancel"
            id="providers.item.cancel"
          />
        </Button>

        <Button loaded={submitting} type="submit">
          <FormattedMessage
            defaultMessage={get(initialValues, 'id') ? 'Save' : 'Create'}
            id={`providers.item.${
              get(initialValues, 'id') ? 'save' : 'create'
            }`}
          />
        </Button>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: PROVIDER_FORM_ID
})(ProvidersAccountForm);
