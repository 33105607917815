import * as React from 'react';
import { Query } from 'react-apollo';

// Components
import Link from '@components/Link';

// GraphQL
import GET_CONFIG from '@graphql/getConfig.graphql';

// styles
import styles from './Links.scss';

const DashboardLinks = () => (
  <Query query={GET_CONFIG}>
    {({ data }) =>
      (data?.getConfig?.menuTop || []).length === 0 ? null : (
        <div className={styles.Root}>
          <div className={styles.List}>
            {data.getConfig.menuTop.map(({ href, text }, index) => (
              <Link key={index} to={href}>
                {text}
              </Link>
            ))}
          </div>
        </div>
      )
    }
  </Query>
);

export default DashboardLinks;
