import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Responsive from 'react-responsive';
import { compose, withHandlers, withState } from 'recompose';

// Components
import Block from '@components/Block';
import { Checkbox } from '@components/Form';
import Table from '@components/Table';

import Item from './components/Item';

// Containers
import Filter from '@containers/Filter';

// Containers
import Request from './containers/Request';

// Constants
import { ACCOUNTANT } from '@constants/roles';
import { FAILED, PENDING, PROCESSED } from '@constants/statuses';

// Ducks
import { COLUMNS, WITHDRAW_REQUEST_MODAL_ID } from './ducks';

// GraphQL
import getWithdrawRequestsQuery from '@graphql/getWithdrawRequests.graphql';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Withdraw.scss';

const Withdraws = ({
  admin,
  currentFilter,
  handleFilterClick,
  handleRowClick,
  userId
}): React.Element<typeof Query> => {
  const [statusFilter, setStatusFilter] = React.useState(null);

  const handleFilterSubmit = React.useCallback(values => {
    const newStatusFilter = [];

    Object.keys(values).forEach(status => {
      if (values[status]) {
        newStatusFilter.push(status);
      }
    });

    setStatusFilter(newStatusFilter.length > 0 ? newStatusFilter : null);
  }, []);

  return (
    <Query
      query={getWithdrawRequestsQuery}
      variables={!admin ? { id: userId, statusFilter } : { statusFilter }}
    >
      {({ data, loading }) => {
        const isAccountant: boolean =
          get(data, 'currentUser.roles', []).indexOf(ACCOUNTANT) > -1;

        const tableData = get(data, 'getWithdrawRequests', []).sort(
          ({ createdAt: a }, { createdAt: b }) => (a < b ? 1 : a > b ? -1 : 0)
        );

        return (
          <div className={style.Root}>
            <Filter onSubmit={handleFilterSubmit} title="withdraw.filter.title">
              <Checkbox
                label="transactions.status.processed"
                name={PROCESSED}
              />
              <Checkbox label="transactions.status.pending" name={PENDING} />
              <Checkbox label="transactions.status.failed" name={FAILED} />
            </Filter>

            <div className={style.Block}>
              <Block
                title={
                  <FormattedMessage
                    defaultMessage={`${
                      admin ? 'Withdraw' : 'My'
                    } request list ({count})`}
                    id={`withdraws.title.${admin ? 'admin' : 'common'}`}
                    values={{ count: tableData.length }}
                  />
                }
              >
                <Responsive minWidth={769}>
                  <Table
                    columns={COLUMNS}
                    data={tableData}
                    getTrProps={(state, { original }) => ({
                      onClick: () => handleRowClick(original)
                    })}
                    loading={loading}
                    showPagination={false}
                  />
                </Responsive>

                <Responsive maxWidth={768}>
                  {tableData.map((withdraw): React.Element<typeof Item> => (
                    <Item
                      {...withdraw}
                      key={withdraw.id}
                      onClick={() => handleRowClick(withdraw)}
                    />
                  ))}
                </Responsive>

                <Request isAccountant={admin && isAccountant} />
              </Block>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default compose(
  connect(
    null,
    { openModal }
  ),
  withState('currentFilter', 'setCurrentFilter', null),
  withHandlers({
    handleFilterClick: ({ setCurrentFilter }): Function => (
      filter: string
    ): void => setCurrentFilter(filter),
    handleRowClick: ({ openModal }): Function => (original: Object): void =>
      openModal(WITHDRAW_REQUEST_MODAL_ID, {
        data: original,
        title: (
          <FormattedMessage
            defaultMessage="Withdraw request #{id}"
            id="withdraws.request.modal.title"
            values={{ id: get(original, 'id') }}
          />
        )
      })
  })
)(Withdraws);
