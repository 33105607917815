import { get } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Paginate from 'react-paginate';
import { useHistory, useParams } from 'react-router-dom';

// Components
import Tabs from '@components/Tabs';
import Task, { STATUS } from './components/Item';

// GraphQL
import GET_TASK_LIST from './graphql/getTaskList.graphql';

// Styles
import styles from './Tasks.scss';

const CURRENT_TASK_STATUS_STORAGE_ID = 'currentTaskStatus';

const Tasks = () => {
  // Setup
  const params = useParams();
  const page = parseInt(params.page || '1', 10) - 1;
  const pageSize = 10;
  const { push } = useHistory();

  // State
  const [currentStatus, setCurrentStatus] = React.useState(
    localStorage.getItem(CURRENT_TASK_STATUS_STORAGE_ID) || STATUS.ACTIVE
  );

  // Data
  const { data, loading } = useQuery(GET_TASK_LIST, {
    variables: { page, pageSize, statuses: [currentStatus] }
  });

  const tasks = get(data, 'getTasksPaged.tasks', []);
  const taskTypes = get(data, 'getTaskTypes', []);
  const total = get(data, 'getTasksPaged.total', 0);

  const isMultipage = total / pageSize > 1;

  // Handlers
  const handleTabSelect = React.useCallback(
    status => {
      localStorage.setItem(CURRENT_TASK_STATUS_STORAGE_ID, status);
      setCurrentStatus(status);
    },
    [setCurrentStatus]
  );

  return (
    <div className={styles.Root}>
      <Tabs
        onSelect={handleTabSelect}
        tabs={[
          {
            label: 'tasks.filter.status.active',
            value: STATUS.ACTIVE
          },
          {
            label: 'tasks.filter.status.completed',
            value: STATUS.COMPLETED
          },
          {
            label: 'tasks.filter.status.canceled',
            value: STATUS.CANCELLED
          },
          {
            label: 'tasks.filter.status.timedout',
            value: STATUS.TIMEDOUT
          },
          {
            label: 'tasks.filter.status.failed',
            value: STATUS.FAILED
          }
        ]}
        value={currentStatus}
      />

      <div className={styles.Wrapper}>
        {isMultipage && (
          <div className={styles.Total}>
            <FormattedMessage defaultMessage="Total" id="common.total" />:{' '}
            {total}
          </div>
        )}

        {!loading && tasks.length === 0 && (
          <div className={styles.Empty}>
            <i className="fas fa-transporter-empty" />
            <div className={styles.EmptyTitle}>
              <FormattedMessage
                default="Task list is empty!"
                id="tasks.empty.title"
              />
            </div>
          </div>
        )}

        {tasks && tasks.length > 0 && (
          <div className={styles.List}>
            {tasks
              .sort((a, b) =>
                a.updatedAt > b.updatedAt
                  ? -1
                  : a.updatedAt < b.updatedAt
                  ? 1
                  : 0
              ) // eslint-disable-line
              .map(task => {
                const taskType = taskTypes.find(
                  taskType => task.typeId.toString() === taskType.id
                );

                return <Task key={task.id} {...taskType} {...task} />;
              })}
          </div>
        )}

        {isMultipage && (
          <Paginate
            activeClassName={styles.PaginatePageIsActive}
            breakClassName={styles.PaginateBreak}
            containerClassName={styles.Paginate}
            initialPage={page}
            marginPagesDisplayed={1}
            nextClassName={styles.PaginateNext}
            onPageChange={({ selected }) => push(`/tasks/${selected + 1}`)}
            pageClassName={styles.PaginatePage}
            pageCount={Math.floor(total / pageSize)}
            pageRangeDisplayed={3}
            previousClassName={styles.PaginatePrev}
          />
        )}
      </div>
    </div>
  );
};

export default Tasks;
