import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Avatar from '@components/Avatar';

// Style
import style from './Item.scss';

const PartnersItem = ({
  attributes: { date, email, level } = {},
  image,
  isAchieved,
  name,
  onClick
}) => (
  <div
    className={classNames(style.Root, { [style.RootIsAchieved]: isAchieved })}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <div className={style.Left}>
      <Avatar className={style.Avatar} value={image} />
    </div>

    <div className={style.Right}>
      <div className={style.Name}>{name}</div>

      <div className={style.Email}>
        <span className={style.Label}>
          <FormattedMessage defaultMessage="Email" id="common.field.email" />:
        </span>
        <span className={style.Value}>{email}</span>
      </div>

      <div className={style.Attributes}>
        <div className={style.Field}>
          <span className={style.Label}>
            <FormattedMessage defaultMessage="Date" id="common.field.date" />:
          </span>
          <span className={style.Value}>{date}</span>
        </div>

        <div className={style.Field}>
          <span className={style.Label}>
            <FormattedMessage defaultMessage="Level" id="common.field.level" />:
          </span>
          <span className={style.Value}>{level}</span>
        </div>
      </div>
    </div>
  </div>
);

export default PartnersItem;
