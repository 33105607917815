import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

// Style
import style from './Link.scss';

const SettingsLink = ({
  title,
  to
}: SettingsLinkPropTypes): React.Element<typeof NavLink> => (
  <NavLink
    activeClassName={style.RootIsSelected}
    className={style.Root}
    exact
    to={to}
  >
    <FormattedMessage defaultMessage={title} id={title} />
  </NavLink>
);

export default SettingsLink;
export type SettingsLinkPropTypes = {
  title: string,
  to: string
};
