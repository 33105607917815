import { get } from 'lodash';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, errorHandler, Input } from '@components/Form';
import Group from '../components/Group';

// Ducks
import { SETTINGS_SECURITY_FORM_ID } from '../ducks';

// GraphQL
import changePasswordMutation from '@graphql/changePassword.graphql';

// Style
import style from './common.scss';

const SettingsSecurityForm = ({
  error,
  handleSubmit,
  submitting
}: SettingsSecurityFormPropTypes): React.Element<typeof Form> => (
  <Form
    classNames={{ error: style.Error }}
    error={error}
    onSubmit={handleSubmit}
  >
    <Group icon="fal fa-lock-alt" title="settings.security.group.password">
      <Input
        disabled={submitting}
        label="settings.field.password"
        name="passOld"
        type="password"
      />
      <Input
        disabled={submitting}
        label="settings.field.password_new"
        name="passNew"
        type="password"
      />
      <Input
        disabled={submitting}
        label="settings.field.password_confirm"
        name="confirm"
        type="password"
      />
    </Group>

    <div className={style.Actions}>
      <Button loaded={submitting} type="submit">
        <FormattedMessage
          defaultMessage="Update password"
          id="settings.security.submit"
        />
      </Button>
    </div>
  </Form>
);

export default compose(
  graphql(changePasswordMutation, { name: 'changePassword' }),
  reduxForm({
    form: SETTINGS_SECURITY_FORM_ID,
    asyncValidate: asyncValidator(
      yup.object().shape({
        confirm: yup
          .string()
          .required('error.form.required')
          .test(
            'confirm-passwords',
            'settings.security.form.confirm.error',
            // eslint-disable-next-line
            function(value) {
              return value === get(this, 'parent.passNew');
            }
          ),
        passNew: yup.string().required('error.form.required'),
        passOld: yup.string().required('error.form.required')
      })
    ),
    // eslint-disable-next-line
    onSubmit: (values, dispatch, { changePassword }) =>
      changePassword({ variables: values })
        // eslint-disable-next-line
        .then(console.log)
        .catch(errorHandler),
    shouldAsyncValidate: () => true
  })
)(SettingsSecurityForm);

export type SettingsSecurityFormPropTypes = {
  error: ?string,
  handleSubmit: Function,
  submitting: boolean
};
