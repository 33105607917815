import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose, withHandlers, withProps } from 'recompose';

// Components
import Button from '@components/Button';

// GraphQL
import getSponsorQuery from '@graphql/getSponsorByCode.graphql';

// Style
import style from './Landing.scss';

const Landing = ({
  code,
  handleCopy,
  handleRegistrationClick,
  type = 'node'
}): React.Element<typeof Query> => (
  <Query query={getSponsorQuery} variables={{ code }}>
    {({ data, loading }) => {
      const {
        avatar,
        email,
        firstname = '',
        skype,
        telegram,
        surname = ''
      } = get(data, 'getSponsor', {});

      const fullName: string = `${firstname || ''} ${surname || ''}`;

      return (
        <div className={style.Root}>
          <div className={style.Wrapper}>
            <div className={style.Header}>
              <div className={style.User}>
                <div className={style.UserInfo}>
                  <div className={style.Name}>{fullName}</div>
                  <div className={style.UserSummary}>{email}</div>
                </div>
              </div>

              <div className={style.HeaderActions}>
                <Button
                  className={style.Registry}
                  onClick={handleRegistrationClick}
                  size="small"
                >
                  <FormattedMessage
                    defaultMessage="Registration"
                    id="sign.up.title"
                  />
                </Button>
              </div>
            </div>

            <div className={style.Body}>
              <h1 className={style.Title}>
                <FormattedMessage
                  defaultMessage="I greet you, I {fullName}"
                  id="landing.common.title"
                  values={{ fullName }}
                />
              </h1>

              <div className={classNames(style.Text, style.TextWithPhoto)}>
                <div className={style.Photo}>
                  {avatar <= 0 ? (
                    <i className={classNames(style.PhotoIcon, 'fas fa-user')} />
                  ) : (
                    <img
                      alt={fullName}
                      className={style.PhotoImage}
                      src={`${window.API_URL}/files/link/${avatar}`}
                    />
                  )}
                </div>

                <div>
                  <FormattedMessage id={`landing.${type}.text.0`} />
                  <div className={style.Slogan}>
                    <FormattedMessage id={`landing.${type}.slogan.0`} />
                  </div>
                </div>
              </div>

              <h3 className={style.Subtitle}>
                <FormattedMessage id={`landing.${type}.title.0`} />
              </h3>

              <div className={style.Text}>
                <FormattedHTMLMessage id={`landing.${type}.text.1`} />
              </div>

              <div className={style.Text}>
                <FormattedHTMLMessage id={`landing.${type}.text.2`} />
              </div>

              <div className={style.Text}>
                <FormattedHTMLMessage id="landing.common.video" />
              </div>

              <div className={style.Video}>
                <FormattedMessage id={`landing.${type}.video`}>
                  {src => (
                    <iframe
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      frameBorder="0"
                      height="400"
                      src={src}
                      title="Video"
                      width="100%"
                    />
                  )}
                </FormattedMessage>
              </div>

              <h3 className={style.Subtitle}>
                <FormattedHTMLMessage id="landing.common.question" />
              </h3>

              <div className={style.Text}>
                <FormattedHTMLMessage id="landing.common.answer" />
                <div className={style.Actions}>
                  {email && (
                    <div className={style.Card}>
                      <i
                        className={classNames(
                          'fas',
                          'fa-envelope',
                          style.CardIcon
                        )}
                      />

                      <div className={style.CardTitle}>{email}</div>

                      <div className={style.CardActions}>
                        <Button
                          color="success"
                          onClick={() => handleCopy(email)}
                          size="small"
                        >
                          <FormattedHTMLMessage
                            defaultMessage="Copy"
                            id="common.copy"
                          />
                        </Button>

                        <a className={style.CardLink} href={`mailto:${email}`}>
                          <Button className={style.CardAction} size="small">
                            <FormattedHTMLMessage
                              defaultMessage="Contact"
                              id="common.contact"
                            />
                          </Button>
                        </a>
                      </div>
                    </div>
                  )}

                  {telegram && (
                    <div className={style.Card}>
                      <i
                        className={classNames(
                          'fab',
                          'fa-telegram',
                          style.CardIcon
                        )}
                      />

                      <div className={style.CardTitle}>{telegram}</div>

                      <div className={style.CardActions}>
                        <Button
                          color="success"
                          onClick={() => handleCopy(telegram)}
                          size="small"
                        >
                          <FormattedHTMLMessage
                            defaultMessage="Copy"
                            id="common.copy"
                          />
                        </Button>

                        <a
                          className={style.CardLink}
                          href={`tg://resolve?domain=${telegram}`}
                        >
                          <Button className={style.CardAction} size="small">
                            <FormattedHTMLMessage
                              defaultMessage="Contact"
                              id="common.contact"
                            />
                          </Button>
                        </a>
                      </div>
                    </div>
                  )}

                  {skype && (
                    <div className={style.Card}>
                      <i
                        className={classNames(
                          'fab',
                          'fa-skype',
                          style.CardIcon
                        )}
                      />

                      <div className={style.CardTitle}>{skype}</div>

                      <div className={style.CardActions}>
                        <Button
                          color="success"
                          onClick={() => handleCopy(skype)}
                          size="small"
                        >
                          <FormattedHTMLMessage
                            defaultMessage="Copy"
                            id="common.copy"
                          />
                        </Button>

                        <a className={style.CardLink} href={`skype:${skype}`}>
                          <Button className={style.CardAction} size="small">
                            <FormattedHTMLMessage
                              defaultMessage="Contact"
                              id="common.contact"
                            />
                          </Button>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <h3 className={style.Subtitle}>
                <FormattedMessage id={`landing.${type}.title.1`} />
              </h3>

              <div className={style.Text}>
                <div className={style.Reg}>
                  <div className={style.RegSlogan}>
                    <FormattedHTMLMessage id="landing.common.slogan" />
                  </div>

                  <Button
                    className={style.RegAction}
                    onClick={handleRegistrationClick}
                  >
                    <FormattedHTMLMessage
                      defaultMessage="Sign Up"
                      id="sign.up.title"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </Query>
);

export default compose(
  withProps(({ match }) => {
    const code: string = get(match, 'params.code');

    return { code };
  }),
  withHandlers({
    handleCopy: () => data => {
      const $input = document.createElement('input');

      $input.value = data;
      document.body.appendChild($input);

      $input.select();
      $input.setSelectionRange(0, 99999);

      document.execCommand('copy');
      document.body.removeChild($input);
    },
    handleRegistrationClick: ({ code, history }): Function => (): void =>
      history.push(`/sign/up?refcode=${code}`)
  })
)(Landing);
