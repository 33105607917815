import * as React from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import Button from '@components/Button';
import Form from '@components/Form';

// Styles
import styles from './Form.scss';

const TasksForm = ({
  disabled,
  error,
  handleSubmit,
  onCancel,
  onClose,
  pattern,
  submitting
}) => (
  <Form
    classNames={{ error: styles.Error }}
    error={error}
    onSubmit={handleSubmit}
  >
    {pattern && (
      <div className={styles.Fieldset}>
        {pattern.map(({ Component, ...field }, index) => (
          <Component key={index} {...field} disabled={disabled || submitting} />
        ))}
      </div>
    )}

    <div className={styles.Actions}>
      <Button color="secondary" onClick={onClose}>
        <FormattedMessage defaultMessage="Hide" id="common.action.hide" />
      </Button>

      {!disabled && (
        <div>
          <Button color="danger" disabled={submitting} onClick={onCancel}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="common.action.cacnel"
            />
          </Button>

          <Button color="success" loaded={submitting} type="submit">
            <FormattedMessage
              defaultMessage="Complete"
              id="common.action.complete"
            />
          </Button>
        </div>
      )}
    </div>
  </Form>
);

export default reduxForm()(TasksForm);
