export const BASE = 'BASE';
export const DISPLAY = 'DISPLAY';
export const IN = 'IN';
export const LIQUID = 'LIQUID';
export const OUT = 'OUT';
export const PRODUCT = 'PRODUCT';
export const UPDATE = 'UPDATE';

export default {
  BASE,
  DISPLAY,
  IN,
  LIQUID,
  OUT,
  PRODUCT,
  UPDATE
};
