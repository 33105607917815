import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Form, { Input, Select } from '@components/Form';

// Constants
import { IN, LIQUID } from '@constants/purposes';

// Styles
import transferStyle from '../../../Transfer/containers/Form/Form.scss';
import styles from './Form.scss';

const BuyForm = props => {
  const {
    accounts,
    commissionAmount,
    commissionError,
    currencies,
    currentProductTypeId,
    error,
    handleSubmit,
    initialValues,
    isHelpCode,
    isLoaded,
    maxNum,
    onSubmit,
    partner,
    product,
    productTypeOptions,
    productTypes,
    rateId,
    showProductTypes,
    submitting,
    total
  } = props;

  const isUpgrade = !!initialValues.productId;

  const options = React.useMemo(() => {
    // eslint-disable-next-line eqeqeq
    const product = productTypes.find(({ id }) => id == currentProductTypeId);

    try {
      const json = JSON.parse(product.priceOptions);

      return Object.keys(json).map(key => ({ label: json[key], value: key }));
    } catch (e) {
      return [];
    }
  }, [currentProductTypeId, productTypes]);

  return (
    <Form
      classNames={{ root: styles.Root }}
      error={commissionError || error}
      onSubmit={handleSubmit}
    >
      {partner && (
        <div className={transferStyle.Partner}>
          <Avatar
            className={transferStyle.Avatar}
            value={get(partner, 'image')}
          />

          <div className={transferStyle.Info}>
            <div className={transferStyle.Name}>{get(partner, 'name')}</div>
            <div className={transferStyle.Email}>{`ID: ${get(
              partner,
              'userIdTo'
            )}, ${get(partner, 'attributes.email')}`}</div>
          </div>
        </div>
      )}

      {!showProductTypes && product && (
        <div className={styles.Product}>
          <div className={styles.Title}>{product.name}</div>
          <div className={styles.Description}>{product.description}</div>
        </div>
      )}

      <div className={styles.Fieldset}>
        <Select
          label="withdraw.form.currencyIdFrom.label"
          name="currencyIdFrom"
        >
          {currencies
            .filter(({ active }) => !!active)
            .map(({ id, name, purposes, rate }): React.Element<'option'> => {
              const account = accounts.find(
                ({ currencyId }) => currencyId === id
              );

              const isIn = purposes.indexOf(IN) > -1;
              const isLiquid = purposes.indexOf(LIQUID) > -1;
              // debugger; // eslint-disable-line
              return (
                ((account && isLiquid) || (!account && isIn)) && (
                  <option key={id} value={id}>
                    {account && isLiquid
                      ? `${name} ${(account.value || 0).toFixed(4)} ($${(
                          rate * (account.value || 0)
                        ).toFixed(2)})`
                      : id}
                  </option>
                )
              );
            })}
        </Select>

        {showProductTypes && (
          <Select
            disabled={isHelpCode}
            label="products.form.productTypes"
            name="productTypeId"
          >
            {productTypes.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>
        )}

        {isUpgrade && productTypeOptions && (
          <Select
            disabled={isHelpCode}
            label="products.form.productTypes"
            name="productTypeId"
          >
            {productTypeOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        )}

        {!isUpgrade && options && options.length > 1 && (
          <Select
            disabled={isHelpCode}
            label="products.form.priceOptions"
            name="priceOption"
          >
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        )}

        <Input
          disabled={
            isHelpCode ||
            isUpgrade ||
            (product && product.maxNum === 1) ||
            maxNum === 1
          }
          label="transfer.form.amount.label"
          name="amount"
          type="number"
        />

        {!isUpgrade && (
          <Input label="transfer.form.comment.label" name="comment" />
        )}
      </div>

      {!!commissionAmount && (
        <div className={styles.Commission}>
          <div className={styles.CommissionAmount}>
            <FormattedMessage
              id="transfer.commission.amount"
              values={{ amount: commissionAmount }}
            />
          </div>

          <div className={styles.Total}>
            <FormattedMessage
              id="transfer.commission.total"
              values={{ total }}
            />
          </div>
        </div>
      )}

      <div className={styles.Actions}>
        {!isHelpCode && (
          <Button
            color="secondary"
            icon="fas fa-hand-holding-dollar"
            loaded={submitting || isLoaded}
            onClick={handleSubmit(values =>
              onSubmit({ ...values, isHelp: true }, props.dispatch, props))}
          >
            <FormattedMessage id="helpCode.button" />
          </Button>
        )}

        <Button
          color="success"
          disabled={!rateId}
          fullWidth
          loaded={submitting || isLoaded}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Purchase"
            id="products.buy.submit"
          />
        </Button>
      </div>
    </Form>
  );
};

export default BuyForm;
