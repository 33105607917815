import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';

// Components
import Modal from '@components/Modal';

// Containers
import Form from './containers/Form';

// Constants
import { BASE, OUT } from '@constants/purposes';

// Ducks
import { TRANSFER_MODAL_ID } from './ducks';

// GraphQL
import getAccountsQuery from '@graphql/getAccounts.graphql';

const Transfer = (): React.Element<typeof Modal> => (
  <Query query={getAccountsQuery}>
    {({ data }) => {
      const accounts: Array = get(data, 'getAccounts', []);
      const currencies: Array = get(data, 'getCurrencies', []);

      return (
        <Modal id={TRANSFER_MODAL_ID}>
          {props => {
            const isWithdraw = !!get(props, 'withdraw');

            const filteredCurrencies: Array = currencies
              .filter(({ purposes }) => purposes.indexOf(BASE) === -1)
              .filter(
                ({ purposes }) =>
                  !isWithdraw || (isWithdraw && purposes.indexOf(OUT) > -1)
              );

            const currencyIdFrom = get(
              props,
              'currencyId',
              get(filteredCurrencies, '0.id')
            );

            const currencyIdTo = get(
              props,
              'currencyIdTo',
              get(filteredCurrencies, '0.id')
            );

            const partner = get(props, 'partner');

            return (
              filteredCurrencies.length > 0 &&
              (partner || isWithdraw) && (
                <Form
                  accounts={accounts}
                  currencies={filteredCurrencies}
                  initialValues={{
                    currencyIdFrom,
                    currencyIdTo,
                    userIdTo: get(partner, 'userIdTo')
                  }}
                  partner={partner}
                  withdrawMode={isWithdraw}
                />
              )
            );
          }}
        </Modal>
      );
    }}
  </Query>
);

export default Transfer;
