import classNames from 'classnames';
import * as React from 'react';

// Style
import style from './Block.scss';

const Block = ({
  children,
  className,
  title
}: BlockPropTypes): React.Element<'div'> => (
  <div className={classNames(className, style.Root)}>
    {title && <div className={style.Title}>{title}</div>}

    <div className={style.Container}>{children}</div>
  </div>
);

export default Block;
export type BlockPropTypes = {
  children: React.Node,
  className: ?string,
  title: ?string
};
