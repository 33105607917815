import classNames from 'classnames';
import * as React from 'react';

// Components
import Avatar from '@components/Avatar';

// Constants
import { FAILED, PENDING, PROCESSED } from '@constants/statuses';

// Style
import style from './Item.scss';

// Views
import { Status } from '@views/Transactions';

const WithdrawsItem = ({
  address,
  comment,
  onClick,
  status,
  tx: { from: { comissionFrom, currencyFrom, valueFrom } = {} } = {},
  user: { avatar, id, email, firstname, surname } = {}
}: WithdrawsItemPropTypes): React.Element<'div'> => (
  <div className={style.Root} onClick={onClick} role="button" tabIndex={0}>
    <div className={style.Header}>
      <div className={style.User}>
        <Avatar className={style.Avatar} value={avatar} />

        <div className={style.Info}>
          <div className={style.Name}>{`${firstname || ''} ${surname ||
            ''}`}</div>

          <div className={style.Attributes}>
            <div className={style.Field}>
              <span className={style.Label}>ID:</span>
              <span className={style.Value}>{id}</span>
            </div>

            <div className={style.Field}>
              <span className={style.Label}>Email:</span>
              <span className={style.Value}>{email}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={style.State}>
        <div className={style.Amount}>
          {(valueFrom - comissionFrom).toFixed(6)}
          <span className={style.Unit}>{currencyFrom}</span>
        </div>

        <div className={style.Status}>
          <Status value={status} />
        </div>
      </div>
    </div>

    <div className={style.Content}>
      {comment && (
        <div
          className={classNames(style.Comment, {
            [style.CommentIsFailed]: status === FAILED,
            [style.CommentIsProcessed]: status === PROCESSED
          })}
        >
          {comment}
        </div>
      )}

      {address && <div className={style.Comment}>{address}</div>}
    </div>
  </div>
);

export default WithdrawsItem;
export type WithdrawsItemPropTypes = {
  address?: string,
  comment?: string,
  onClick?: (SyntheticEvent<HTMLDivElement>) => ?void,
  status: typeof FAILED | typeof PENDING | typeof PROCESSED,
  tx: {
    currencyTo: string,
    valueTo: string
  },
  user: {
    avatar?: number,
    id: string,
    email: string,
    firstname: string,
    surname: string
  }
};
