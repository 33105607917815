import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';

// Fragments
import introspectionQueryResultData from './fragmentTypes.json';

import 'animate.css/animate.min.css';
import 'react-table/react-table.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

// GraphQL
import refreshTokenMutation from '@graphql/refreshToken.graphql';

// Services
import {
  FAILED_RELOGIN_COOKIE_ID,
  REFRESH_TOKEN_COOKIE_ID,
  TOKEN_COOKIE_ID,
  logout,
  getToken,
  setToken
} from '@services/session';

// Store
import createStore from './store';

// Utils
import * as serviceWorker from '@utils/serviceWorker';

// Views
import App from './App';

const initProject = ({ apiUrl, logo }) => {
  // Set API endpoints
  window.API_URL =
    apiUrl ||
    `https://${
      process.env.NODE_ENV === 'production'
        ? window.location.host
            .split('.')
            .map((match, index) => (index === 0 ? `${match}-api` : match))
            .join('.')
        : 'my.oasisddb.com'
    }`;

  window.LOGO = logo;

  const ghRecoveryPath: string = localStorage.getItem('gh-recovery-path');
  const history = createBrowserHistory();
  const store = createStore();

  if (ghRecoveryPath) {
    history.replace(ghRecoveryPath);
    localStorage.removeItem('gh-recovery-path');
  }

  const uploadLink = createUploadLink({
    credentials: 'include',
    uri: `${window.API_URL}/graphql`
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const refreshClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([uploadLink])
  });

  let currentMutation = null;

  // Handle graphql query errors
  const errorLink = onError(({ forward, graphQLErrors, operation }) => {
    const { dispatch } = store;

    if (graphQLErrors) {
      const isUnauthenticated = graphQLErrors.find(
        ({ extensions }) => get(extensions, 'code') === 'UNAUTHENTICATED'
      );

      if (isUnauthenticated) {
        const refreshToken: string = Cookies.get(REFRESH_TOKEN_COOKIE_ID);

        if (!refreshToken) {
          dispatch(logout());
        } else {
          if (!currentMutation) {
            currentMutation = refreshClient
              .mutate({
                mutation: refreshTokenMutation,
                variables: { token: Cookies.get(REFRESH_TOKEN_COOKIE_ID) }
              })
              .then(({ data }) => {
                currentMutation = null;

                const refreshToken: Date = get(
                  data,
                  'refreshToken.refreshToken'
                );
                const refreshTokenTill: Date = get(
                  data,
                  'refreshToken.refreshTokenTill'
                );
                const token: string = get(data, 'refreshToken.accessToken');
                const tokenTill: Date = get(
                  data,
                  'refreshToken.accessTokenTill'
                );

                Cookies.set(TOKEN_COOKIE_ID, token, {
                  expires: new Date(tokenTill)
                });

                dispatch(
                  setToken({
                    refreshToken,
                    refreshTokenTill,
                    token,
                    tokenTill
                  })
                );

                return true;
              })
              .catch(() => {
                Cookies.set(FAILED_RELOGIN_COOKIE_ID, true);
                Cookies.remove(REFRESH_TOKEN_COOKIE_ID);
                dispatch(logout());
              });
          }

          return new Observable(subscriber => {
            currentMutation.then(() => {
              const state = store.getState();
              const token: string = getToken(state);

              // Re-new headers
              if (token) {
                operation.setContext({
                  headers: {
                    ...operation.getContext().headers,
                    authorization: token
                  }
                });
              }

              forward(operation).subscribe(subscriber);
            });

            return subscriber;
          });
        }
      }
    }

    return null;
  });

  const authLink = new ApolloLink( (operation, forward) => {
    const state = store.getState();
    const token: string = getToken(state) ;

    // Re-new headers
    if (token) {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          authorization:  token
        }
      });
    }

    return forward(operation);
  });

  const client: ApolloClient = new ApolloClient({
    cache: new InMemoryCache({ fragmentMatcher }),
    link: authLink.concat(errorLink).concat(uploadLink)
  });

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router history={history}>
          <Route component={App} path="/" />
        </Router>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

fetch('/config.json')
  .then(res => res.json())
  .then(initProject)
  .catch(initProject);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
