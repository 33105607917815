import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

// Style
import style from './Link.scss';

const MainLink = ({
  exact,
  external,
  icon,
  onClick,
  title,
  to
}: MainLinkPropTypes): React.Element<'a'> => {
  const Component: React.Node = external ? 'a' : NavLink;

  const externalProps: Object = {
    href: to,
    target: '_blank'
  };

  const props: Object = {
    activeClassName: style.RootIsSelected,
    exact,
    to
  };

  return (
    <Component
      {...(external ? externalProps : props)}
      className={classNames(style.Root, {
        [style.RootVariantExternal]: external
      })}
      onClick={onClick}
    >
      {icon && <i className={classNames(icon, style.Icon)} />}

      <div className={style.Title}>
        <FormattedMessage defaultMessage={title} id={title} />
      </div>

      {external && (
        <div className={classNames('far fa-external-link', style.External)} />
      )}
    </Component>
  );
};

export default MainLink;
export type MainLinkPropTypes = {
  exact: ?boolean,
  external: ?boolean,
  icon: ?string,
  onClick: Function,
  title: string,
  to: string
};
