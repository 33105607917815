import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, withProps } from 'recompose';

// Style
import style from './Summary.scss';

const DashboardSummary = ({
  bonusBase,
  bonusTill,
  input,
  inputThreshold,
  progress,
  roundedProgress,
  // State
  isFinished
}: DashboardSummaryPropTypes): React.Element<'div'> => (
  <div
    className={classNames(style.Root, {
      [style.RootIsFinished]: isFinished
    })}
  >
    {inputThreshold !== 0 ? (
      <React.Fragment>
        <div className={style.Progress}>
          <div className={style.Wrapper}>
            <div className={style.Bar} style={{ width: `${roundedProgress}%` }}>
              <div className={style.Input}>{(input || 0).toFixed(2)}</div>
            </div>

            {!isFinished && (
              <div
                className={style.Threshold}
                style={{
                  left: `${roundedProgress}%`,
                  width: `${100 - roundedProgress}%`
                }}
              >
                <div className={style.ThresholdInput}>
                  {`+${((inputThreshold || 0) - (input || 0)).toFixed(2)}`}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={style.Goal}
          style={{
            left: `calc(${
              !isFinished ? 100 : Math.max(38, 100 - (progress - 100))
            }% - 116px)`
          }}
        >
          <div className={style.Title}>
            <FormattedMessage
              defaultMessage={isFinished ? 'DONE!' : 'BONUS'}
              id={
                isFinished
                  ? 'dashboard.summary.done'
                  : 'dashboard.summary.bonus'
              }
            />
          </div>
          <div className={style.Divider} />
          <div className={style.Bonus}>
            {`${isFinished ? '+' : ''}$${(bonusBase || 0).toFixed(2)}`}
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div className={style.Empty}>
        <div className={style.Field}>
          <div className={style.Label}>
            <FormattedMessage
              defaultMessage="BONUS"
              id="dashboard.summary.bonus.empty"
            />
          </div>

          <div className={style.Value}>{`$${(bonusBase || 0).toFixed(2)}`}</div>
        </div>

        <div className={style.Field}>
          <div className={style.Label}>
            <FormattedMessage
              defaultMessage="END OF PERIOD"
              id="dashboard.summary.period"
            />
          </div>

          <div className={style.Value}>
            {moment(bonusTill).format('DD.MM.YYYY HH:mm')}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default compose(
  withProps(({ data }) => {
    const { bonusBase, bonusTill, input, inputThreshold } = get(
      data,
      'getSummary',
      {}
    );

    const progress: number = (input / inputThreshold) * 100;

    return {
      bonusBase,
      bonusTill,
      input,
      inputThreshold,
      progress,
      isFinished: progress >= 100,
      roundedProgress: Math.min(100, progress)
    };
  })
)(DashboardSummary);

export type DashboardSummaryPropTypes = {
  bonus: ?string,
  bonusBase: ?number,
  input: ?number,
  inputThreshold: number,
  isFinished: boolean,
  progress: number,
  roundedProgress: number
};
