import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

// Components
import Button from '@components/Button';
import Modal from '@components/Modal';

// Services
import { closeModals } from '@services/modals';

// Styles
import styles from './HelpCode.scss';
import { connect } from 'react-redux';

const HelpCode = ({ closeModals }) => {
  // State
  const [hasCopied, setCopyState] = useState(false);

  // Handlers
  const handleCopyClick = useCallback(code => {
    setCopyState(true);
    copy(code);

    setTimeout(() => setCopyState(false), 1000);
  }, []);

  return (
    <Modal id="123">
      {props => (
        <div className={styles.Root}>
          <div className={styles.Description}>
            <FormattedMessage id="helpCode.modal.description" />
          </div>

          <div className={styles.Code}>
            <div className={styles.Text}>{props.code}</div>
            <Button
              icon={classNames('far', {
                'fa-clipboard': !hasCopied,
                'fa-clipboard-check': hasCopied
              })}
              onClick={() => handleCopyClick(props.code)}
            />
          </div>

          <div className={styles.Actions}>
            <Button color="success" fullWidth onClick={closeModals}>
              <FormattedMessage id="common.action.complete" />
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default compose(connect(null, { closeModals }))(HelpCode);
