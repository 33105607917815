import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { compose, withHandlers } from 'recompose';

// Services
import { closeModal, getModalById } from '@services/modals';

// Style
import style from './Modal.scss';

const Modal = ({
  children,
  className,
  classNames: {
    root: rootClassName,
    container: containerClassName,
    title: titleClassName
  } = {},
  title,
  // Handlers
  handleClose,
  // State
  isOpened,
  ...props
}: ModalPropTypes): React.Element<typeof Portal> => (
  <Portal>
    <CSSTransition
      classNames={{
        enter: style.RootAnimateEnter,
        enterActive: style.RootAnimateEnterActive
      }}
      in={isOpened}
      timeout={{ enter: 400, exit: 0 }}
      unmountOnExit
    >
      <div className={classNames(className, rootClassName, style.Root)}>
        <div className={style.Backdrop} onClick={handleClose} />

        <div className={classNames(containerClassName, style.Container)}>
          {title && (
            <div className={classNames(titleClassName, style.Title)}>
              {typeof title === 'string' ? (
                <FormattedMessage defaultMessage={title} id={title} />
              ) : (
                title
              )}
            </div>
          )}
          <div className={style.Content}>
            {typeof children === 'function' ? children(props) : children}
          </div>
        </div>
      </div>
    </CSSTransition>
  </Portal>
);

const mapStateToProps: Function = (
  state: Object,
  { id, isOpened, title }: ModalPropTypes
) => {
  const modal: Object = getModalById(state, id);

  return {
    isOpened: isOpened || !!modal,
    title: title || get(modal, 'title'),
    ...modal
  };
};

export default compose(
  connect(
    mapStateToProps,
    { closeModal }
  ),
  withHandlers({
    handleClose: ({ closeModal, id, onClose }): Function => (): void => {
      closeModal(id);
      onClose && onClose();
    }
  })
)(Modal);

export type ModalPropTypes = {
  children: React.Node,
  className: ?string,
  handleClose: Function,
  id: string,
  isOpened: boolean,
  onClose: ?Function,
  title: ?string
};
