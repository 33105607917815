export const MENU = [
  {
    links: [
      {
        exact: true,
        icon: 'fal fa-tachometer',
        title: 'menu.dashboard',
        to: '/'
      },
      {
        icon: 'fal fa-users',
        title: 'menu.partners',
        to: '/partners'
      },
      {
        icon: 'fal fa-vote-yea',
        title: 'menu.withdraws',
        to: '/withdraws'
      },
      {
        icon: 'fal fa-album-collection',
        title: 'menu.products',
        to: '/products'
      },
      {
        icon: 'fal fa-robot',
        title: 'menu.providers',
        to: '/providers'
      },
      {
        icon: 'fal fa-tasks',
        title: 'menu.tasks',
        to: '/tasks'
      },
      {
        icon: 'fal fa-receipt',
        title: 'menu.transactions',
        to: '/transactions'
      }
    ],
    title: 'menu.main'
  },
  {
    links: [
      {
        icon: 'fal fa-history',
        title: 'menu.logs',
        to: '/logs'
      },
      {
        icon: 'fal fa-cog',
        title: 'menu.settings',
        to: '/settings'
      },
      {
        hidden: true,
        title: 'menu.user',
        to: '/users/'
      }
    ],
    title: 'menu.profile'
  },
  {
    admin: true,
    links: [
      {
        icon: 'fal fa-shield-alt',
        title: 'menu.users',
        to: '/admin/users'
      },
      {
        icon: 'fal fa-shield-alt',
        title: 'menu.verifications',
        to: '/admin/verifications'
      },
      {
        icon: 'fal fa-shield-alt',
        title: 'menu.withdraws',
        to: '/admin/withdraws'
      }
    ],
    title: 'menu.admin'
  }
];
