import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import Block from '@components/Block';
import Table from '@components/Table';

// Constants
import { VERIFICATION_TYPE_ADMIN } from '@constants/verification';

// Containers
import { VERIFY_MODAL_ID } from '@containers/Verify';

// GraphQL
import getVerificationsQuery from '@graphql/getVerifications.graphql';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Verifications.scss';

// Views
import { Status } from '@views/Transactions';

const COLUMNS = [
  {
    accessor: 'id',
    Header: 'ID',
    resizable: false,
    sortable: false,
    width: 80
  },
  {
    accessor: 'status',
    Cell: ({ original, value }) => <Status {...original} value={value} />,
    Header: () => (
      <FormattedMessage defaultMessage="Status" id="common.field.status" />
    ),
    width: 120
  },
  {
    accessor: 'user',
    Cell: ({ value }) => (
      <div className={style.User}>
        <Avatar className={style.UserAvatar} value={get(value, 'avatar')} />

        <div className={style.UserInfo}>
          <div className={style.UserName}>{`${get(value, 'surname') ||
            ''} ${get(value, 'firstname') || ''}`}</div>

          <div className={style.UserEmail}>{get(value, 'email')}</div>
        </div>
      </div>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="User" id="common.field.user" />
    ),
    sortable: false,
    style: {
      justifyContent: 'flex-start'
    },
    width: 240
  },
  {
    accessor: 'type',
    Header: () => (
      <FormattedMessage defaultMessage="Type" id="common.field.type" />
    )
  },
  {
    accessor: 'comment',
    Header: () => (
      <FormattedMessage defaultMessage="Comment" id="common.field.comment" />
    ),
    width: 240
  }
];

const Verifications = ({ handleRowClick }) => (
  <Query query={getVerificationsQuery}>
    {({ data, loading }) => {
      const tableData: Array = get(data, 'getVerifications', []);

      return (
        <Block
          title={
            <FormattedMessage
              defaultMessage="Verification request list ({count})"
              id="verifications.title"
              values={{ count: tableData.length }}
            />
          }
        >
          <Table
            columns={COLUMNS}
            data={tableData}
            getTrProps={(state, { original }) => ({
              onClick: () => handleRowClick(original)
            })}
            loading={loading}
            showPagination={false}
          />
        </Block>
      );
    }}
  </Query>
);

export default compose(
  connect(
    null,
    { openModal }
  ),
  withHandlers({
    handleRowClick: ({ openModal }) => verification =>
      openModal(VERIFY_MODAL_ID, {
        data: verification,
        type: VERIFICATION_TYPE_ADMIN
      })
  })
)(Verifications);
