import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Button
import Button from '@components/Button';

// Hooks
import useProvider from '../Providers.hooks';

// Styles
import styles from './Item.scss';

const ProductsItem = ({
  id,
  description,
  icon,
  name,
  preferences,
  textId,
  version
}) => {
  const { isParsed, openProviderModal } = useProvider({
    id,
    preferences
  });

  return (
    <div className={styles.Root}>
      <div className={styles.Info}>
        <div className={styles.Header}>
          <div className={styles.Title}>{name}</div>
          <img
            alt={name}
            className={styles.Logo}
            src={`${window.API_URL}/${icon}`}
          />
        </div>
        <div
          className={styles.Description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <div className={styles.About}>
        <div className={styles.Item} title="Version">
          <i className={classNames(styles.Icon, 'fad fa-code-merge')} />
          <div className={styles.Value}>{version}</div>
        </div>
      </div>

      {isParsed && (
        <div className={styles.Actions}>
          <Button fullWidth onClick={openProviderModal}>
            <FormattedMessage defaultMessage="Add" id="providers.item.add" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductsItem;
