import classNames from 'classnames';
import Inputmask from 'inputmask';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, lifecycle } from 'recompose';

// Components
import { Field } from '@components/Form';

// Style
import style from './Input.scss';

const FormInput = ({
  disabled,
  disableTrim = false,
  error,
  id,
  name,
  onChange,
  placeholder,
  type = 'text',
  value
}: FormInputPropTypes): React.Element<'div'> => (
  <div
    className={classNames(style.Root, {
      [style.RootIsDisabled]: !!disabled,
      [style.RootIsErred]: !!error
    })}
  >
    <FormattedMessage
      defaultMessage={placeholder}
      id={placeholder || 'common.empty'}
    >
      {placeholder => (
        <input
          autoComplete="off"
          className={style.Input}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={() =>
            typeof value === 'string' && !disableTrim && onChange(value.trim())
          }
          onChange={onChange}
          placeholder={placeholder === '_' ? '' : placeholder}
          step={type === 'number' ? 'any' : null}
          type={type}
          value={value}
        />
      )}
    </FormattedMessage>
  </div>
);

const ComposedFormInput = compose(
  lifecycle({
    componentDidMount() {
      const { id, mask } = this.props;

      if (id && mask) {
        Inputmask(mask).mask(document.getElementById(id));
      }
    }
  })
)(FormInput);

export default props => (
  <Field {...props}>
    <ComposedFormInput />
  </Field>
);

export type FormInputPropTypes = {
  disabled: ?boolean,
  error: ?string,
  id: string,
  mask: ?string,
  name: string,
  onChange: Function,
  placeholder: ?string,
  type: ?string,
  value: boolean | number | string
};

export const CustomInput = ComposedFormInput;
