import { get, merge } from 'lodash';
import * as React from 'react';
import { useMutation } from 'react-apollo';
import { useDispatch } from 'react-redux';

// Data
import { ACCOUNT_CODE_MODAL_ID, PROVIDER_MODAL_ID } from './data';

// GraphQL
import activateAccountMutation from './graphql/activateAccount.graphql';
import deleteAccountMutation from './graphql/deleteAccount.graphql';
import executeAccountMutation from './graphql/executeAccount.graphql';
import getProvidersQuery from './graphql/getProviders.graphql';

// Services
import { openModal } from '@services/modals';

// Utils
import { parseToFields } from '@utils/xml';

const useProvider = (
  { id: providerId, counters, preferences },
  {
    active = false,
    id,
    name,
    payload,
    task: {
      codes,
      resultError: resultErrorJSON,
      resultErrorTime,
      resultSuccess: resultSuccessJSON,
      resultSuccessTime
    } = {}
  } = {}
) => {
  // GraphQL
  const [activateAccount] = useMutation(activateAccountMutation);
  const [deleteAccount] = useMutation(deleteAccountMutation);
  const [executeAccount] = useMutation(executeAccountMutation);

  // Result
  const [error, setError] = React.useState(null);
  const [result, setResult] = React.useState(null);

  // Settings
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = React.useState({});
  const [pattern, setPattern] = React.useState(null);
  const [validate, setValidate] = React.useState(null);

  let initialValues = { id, providerId, name };

  if (payload) {
    try {
      initialValues = { ...initialValues, ...JSON.parse(payload) };
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  React.useEffect(() => {
    if (
      resultErrorJSON &&
      new Date(resultErrorTime) > new Date(resultSuccessTime)
    ) {
      try {
        const { message } = JSON.parse(resultErrorJSON)[0];
        setError(message);
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    } else {
      setError(null);
    }

    if (resultSuccessJSON) {
      try {
        let result = {};
        const results = JSON.parse(resultSuccessJSON);

        results.forEach(item => {
          result = merge(result, item);
        });

        setResult(result);
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    }
  }, [resultErrorJSON, resultErrorTime, resultSuccessJSON, resultSuccessTime]);

  const handleActivate = () =>
    activateAccount({
      refetchQueries: [{ query: getProvidersQuery }],
      variables: { id: parseInt(id, 10), active: !active }
    });

  const handleDelete = () =>
    deleteAccount({
      refetchQueries: [{ query: getProvidersQuery }],
      variables: { ids: [parseInt(id, 10)] }
    });

  const handleExecute = () =>
    executeAccount({
      refetchQueries: [{ query: getProvidersQuery }],
      variables: { ids: [parseInt(id, 10)] }
    });

  const openCodeModal = () => {
    const code = codes.sort(({ till: tillA }, { till: tillB }) => {
      const a = new Date(tillA);
      const b = new Date(tillB);

      return a > b ? 1 : a < b ? -1 : 0;
    })[0];

    if (code) {
      return dispatch(
        openModal(ACCOUNT_CODE_MODAL_ID, {
          data: {
            id: code.id,
            description: get(code, 'params.prompt'),
            image: get(code, 'params.image'),
            initialValues: { id: code.id },
            inputType: get(code, 'params.inputType')
          }
        })
      );
    }

    return null;
  };

  const openProviderModal = () =>
    dispatch(
      openModal(PROVIDER_MODAL_ID, {
        data: {
          id,
          initialValues: { ...defaultValue, ...initialValues },
          pattern,
          validate
        },
        title: initialValues.id
          ? 'providers.account.edit.title'
          : 'providers.add.title'
      })
    );

  React.useEffect(() => {
    if (preferences) {
      const { defaultValue, pattern, validate } = parseToFields(preferences);

      setDefaultValue(defaultValue);
      setPattern(pattern);
      setValidate(validate);
    }
  }, [preferences]);

  return {
    error,
    errorTime: resultErrorTime,
    handleActivate,
    handleDelete,
    handleExecute,
    isParsed: !!pattern,
    openCodeModal,
    openProviderModal,
    result,
    resultTime: resultSuccessTime
  };
};

export default useProvider;
