import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router-dom';
import { compose, withProps, withState } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Form, { asyncValidator, errorHandler, Input } from '@components/Form';
import Modal from '@components/Modal';

// GraphQL
import CHANGE_SPONSOR from '@graphql/changeSponsor.graphql';
import CONFIRM_CHANGE_SPONSOR from '@graphql/confirmChangeSponsor.graphql';
import GET_SPONSOR from '@graphql/getSponsor.graphql';

// Services
import { closeModals } from '@services/modals';

// Styles
import styles from './common.scss';

const DashboardChangeSponsor = ({
  confirmId,
  error,
  handleSubmit,
  isSucceeded,
  sponsor,
  submitting,
  tries
}) => {
  const { avatar, email, firstname, id, surname } = sponsor;

  return (
    <Modal
      classNames={{ container: styles.Modal }}
      id="changeSponsorModal"
      title="dashboard.sponsor.change.trigger"
    >
      {!error && tries >= 0 && (
        <div className={styles.Tries}>
          <FormattedMessage
            id={`confirm.form.error.${tries === 0 ? 'fail' : 'tries'}`}
            values={{ tries }}
          />
        </div>
      )}

      <Form className={styles.Root} error={error} onSubmit={handleSubmit}>
        <div className={styles.Description}>
          <FormattedMessage
            id={`dashboard.sponsor.change.description.${
              confirmId ? 'code' : 'link'
            }`}
          />
        </div>

        {confirmId && (
          <>
            <div className={styles.Sponsor}>
              <Avatar className={styles.Avatar} value={avatar} />

              <div className={styles.Info}>
                <div className={styles.Name}>
                  {firstname} {surname}
                </div>

                <div className={styles.Extra}>
                  <div>
                    ID:{' '}
                    <a
                      href={`/users/${id}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {id}
                    </a>
                  </div>

                  <div>
                    <FormattedMessage
                      defaultMessage="E-mail"
                      id="common.email"
                    />
                    : <strong>{email}</strong>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {!isSucceeded && (
          <Input
            disabled={submitting}
            label={`dashboard.sponsor.change.form.${
              confirmId ? 'code' : 'link'
            }`}
            name="code"
          />
        )}

        <div className={styles.Actions}>
          <Button
            color={confirmId ? 'success' : 'primary'}
            disabled={isSucceeded}
            fullWidth
            icon={isSucceeded && 'fas fa-badge-check'}
            loaded={submitting}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Submit"
              id={`dashboard.sponsor.change.form.submit.${
                confirmId ? (isSucceeded ? 'succeeded' : 'confirm') : 'request'
              }`}
            />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default compose(
  graphql(CHANGE_SPONSOR, { name: 'changeSponsor' }),
  graphql(CONFIRM_CHANGE_SPONSOR, {
    name: 'confirmChangeSponsor'
  }),
  withState('confirmId', 'setConfirmId', null),
  withState('sponsor', 'setSponsor', {}),
  withState('tries', 'setTries', undefined),
  withState('isSucceeded', 'setSuccessStatus', false),
  withProps(({ confirmId }) => ({
    asyncValidate: asyncValidator(
      yup.object().shape({
        code: yup
          .string()
          .required()
          .test('isRefCode', 'Invalid link', value => {
            if (confirmId) return true;

            const allowHostnames = ['localhost', 'my.digitaldoubles.com', 'my.fai.limited',  'cab.sba.plus'];

            try {
              const { hostname, pathname } = new URL(value);
              const match = matchPath(pathname, { path: '/dm/:code' });

              if (
                allowHostnames.indexOf(hostname) === -1 ||
                !match?.params.code
              ) {
                throw new Error();
              }
            } catch (e) {
              return false;
            }

            return true;
          })
      })
    )
  })),
  reduxForm({
    form: 'changeSponsorForm',
    onSubmit: async (
      { code },
      dispatch,
      {
        changeSponsor,
        confirmChangeSponsor,
        confirmId,
        reset,
        setConfirmId,
        setTries,
        setSponsor,
        setSuccessStatus
      }
    ) => {
      try {
        if (!confirmId) {
          const { pathname } = new URL(code);
          const match = matchPath(pathname, { path: '/dm/:code' });
          const { data } = await changeSponsor({
            variables: { code: match.params.code }
          });

          setConfirmId(data.changeSponsor.confirm.id);
          setSponsor(data.changeSponsor.sponsor);
          setTries(undefined);

          reset();
        } else {
          try {
            const { data } = await confirmChangeSponsor({
              refetchQueries: [{ query: GET_SPONSOR }],
              variables: { code, confirmId }
            });

            if (data.confirmChangeSponsor.confirm) {
              const { tries } = data.confirmChangeSponsor.confirm;

              setTries(tries);

              if (tries === 0) {
                setConfirmId(null);
                setSponsor({});
              }
            } else {
              setSuccessStatus(true);
              setTimeout(() => dispatch(closeModals()), 2000);
            }
          } catch (error) {
            return errorHandler(error);
          }
        }
      } catch (error) {
        return errorHandler(error);
      }

      return true;
    }
  })
)(DashboardChangeSponsor);
