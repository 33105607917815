import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import uuid from 'uuid/v5';

// Style
import style from './Field.scss';

// Utils
import reduxFieldAdapter from '../utils/reduxFieldAdapter';

const FormField = (props: FormFieldPropTypes): React.Element<typeof Field> => {
  const { children, id: propId, hidden, info, name, withoutLabel } = props;
  const id = propId || uuid(name, uuid.URL);

  return (
    <Field {...props} component={reduxFieldAdapter}>
      {({ description, error, label, ...props }) => (
        <div
          className={classNames(style.Root, {
            [style.RootIsErred]: !!error,
            [style.RootIsHidden]: !!hidden
          })}
        >
          {!withoutLabel && (label || error) && (
            <div className={style.Header}>
              <div className={style.HeaderLeft}>
                {label && (
                  // eslint-disable-next-line
                  <label className={style.Label} htmlFor={id}>
                    <FormattedMessage defaultMessage={label} id={label} />
                  </label>
                )}

                {error && (
                  <div className={style.Error}>
                    <FormattedMessage defaultMessage={error} id={error}>
                      {error => ` – ${error}`}
                    </FormattedMessage>
                  </div>
                )}
              </div>

              {info && <div className={style.Info}>{info}</div>}
            </div>
          )}

          <div className={style.Control}>
            {typeof children === 'function'
              ? children({ ...props, id, label, error })
              : React.cloneElement(children, { ...props, id, label, error })}
          </div>

          {description && (
            <div className={style.Description}>{description}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default FormField;
export type FormFieldPropTypes = {
  children: React.Node,
  hidden: ?boolean,
  id: ?string,
  info: ?React.Node,
  label: string,
  name: string,
  type: ?string,
  withoutLabel: ?boolean
};
