export const SIGN_CONFIRM_FORM = 'SIGN_CONFIRM_FORM';
export const SIGN_BASE_FORM = 'SIGN_BASE_FORM';

// Confirm types
export const SIGN_CONFIRM_TYPE_CREATE = 'create';
export const SIGN_CONFIRM_TYPE_RECOVER = 'recover';

// Route ids
export const SIGN_CONFIRM_ID = 'confirm';
export const SIGN_IN_ID = 'in';
export const SIGN_RECOVERY_ID = 'recovery';
export const SIGN_UP_ID = 'up';
