import { get, set } from 'lodash';
import { Schema, ValidationError } from 'yup';

export default (schema: Schema) => (values: Object) =>
  schema
    .validate(values, { abortEarly: false })
    .catch((error: ValidationError) => {
      const errors: Object = {};

      get(error, 'inner', []).forEach(({ message, path = '_error' }) => {
        set(errors, path, message);
      });

      // eslint-disable-next-line no-underscore-dangle
      if(Object.keys(errors).length && !errors._error) errors._error = `Please fill required fields: ${Object.keys(errors).join(', ')}`;
      throw errors;
    });
