import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

// Components
import Form, { Select } from '@components/Form';

// GraphQL
import setLanguageMutation from '../graphql/setLanguage.graphql';

// i18n
import { LANG_LIST } from '@i18n/lang';

const MainLang = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Select name="lang">
      {LANG_LIST.map(id => (
        <FormattedMessage key={id} id={`lang.${id}`}>
          {lang => <option value={id}>{lang}</option>}
        </FormattedMessage>
      ))}
    </Select>
  </Form>
);

export default compose(
  graphql(setLanguageMutation, { name: 'setLanguage' }),
  reduxForm({
    form: 'lang',
    onChange: ({ lang }, dispatch, { initialValues, setLanguage }) =>
      lang &&
      initialValues.lang !== lang &&
      setLanguage({ variables: { lang } })
  })
)(MainLang);
