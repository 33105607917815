import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withState } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import { asyncValidator, errorHandler } from '@components/Form';

// Containers
import { CONFIRM_MODAL_ID } from '@containers/Confirm';
import { TRANSFER_FORM_ID, TRANSFER_MODAL_ID } from '../../ducks';

import TransferForm from './Form';

// Constants
import { IN, PRODUCT } from '@constants/purposes';

// GraphQL
import getTransactionsQuery from '@graphql/getTransactions.graphql';
import getTransferCommissionQuery from '@graphql/getTransferComission.graphql';
import getWithdrawCommissionQuery from '@graphql/getWithdrawComission.graphql';
import transferMutation from '@graphql/transfer.graphql';
import withdrawMutation from '@graphql/withdraw.graphql';

// Services
import { closeModal, openModal } from '@services/modals';

export default compose(
  connect(null, { closeModal, openModal }),
  withProps(({ withdrawMode }) => ({
    asyncValidate: asyncValidator(
      yup.object().shape({
        address: withdrawMode && yup.string().required('error.form.required'),
        amount: yup.string().required('error.form.required'),
        comment:
          !withdrawMode &&
          yup.string().matches(/^(?![!\s]).*/, 'transfer.form.comment.error')
      })
    )
  })),
  graphql(getTransferCommissionQuery, { name: 'getTransferCommission' }),
  graphql(getWithdrawCommissionQuery, { name: 'getWithdrawCommission' }),
  graphql(transferMutation, { name: 'transfer' }),
  graphql(withdrawMutation, { name: 'withdraw' }),
  withState('commissionAmount', 'setCommissionAmount', 0),
  withState('commissionError', 'setCommissionError', null),
  withState('currencyIdFrom', 'setCurrencyIdFrom', null),
  withState('currencyIdTo', 'setCurrencyIdTo', null),
  withState('isLoaded', 'setLoadState', false),
  withState('isTransfer', 'setTransferState', false),
  withState('prevAddress', 'setPrevAddress', null),
  withState('prevAmount', 'setPrevAmount', 0),
  withState('rateId', 'setRateId', null),
  withState('total', 'setTotal', 0),
  reduxForm({
    form: TRANSFER_FORM_ID,
    shouldAsyncValidate: () => true,
    onChange: (
      values,
      dispatch,
      {
        change,
        currencies,
        currencyIdFrom,
        currencyIdTo,
        getTransferCommission,
        getWithdrawCommission,
        prevAddress,
        prevAmount,
        setLoadState,
        setCommissionAmount,
        setCommissionError,
        setCurrencyIdFrom,
        setCurrencyIdTo,
        setPrevAddress,
        setPrevAmount,
        setRateId,
        setTotal,
        setTransferState,
        withdrawMode
      }
    ) => {
      if (
        values.amount !== prevAmount ||
        values.address !== prevAddress ||
        values.currencyIdFrom !== currencyIdFrom ||
        values.currencyIdTo !== currencyIdTo
      ) {
        let promise = null;

        setCurrencyIdFrom(get(values, 'currencyIdFrom'));
        setLoadState(true);
        setPrevAddress(values.address);
        setPrevAmount(values.amount);

        if (withdrawMode) {
          promise = getWithdrawCommission
            .refetch({
              address: get(values, 'address'),
              amount: parseFloat(get(values, 'amount', '0').replace(',', '.')),
              currencyIdFrom: get(values, 'currencyIdFrom', currencies[0].id)
            })
            .then(({ data }) => {
              const comissionCost = get(
                data,
                'getWithdrawComission.comission',
                0
              ).toFixed(8);
              const comissionCurrency = currencies.find(
                ({ id }) =>
                  id === get(data, 'getWithdrawComission.comissionCurrencyId')
              );
              const commissionDisplay: string = `${comissionCost} ${
                comissionCurrency.id
              } ($${(comissionCost * comissionCurrency.rate).toFixed(2)})`;

              const totalCost = get(data, 'getWithdrawComission.to', 0).toFixed(
                8
              );
              const totalCurrency = currencies.find(
                ({ id }) =>
                  id === get(values, 'currencyIdFrom', currencies[0].id)
              );

              const total: string = `${totalCost} ${totalCurrency.name ||
                totalCurrency.id} ($${(totalCurrency.rate * totalCost).toFixed(
                2
              )})`;

              setCommissionAmount(commissionDisplay);
              setCommissionError(null);
              setLoadState(false);
              setTotal(total);
              setTransferState(
                get(data, 'getWithdrawComission.opType') === 'TRANSFER'
              );
            });
        } else {
          let { currencyIdTo } = values;

          if (values.currencyIdFrom !== currencyIdFrom) {
            const filteredCurrencies = currencies.filter(
              ({ id, purposes }) =>
                purposes.indexOf(PRODUCT) > -1 ||
                (purposes.indexOf(IN) > -1 &&
                  id === get(values, 'currencyIdFrom'))
            );

            if (filteredCurrencies.length > 0) {
              currencyIdTo = filteredCurrencies[0].id;
              dispatch(change('currencyIdTo', currencyIdTo));
            }
          }

          setCurrencyIdTo(currencyIdTo);

          promise = getTransferCommission
            .refetch({
              amount: parseFloat(get(values, 'amount', '0').replace(',', '.')),
              currencyIdFrom: get(values, 'currencyIdFrom', currencies[0].id),
              currencyIdTo,
              userIdTo: parseInt(get(values, 'userIdTo', 0), 10)
            })
            .then(({ data }) => {
              const comissionCost = get(
                data,
                'getTransferComission.comission',
                0
              ).toFixed(8);
              const comissionCurrency = currencies.find(
                ({ id }) =>
                  id === get(data, 'getTransferComission.comissionCurrencyId')
              );
              const commissionDisplay: string = `${comissionCost} ${
                comissionCurrency.id
              } ($${(comissionCost * comissionCurrency.rate).toFixed(2)})`;

              const totalCost = get(data, 'getTransferComission.to', 0).toFixed(
                8
              );
              const totalCurrency = currencies.find(
                ({ id }) =>
                  id === get(data, 'getTransferComission.toCurrencyId')
              );

              const total: string = `${totalCost} ${totalCurrency.id} ($${(
                totalCurrency.rate * totalCost
              ).toFixed(2)})`;

              setCommissionAmount(commissionDisplay);
              setCommissionError(null);
              setLoadState(false);
              setRateId(get(data, 'getTransferComission.rateId'));
              setTotal(total);
            });
        }

        promise.catch(({ graphQLErrors }) => {
          let error: string = '';

          graphQLErrors &&
            graphQLErrors.forEach(({ message }) => {
              error = message;
            });

          setCommissionError(error);
          setLoadState(false);
        });
      }
    },
    onSubmit: (
      values,
      dispatch,
      {
        closeModal,
        currencies,
        getWithdrawRequest,
        openModal,
        rateId,
        transfer,
        withdraw,
        withdrawMode
      }
    ) => {
      const mutate = withdrawMode
        ? withdraw({
            variables: {
              address: get(values, 'address'),
              amount: parseFloat(get(values, 'amount', '0').replace(',', '.')),
              currencyIdFrom: get(values, 'currencyIdFrom', currencies[0].id)
            }
          })
        : transfer({
            refetchQueries: [{ query: getTransactionsQuery, variables: {} }],
            updateQueries: {
              getTransactions: (prev, { mutationResult }) => {
                const confirmId = get(
                  mutationResult,
                  'data.transfer.confirm.id'
                );
                const tx = get(mutationResult, 'data.transfer.tx');

                return !confirmId && tx
                  ? {
                      ...prev,
                      getTransactions: [...prev.getTransactions, tx]
                    }
                  : prev;
              }
            },
            variables: {
              rateId,
              amount: parseFloat(get(values, 'amount', '0').replace(',', '.')),
              comment: get(values, 'comment'),
              currencyIdFrom: get(values, 'currencyIdFrom', currencies[0].id),
              currencyIdTo: get(values, 'currencyIdTo', currencies[0].id),
              userIdTo: parseInt(get(values, 'userIdTo', 0), 10)
            }
          });

      return mutate
        .then(({ data }) => {
          const confirmId: string = get(
            data,
            `${withdrawMode ? 'withdraw' : 'transfer'}.confirm.id`
          );

          confirmId
            ? openModal(CONFIRM_MODAL_ID, {
                confirmId,
                title: `confirm.modal.title.${
                  withdrawMode ? 'withdraw' : 'transfer'
                }`,
                withdrawMode
              })
            : closeModal(TRANSFER_MODAL_ID);
        })
        .catch(errorHandler);
    }
  })
)(TransferForm);
