import { get, merge } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { matchPath, Redirect, Route } from 'react-router-dom';
import { compose, withProps } from 'recompose';

// Components
import Block from '@components/Block';
import Link, { SettingsLinkPropTypes } from './components/Link';

// Containers
import ProfileForm from './containers/ProfileForm';
import SecurityForm from './containers/SecurityForm';
import VerifyForm from './containers/VerifyForm';

// Ducks
import { MENU, VERIFY_TYPE_PASSPORT } from './ducks';

// GraphQL
import currentUserQuery from '@graphql/currentUser.graphql';

// Style
import style from './Settings.scss';

// Utils
import { resolveUrl } from '@utils/url';

const Settings = ({
  match,
  title
}: SettingsPropTypes): React.Element<'div'> => (
  <Query query={currentUserQuery}>
    {({ data, error, loading }) => {
      const initialValues: Object =
        data &&
        merge({}, get(data, 'currentUser', {}), {
          documentType: VERIFY_TYPE_PASSPORT
        });

      const kycEnabled = get(data, 'getConfig.kycEnabled', false);

      if (initialValues && initialValues.documentTill) {
        initialValues.documentTill = moment(
          initialValues.documentTill,
          'YYYY-MM-DD'
        ).format('DD.MM.YYYY');
      }

      return (
        <div className={style.Root}>
          {error}
          {!data && loading && 'Loading...'}

          {data && !loading && (
            <React.Fragment>
              <div className={style.Nav}>
                <h3 className={style.Title}>
                  <FormattedMessage
                    defaultMessage="Settings"
                    id="menu.settings"
                  />
                </h3>
                <div className={style.Menu}>
                  {MENU.filter(
                    ({ to }) => kycEnabled || (!kycEnabled && to !== 'verify')
                  ).map(
                    ({ to, title }: SettingsLinkPropTypes, index: number) => (
                      <Link
                        key={index}
                        title={title}
                        to={resolveUrl(['settings', to])}
                      />
                    )
                  )}
                </div>
              </div>

              <Block
                className={style.Container}
                title={
                  <FormattedMessage
                    defaultMessage={title}
                    id={title || 'empty'}
                  />
                }
              >
                <div>
                  {!title && (
                    <Redirect to={resolveUrl([match.url, 'profile'])} />
                  )}

                  <Route
                    path={resolveUrl([match.url, 'profile'])}
                    render={props => (
                      <ProfileForm {...props} initialValues={initialValues} />
                    )}
                  />

                  <Route
                    path={resolveUrl([match.url, 'security'])}
                    render={props => (
                      <SecurityForm {...props} initialValues={initialValues} />
                    )}
                  />

                  {kycEnabled && (
                    <Route
                      path={resolveUrl([match.url, 'verify'])}
                      render={props => (
                        <VerifyForm {...props} initialValues={initialValues} />
                      )}
                    />
                  )}
                </div>
              </Block>
            </React.Fragment>
          )}
        </div>
      );
    }}
  </Query>
);

export default compose(
  withProps(({ location }: SettingsPropTypes) => {
    const match: Object = matchPath(get(location, 'pathname'), {
      path: '/settings/:sectionId'
    });
    const sectionId: string = get(match, 'params.sectionId');
    const title: string = get(
      MENU.find(({ to }) => to === sectionId),
      'title'
    );

    return {
      sectionId,
      title
    };
  })
)(Settings);

export type SettingsPropTypes = {
  location: {
    pathname: string
  },
  match: {
    params: {
      sectionId: ?string
    }
  },
  title: string
};
