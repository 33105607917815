import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// Utils
import { resolveUrl } from '@utils/url';

// Views
import Verifications from '@views/Verifications';
import Users from '@views/Users';
import Withdraws from '@views/Withdraws';

const Admin = ({
  match,
  userId
}: AdminPropTypes): React.Element<typeof Switch> => (
  <Switch>
    <Route
      component={Verifications}
      path={resolveUrl([match.url, 'verifications'])}
    />

    <Route component={Users} path={resolveUrl([match.url, 'users'])} />

    <Route
      path={resolveUrl([match.url, 'withdraws'])}
      render={props => <Withdraws {...props} admin userId={userId} />}
    />
  </Switch>
);

export default Admin;
export type AdminPropTypes = {
  match: {
    url: string
  },
  userId: number
};
