import ace from 'brace';
import { JsonEditor as Editor } from 'jsoneditor-react';
import * as React from 'react';

import 'brace/mode/json';
import 'brace/theme/github';

// Components
import { Field } from '@components/Form';
import Ajv from 'ajv';

// Style
import 'jsoneditor-react/es/editor.min.css';

const FormJson = ({ jsonSchema, onChange, value, ...props }) => {
  // Memo
  const { json, schema } = React.useMemo(() => {
    let json;
    let schema;

    try {
      json = JSON.parse(value);
      schema = JSON.parse(jsonSchema);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return { json, schema };
  }, [jsonSchema, value]);

  // Handlers
  const handleChange = React.useCallback(
    value => onChange(JSON.stringify(value)),
    [onChange]
  );

  const ajv = new Ajv({ allErrors: true, verbose: true });
  return (
    <Editor
      ace={ace}
      ajv={ajv}
      allowedModes={['code', 'form', 'tree']}
      mode="form"
      onChange={handleChange}
      schema={schema}
      theme="ace/theme/github"
      value={json}
    />
  );
};

export default props => (
  <Field {...props}>
    <FormJson />
  </Field>
);
