import classNames from 'classnames';
import * as React from 'react';

// Style
import style from './Form.scss';

const Form = ({
  children,
  className,
  classNames: {
    root: rootClassName,
    container: containerClassName,
    error: errorClassName
  } = {},
  error,
  onSubmit
}: FormPropType): React.Element<'form'> => (
  <form
    className={classNames(className, rootClassName, style.Root)}
    onSubmit={onSubmit}
  >
    {!!error && (
      <div className={classNames(errorClassName, style.Error)}>
        <strong>Error:&nbsp;</strong>
        {error}
      </div>
    )}

    <div className={classNames(containerClassName, style.Container)}>
      {children}
    </div>
  </form>
);

export default Form;
export type FormPropType = {
  children: React.Node,
  className: ?string,
  classNames: ?{
    root: string,
    container: string,
    error: string
  },
  error: ?string,
  onSubmit: Function
};
