import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Styles
import styles from './Installments.scss';

export const Subscriptions = ({ nextPayment, nextPaymentTill, phase, plan }) => (
  <div
    className={classNames(styles.Root, {
      [styles.RootIsExpired]: moment().isAfter(moment(nextPaymentTill)),
      [styles.RootIsExpires]:
        moment(nextPaymentTill).diff(moment(), 'weeks') === 1
    })}
  >
    <h3 className={styles.Title}>
      <FormattedMessage
        defaultMessage="Subscriptions"
        id="products.subscriptions.title"
      />
    </h3>

    <div className={styles.List}>
      <div className={styles.Item}>
        <i className={classNames(styles.Icon, 'fas fa-alarm-clock')} />
        <span>
          <FormattedHTMLMessage
            id="products.installments.date"
            values={{
              amount: nextPayment,
              date: moment(nextPaymentTill).format('DD.MM.YYYY')
            }}
          />
        </span>
      </div>
    </div>
  </div>
);
