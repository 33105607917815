import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Constants
import { IN } from '@constants/purposes';
import { FAILED, PENDING, PROCESSED, SUCCESS } from '@constants/statuses';

// Style
import style from './Status.scss';

const TransactionsStatus = ({
  confirmations = 0,
  confirmationsNeeded = 0,
  type,
  value
}: TransactionsStatusPropTypes): React.Element<'div'> => (
  <div className={style.Root}>
    <div
      className={classNames(style.Label, {
        [style.LabelColorFailed]: value === FAILED,
        [style.LabelColorPending]: value === PENDING,
        [style.LabelColorProcessed]: value === PROCESSED || value === SUCCESS
      })}
    >
      <FormattedMessage
        defaultMessage={value}
        id={`transactions.status.${value.toLowerCase()}`}
      />

      {type === IN &&
        value === PENDING &&
        `: ${confirmations}/${confirmationsNeeded}`}
    </div>
  </div>
);

export default TransactionsStatus;
export type TransactionsStatusPropTypes = {
  confirmations?: number,
  confirmationsNeeded?: number,
  type?: typeof FAILED | typeof PENDING | typeof PROCESSED,
  value: string
};
