import * as React from 'react';

import style from './Section.scss';

const UserSection = ({
  children,
  title
}: UserSectionPropTypes): React.Element<'div'> => (
  <div className={style.Root}>
    {title && <div className={style.Title}>{title}</div>}

    <div className={style.Container}>{children}</div>
  </div>
);

export default UserSection;
export type UserSectionPropTypes = {
  children: React.Node,
  title: ?string
};
