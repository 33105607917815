import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

// Components
import Button from '@components/Button';
import { Installments } from './Installments';
import {Subscriptions} from "./Subscriptions";

// Containers
import { BUY_MODAL_ID } from '@containers/Buy';

// Services
import { openModal } from '@services/modals';

// Styles
import styles from './Item.scss';

const ProductsItem = ({
  id,
  active,
  description,
  expiresIn,
  icon,
  hidden,
  maxNum,
  name,
  previousPrice,
  price,
  product = false,
  upgrades
}) => {
  const dispatch = useDispatch();

  const handleBuyClick = () =>
    dispatch(
      openModal(BUY_MODAL_ID, {
        data: {
          initialValues: {
            amount: 1,
            productTypeId: id
          },
          product: {
            id,
            description,
            maxNum,
            name
          }
        }
      })
    );

  const handlePaymentClick = () =>
    dispatch(
      openModal(BUY_MODAL_ID, {
        data: {
          initialValues: {
            amount: product.cnt,
            productId: product.id,
            productTypeId: product.productTypeId
          },
          product: {
            id,
            description,
            name,
            productTypeId: product.productTypeId
          }
        }
      })
    );

  const handleUpgradeClick = () =>
    dispatch(
      openModal(BUY_MODAL_ID, {
        data: {
          initialValues: {
            amount: product.cnt,
            productId: product.id,
            productTypeId: id
          },
          product: {
            id,
            description,
            name,
            productTypeId: id
          }
        }
      })
    );

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.HeaderCover}>
          {icon && icon > 0 ? (
            <img
              alt={name}
              className={styles.HeaderImage}
              src={`${window.API_URL}/files/link/${icon}`}
            />
          ) : (
            <i className={classNames(styles.HeaderIcon, 'fad fa-box')} />
          )}
        </div>

        <div>
          <div className={styles.Title}>{name}</div>
          <div className={styles.Description}>{description}</div>
        </div>
      </div>

      <div className={styles.About}>
        {!product && (
          <>
            {!!previousPrice && (
              <div
                className={classNames(styles.Item, styles.ItemVariantPrevious)}
                title="Previous price"
              >
                <i className={classNames(styles.Icon, 'fad fa-dollar-sign')} />
                <div className={styles.Value}>{previousPrice}</div>
              </div>
            )}

            <div
              className={classNames(styles.Item, styles.ItemVariantPrice)}
              title="Price"
            >
              <i className={classNames(styles.Icon, 'fad fa-dollar-sign')} />
              <div className={styles.Value}>{price}</div>
            </div>
          </>
        )}

        <div
          className={classNames(styles.Item, styles.ItemVariantCount)}
          title={product ? 'Count' : 'Maximum count'}
        >
          <i className={classNames(styles.Icon, 'fad fa-tachometer-fastest')} />
          <div className={styles.Value}>{product.cnt || maxNum}</div>
        </div>

        {((product && product.till) || expiresIn) && (
          <div className={classNames(styles.Item, styles.ItemVariantTimer)}>
            <i className={classNames(styles.Icon, 'fad fa-clock')} />

            {!product && expiresIn && <div>{expiresIn / 86400} days</div>}
            {product?.till && <div>{product?.till}</div>}
          </div>
        )}
      </div>

      {product.installment && (product.installment.type === 'abon' ? <Subscriptions {...product.installment} /> : <Installments {...product.installment} />) }

      {(!product ||
        (product && upgrades) ||
        (product && product.installment)) && (
        <div className={styles.Actions}>
          {product ? (
            product.installment ? (
              <Button color="success" fullWidth onClick={handlePaymentClick}>
                <FormattedMessage
                  defaultMessage="Payment"
                  id="products.item.payment"
                />
              </Button>
            ) : (
              <Button fullWidth onClick={handleUpgradeClick}>
                <FormattedMessage
                  defaultMessage="Upgrade"
                  id="products.item.upgrade"
                />
              </Button>
            )
          ) : (
            <Button disabled={!active} fullWidth onClick={handleBuyClick}>
              <FormattedMessage
                defaultMessage="Buy"
                id="products.item.button"
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductsItem;
