import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Components
import Block from '@components/Block';
import Table from '@components/Table';

// GraphQL
import getLogsQuery from './graphql/getLogs.graphql';

// Style
import style from './Logs.scss';

const COLUMNS = [
  {
    accessor: 'createdAt',
    Cell: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm:ss'),
    Header: () => (
      <FormattedMessage defaultMessage="Date" id="common.field.date" />
    ),
    resizable: false,
    width: 160
  },
  {
    accessor: 'os',
    Cell: ({ value }) => (
      <React.Fragment>
        <i
          className={classNames(
            `fab fa-${value === 'Mac OS' ? 'apple' : value.toLowerCase()}`,
            style.BrowserIcon
          )}
        />
        {value}
      </React.Fragment>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="OS" id="user.logs.table.os" />
    ),
    resizable: false,
    width: 100
  },
  {
    accessor: 'browser',
    Cell: ({ value }) => (
      <React.Fragment>
        <i
          className={classNames(
            `fab fa-${value.toLowerCase()}`,
            style.BrowserIcon
          )}
        />
        {value}
      </React.Fragment>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="Browser" id="user.logs.table.browser" />
    ),
    resizable: false,
    width: 100
  },
  {
    accessor: 'ip',
    Header: 'IP',
    resizable: false,
    width: 220
  },
  {
    accessor: 'type',
    Header: () => (
      <FormattedMessage defaultMessage="Type" id="common.field.type" />
    ),
    resizable: false,
    width: 120
  },
  {
    accessor: 'text',
    Header: () => (
      <FormattedMessage defaultMessage="Changes" id="user.logs.table.text" />
    ),
    width: 300
  }
];

const Logs = ({
  id,
  pageSize,
  showPagination = false
}: UserLogsPropTypes): React.Element<typeof Query> => (
  <Query query={getLogsQuery} variables={{ id }}>
    {({ data, loading }) => {
      const tableData: Array = get(data, 'getLogs', []).sort(
        ({ createdAt: a }, { createdAt: b }) => (a < b ? 1 : a > b ? -1 : 0)
      );

      return (
        <Block
          title={
            <FormattedMessage
              defaultMessage="Log list ({count})"
              id="user.logs.title"
              values={{ count: tableData.length }}
            />
          }
        >
          <Table
            columns={COLUMNS}
            data={tableData}
            defaultPageSize={pageSize}
            loading={loading}
            showPagination={showPagination}
            sorted={[{ id: 'createdAt', desc: true }]}
          />
        </Block>
      );
    }}
  </Query>
);

export default Logs;
export type UserLogsPropTypes = {
  id: string,
  pageSize?: number,
  showPagination?: boolean
};
