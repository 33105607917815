// Forms
export const SETTINGS_PROFILE_FORM_ID = 'SETTINGS_PROFILE_FORM_ID';
export const SETTINGS_SECURITY_FORM_ID = 'SETTINGS_SECURITY_FORM_ID';
export const SETTINGS_VERIFY_FORM_ID = 'SETTINGS_VERIFY_FORM_ID';

// Verify type
export const VERIFY_TYPE_DRIVER_LICENSE = 'DRIVER_LICENSE';
export const VERIFY_TYPE_FOREIN_PASSPORT = 'FOREIN_PASSPORT';
export const VERIFY_TYPE_OTHER = 'OTHER';
export const VERIFY_TYPE_PASSPORT = 'PASSPORT';
