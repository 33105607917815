import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

// Constants
import { FAILED, SUCCESS } from '@constants/statuses';

// Style
import style from './Status.scss';

const VerifyStatus = ({ status, statusMessage, updatedAt }) => (
  <div
    className={classNames(style.Root, {
      [style.RootColorDanger]: status === FAILED,
      [style.RootColorSuccess]: status === SUCCESS
    })}
  >
    <i className={classNames(style.Icon, 'fas fa-badge-check')} />

    <div className={style.Info}>
      <div className={style.Title}>{status}</div>
      <div className={style.Message}>{statusMessage}</div>

      <div className={style.Date}>
        {moment(updatedAt).format('DD MMM HH:mm')}
      </div>
    </div>
  </div>
);

export default VerifyStatus;
