import { isEmpty, keys, merge } from 'lodash';
import url from 'url';

export const isExternal = (str: string): boolean => {
  const { host } = url.parse(str);
  return !!host && host !== window.location.host;
};

export const resolveUrl: Function = (
  input: string | [string] | resolveUrlPropTypes
): string => {
  const data: resolveUrlPropTypes = {
    base: '/',
    hash: '',
    path: [],
    query: {}
  };

  const { base, hash, path, query }: resolveUrlPropTypes = merge(
    data,
    Array.isArray(input) || typeof input === 'string'
      ? // eslint-disable-next-line
        { path: [].concat(input) }
      : input
  );

  let url: string = [base].concat(path).join('/');

  if (!isEmpty(query)) {
    const queries: [string] = keys(query).map(
      (key: number | string): string =>
        `${key}=${[].concat(query[key]).join(',')}`
    );

    if (queries.length > 1) {
      url = `${url}?${queries.join('&')}`;
    }
  }

  if (hash) {
    url = `${url}#${hash}`;
  }

  return url.replace(url.match(/^https?:/) ? /([^:])\/\/+/g : /()\/+/g, '$1/');
};

export type resolveUrlPropTypes = {
  base: string,
  hash: string,
  path: string | [string],
  query: {
    [key: number | string]: number | string | [number] | [string]
  }
};
