import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Avatar from '@components/Avatar';

// Constants
import { FAILED, PENDING, PROCESSED } from '@constants/statuses';

// Style
import style from '../Withdraw.scss';

export const COLUMNS = [
  {
    accessor: 'id',
    Header: 'id',
    style: {
      textAlign: 'center'
    },
    width: 60
  },
  {
    accessor: 'user',
    Cell: ({ value }) => {
      const user = value;

      return (
        <div className={style.User}>
          <Avatar className={style.UserAvatar} value={get(user, 'avatar')} />

          <div className={style.UserInfo}>
            <div className={style.UserName}>
              {`${get(user, 'firstname')} ${get(user, 'surname')}`}
            </div>

            <div className={style.UserEmail}>{get(user, 'email')}</div>
          </div>
        </div>
      );
    },
    Header: () => (
      <FormattedMessage
        defaultMessage="User"
        id="withdraws.table.header.user"
      />
    ),
    style: {
      justifyContent: 'flex-start'
    },
    width: 240
  },
  {
    accessor: 'address',
    Header: () => (
      <FormattedMessage
        defaultMessage="Account address"
        id="withdraws.table.header.address"
      />
    ),
    resizable: false,
    sortable: false,
    width: 320
  },
  {
    accessor: 'tx',
    Cell: ({ value }) =>
      `${get(value, 'from.valueFrom', 0).toFixed(6)} ${get(
        value,
        'from.currencyFrom'
      )}`,
    Header: () => (
      <FormattedMessage
        defaultMessage="Amount"
        id="withdraws.table.header.amount"
      />
    ),
    width: 140
  },
  {
    accessor: 'tx',
    Cell: ({ value }) =>
      `${get(value, 'from.comissionFrom')} ${get(value, 'from.currencyFrom')}`,
    Header: () => (
      <FormattedMessage
        defaultMessage="Commission"
        id="withdraws.table.header.commission"
      />
    ),
    width: 140
  },
  {
    accessor: 'tx',
    Cell: ({ value }) =>
      `${(
        get(value, 'from.valueFrom', 0) - get(value, 'from.comissionFrom')
      ).toFixed(6)} ${get(value, 'from.currencyFrom')}`,
    Header: () => (
      <FormattedMessage
        defaultMessage="Total"
        id="withdraws.table.header.total"
      />
    ),
    width: 140
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <div className={style.Status}>
        <div
          className={classNames(style.Label, {
            [style.LabelColorFailed]: value === FAILED,
            [style.LabelColorPending]: value === PENDING,
            [style.LabelColorProcessed]: value === PROCESSED
          })}
        >
          <FormattedMessage
            defaultMessage={value}
            id={`transactions.status.${value.toLowerCase()}`}
          />
        </div>
      </div>
    ),
    Header: () => (
      <FormattedMessage defaultMessage="Amount" id="common.field.status" />
    ),
    resizable: false,
    width: 120
  }
];
