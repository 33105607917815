export const BTC = 'BTC';
export const ETH = 'ETH';
export const ODDB = 'ODDB';
export const USD = 'USD';

export default {
  BTC,
  ETH,
  ODDB,
  USD
};
