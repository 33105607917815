import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

// Components
import Button from '@components/Button';
import Form from '@components/Form';

// Styles
import styles from './Filter.scss';

const Filter = ({ children, handleSubmit, title = 'Filter' }) => {
  const [isOpened, setOpenState] = React.useState(false);

  const handleTriggerClick = React.useCallback(() => setOpenState(!isOpened), [
    isOpened,
    setOpenState
  ]);

  return (
    <div
      className={classNames(styles.Root, { [styles.RootIsOpened]: isOpened })}
    >
      <button
        className={styles.Trigger}
        onClick={handleTriggerClick}
        type="button"
      >
        <i className="fal fa-filter" />
      </button>

      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <FormattedMessage defaultMessage={title} id={title} />
        </div>

        <Form classNames={{ root: styles.Form }} onSubmit={handleSubmit}>
          <div className={styles.Fieldset}>{children}</div>

          <div className={styles.Actions} onSubmit={handleSubmit}>
            <Button
              fullWidth
              onClick={handleTriggerClick}
              size="small"
              type="submit"
            >
              <FormattedMessage
                defaultMessage="Filter"
                id="common.action.filter"
              />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'filter' })(Filter);
