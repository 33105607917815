import {
  SIGN_CONFIRM_ID,
  SIGN_IN_ID,
  SIGN_RECOVERY_ID,
  SIGN_UP_ID
} from './constants';

export const VIEW = {
  [SIGN_CONFIRM_ID]: {
    action: 'Verify',
    description:
      'Please enter the verification code we sent to your email address',
    secondary: {
      link: {
        title: 'Login',
        to: '/sign/in'
      },
      title: 'Back to'
    },
    title: 'Verification'
  },
  [SIGN_IN_ID]: {
    action: 'Sign In',
    description: 'Please enter your email and password to continue',
    secondary: {
      link: {
        title: 'Create an Account',
        to: '/sign/up'
      },
      title: "Don't have an account?"
    },
    title: 'Login to Account'
  },
  [SIGN_RECOVERY_ID]: {
    action: 'Recover',
    description:
      'Please enter your email address to recieve a verification code',
    secondary: {
      link: {
        title: 'Login',
        to: '/sign/in'
      },
      title: 'Oops, I think I remembered!'
    },
    title: 'Forgot Password'
  },
  [SIGN_UP_ID]: {
    action: 'Sign Up',
    description: 'Create an account to continue',
    secondary: {
      link: {
        title: 'Login',
        to: '/sign/in'
      },
      title: 'Already have an account?'
    },
    title: 'Create an Account'
  }
};
