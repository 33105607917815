import * as React from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

// Components
import Button from '@components/Button';
import Form, { errorHandler, Input } from '@components/Form';

// Constants
import { FAILED, SUCCESS } from '@constants/statuses';

// Ducks
import { VERIFY_CONFIRM_FORM_ID } from '../ducks';

// GraphQL
import confirmVerificationMutation from '@graphql/confirmVerification.graphql';

// Services
import { closeModals } from '@services/modals';

// Style
import style from './common.scss';

const VerifyConfirmForm = ({
  error,
  handleSubmit,
  onSubmit,
  submitting,
  ...props
}) => (
  <Form className={style.RootVariantVerify} error={error}>
    <Input label="common.field.comment" name="statusMessage" />

    <div className={style.Actions}>
      <Button
        color="danger"
        loaded={submitting}
        onClick={handleSubmit(values =>
          onSubmit({ ...values, status: FAILED }, props))}
      >
        Отклонить
      </Button>

      <Button
        color="success"
        loaded={submitting}
        onClick={handleSubmit(values =>
          onSubmit({ ...values, status: SUCCESS }, props))}
      >
        Подтвердить
      </Button>
    </div>
  </Form>
);

export default compose(
  connect(
    null,
    { closeModals }
  ),
  graphql(confirmVerificationMutation, { name: 'confirmVerification' }),
  reduxForm({
    form: VERIFY_CONFIRM_FORM_ID,
    onSubmit: (values, { closeModals, confirmVerification }) =>
      confirmVerification({ variables: values })
        .then(closeModals)
        .catch(errorHandler)
  })
)(VerifyConfirmForm);
