import { get, keys, isEmpty } from 'lodash';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withState } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import { asyncValidator, errorHandler } from '@components/Form';

// Containers
import { CONFIRM_MODAL_ID } from '@containers/Confirm';
import BuyForm from './Form';

// Data
import { BUY_FORM_ID, BUY_MODAL_ID } from '../../ducks';

// GraphQL
import buyMutation from '@graphql/buy.graphql';
import getAccountsQuery from '@graphql/getAccounts.graphql';
import getBuyComissionQuery from '@graphql/getBuyComission.graphql';
import getPaymentHelpCode from '@graphql/getPaymentHelpCode.graphql';
import getProductsQuery from '@graphql/getProducts.graphql';
import getTransactionsQuery from '@graphql/getTransactions.graphql';

// Services
import { closeModal, openModal } from '@services/modals';

export default compose(
  connect(null, { closeModal, openModal }),
  graphql(buyMutation, { name: 'buy' }),
  graphql(getBuyComissionQuery, { name: 'getBuyComission' }),
  graphql(getPaymentHelpCode, { name: 'getPaymentHelpCode' }),
  withProps(({ initialValues, maxNum, product = {}, productTypes = [] }) => {
    const currentProductType = productTypes.find(
      ({ id }) => id === product.productTypeId
    );

    if (currentProductType) {
      try {
        const json = JSON.parse(currentProductType.upgrades);

        if (json && !isEmpty(json)) {
          const productTypeOptions = keys(json).map(key => {
            const productType = productTypes.find(
              ({ textId }) => textId === key
            );
            return {
              label: `${productType.name} ($${json[key]})`,
              value: productType.id
            };
          });

          return {
            initialValues: {
              ...initialValues,
              productTypeId: productTypeOptions[0].value
            },
            productTypeOptions
          };
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    }

    return {};
  }),
  withState('commissionAmount', 'setCommissionAmount', 0),
  withState('commissionError', 'setCommissionError', null),
  withState('currentCurrencyIdFrom', 'setCurrentCurrencyIdFrom', 0),
  withState('currentAmount', 'setCurrentAmount', 0),
  withState('currentPriceOption', 'setCurrentPriceOption', ''),
  withState('currentProductTypeId', 'setCurrentProductTypeId', null),
  withState('isLoaded', 'setLoadState', false),
  withState('maxNum', 'setMaxNum', undefined),
  withState('rateId', 'setRateId', null),
  withState('total', 'setTotal', 0),
  reduxForm({
    asyncValidate: asyncValidator(
      yup.object().shape({
        amount: yup.string().required('error.form.required'),
        comment: yup
          .string()
          .matches(/^(?![!\s]).*/, 'transfer.form.comment.error')
      })
    ),
    form: BUY_FORM_ID,
    initialValues: {
      amount: 1
    },
    onChange: (
      values,
      dispatch,
      {
        change,
        currencies,
        currentAmount,
        currentCurrencyIdFrom,
        currentPriceOption,
        currentProductTypeId,
        getBuyComission,
        productTypes,
        setCommissionAmount,
        setCommissionError,
        setCurrentAmount,
        setCurrentCurrencyIdFrom,
        setCurrentPriceOption,
        setCurrentProductTypeId,
        setLoadState,
        setMaxNum,
        setRateId,
        setTotal,
        ...props
      }
    ) => {
      if (
        values.amount !== currentAmount ||
        values.currencyIdFrom !== currentCurrencyIdFrom ||
        values.priceOption !== currentPriceOption ||
        values.productTypeId !== currentProductTypeId
      ) {
        let { amount = 0 } = values;

        if (values.productTypeId !== currentProductTypeId) {
          const product = productTypes.find(
            ({ id }) => id === values.productTypeId
          );

          if (product && product.maxNum) {
            amount = Math.max(amount, product.maxNum);

            dispatch(change('amount', amount));
            setMaxNum(product.maxNum);
          }
        }

        setCurrentAmount(values.amount);
        setCurrentCurrencyIdFrom(values.currencyIdFrom);
        setCurrentPriceOption(values.priceOption);
        setCurrentProductTypeId(values.productTypeId);
        setLoadState(true);

        getBuyComission
          .refetch({
            amount: parseInt(amount, 10),
            currencyIdFrom: `${values.priceOption}:${values.currencyIdFrom}`,
            productId: parseInt(values.productId, 10) || undefined,
            productTypeId: parseInt(values.productTypeId, 10),
            userIdTo: parseInt(values.userIdTo, 10)
          })
          .then(({ data }) => {
            const comissionCost = get(
              data,
              'getBuyComission.comission',
              0
            ).toFixed(8);
            const comissionCurrency = currencies.find(
              ({ id }) =>
                id === get(data, 'getBuyComission.comissionCurrencyId')
            );
            const commissionAmount: string = `${comissionCost} ${
              comissionCurrency.id
            } ($${(comissionCost * comissionCurrency.rate).toFixed(2)})`;

            let total = '';

            try {
              const cost = JSON.parse(get(data, 'getBuyComission.cost', 0));

              Object.keys(cost).forEach(key => {
                const currency = currencies.find(({ id }) => id === key);
                const value = cost[key];

                total += ` + ${value.toFixed(4)} ${key} ($${(
                  value * currency.rate
                ).toFixed(2)})`;
              });
            } catch (e) {
              // eslint-disable-next-line
              console.log('Cost parse error!', e);
            }

            setCommissionAmount(commissionAmount);
            setCommissionError(null);
            setLoadState(false);
            setRateId(get(data, 'getBuyComission.rateId'));
            setTotal(total.substr(3));
          })
          .catch(({ graphQLErrors }) => {
            let error: string = '';

            graphQLErrors &&
              graphQLErrors.forEach(({ message }) => {
                error = message;
              });

            setCommissionError(error);
            setLoadState(false);
          });
      }
    },
    onSubmit: (
      values,
      dispatch,
      { buy, closeModal, currencies, getPaymentHelpCode, openModal, rateId }
    ) => {
      const { isHelp } = values;

      const input = {
        amount: parseFloat(values.amount),
        productId: values.productId
          ? parseInt(values.productId, 10)
          : undefined,
        productTypeId: parseInt(values.productTypeId, 10),
        userIdTo: parseInt(get(values, 'userIdTo', 0), 10)
      };

      return isHelp
        ? getPaymentHelpCode
            .refetch({
              input: {
                ...input,
                paymentOption: values.priceOption
              }
            })
            .then(({ data }) => {
              const code = get(data, 'getPaymentHelpCode');
              openModal('123', { code, title: 'helpCode.modal.title' });
              return true;
            })
            .catch(errorHandler)
        : buy({
            refetchQueries: [
              {
                query: getAccountsQuery
              },
              {
                query: getTransactionsQuery,
                variables: { id: 0 }
              },
              {
                query: getProductsQuery
              }
            ],
            variables: {
              ...input,
              comment: get(values, 'comment'),
              currencyIdFrom: `${values.priceOption}:${get(
                values,
                'currencyIdFrom',
                currencies[0].id
              )}`,
              rateId
            }
          })
            .then(({ data }) => {
              const confirmId = get(data, 'buy.confirm.id');

              confirmId
                ? openModal(CONFIRM_MODAL_ID, {
                    buyMode: true,
                    confirmId,
                    title: `confirm.modal.title.buy`
                  })
                : closeModal(BUY_MODAL_ID);
            })
            .catch(errorHandler);
    }
  })
)(BuyForm);
