import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { compose, withProps } from 'recompose';

// Containers
import Accounts from './containers/Accounts';
import Profile from './containers/Profile';

// GraphQL
import getUserQuery from './graphql/getUser.graphql';

// Style
import style from './User.scss';

// Views
import Logs from '@views/Logs';
import Transactions from '@views/Transactions';

const User = ({ id }) => (
  <Query query={getUserQuery} variables={{ id }}>
    {({ data }) => {
      const user: Object = get(data, 'getUser');

      return (
        <div className={style.Root}>
          <Profile {...user} id={id} />
          <Accounts id={id} />
          <Transactions id={id} pageSize={5} showPagination />
          <Logs id={id} pageSize={10} showPagination />
        </div>
      );
    }}
  </Query>
);

export default compose(
  withProps(({ match }) => ({
    id: parseInt(get(match, 'params.id'), 10)
  }))
)(User);
