import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';

// Components
import Modal from '@components/Modal';

// Constants
import { BASE } from '@constants/purposes';

// Containers
import Form from './containers/Form';

// Ducks
import { BUY_MODAL_ID } from './ducks/constants';

// GraphQL
import AGGREGATE_BUY from '@graphql/aggregateBuy.graphql';

const Buy = () => (
  <Query query={AGGREGATE_BUY}>
    {({ data }) => {
      const accounts: Array = get(data, 'getAccounts', []);
      const currencies: Array = get(data, 'getCurrencies', []);
      const licenses = get(data, 'getProductTypes', []);
      const userIdTo = get(data, 'currentUser.id', 0);

      const filteredCurrencies: Array = currencies.filter(
        ({ purposes }) => purposes.indexOf(BASE) === -1
      );

      return (
        <Modal id={BUY_MODAL_ID}>
          {({ data = {} }) => (
            <Form
              accounts={accounts}
              currencies={filteredCurrencies}
              initialValues={{
                currencyIdFrom: get(filteredCurrencies, '0.id'),
                priceOption: '',
                productTypeId: get(licenses, '0.id'),
                userIdTo: get(data, 'partner.userIdTo', userIdTo),
                ...get(data, 'initialValues', {})
              }}
              isHelpCode={get(data, 'isHelpCode', false)}
              partner={get(data, 'partner')}
              product={get(data, 'product')}
              productTypes={licenses}
            />
          )}
        </Modal>
      );
    }}
  </Query>
);

export default Buy;
