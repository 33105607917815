import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, withHandlers } from 'recompose';

// Components
import { Field } from '@components/Form';

// Style
import style from './File.scss';

const SettingsFile = ({
  error,
  handleChange,
  icon,
  id,
  info,
  label,
  value
}: SettingsFilePropTypes): React.Element<'label'> => (
  <label
    className={classNames(style.Root, {
      [style.RootIsErred]: !!error,
      [style.RootIsSucceed]: !!value
    })}
    htmlFor={id}
  >
    <i className={classNames(icon, style.Icon)} />
    <div className={style.Title}>
      <FormattedMessage defaultMessage={label} id={label} />
    </div>
    <div className={style.Description}>
      <FormattedMessage defaultMessage={info} id={info} />
    </div>

    <input
      accept=".jpg, .jpeg, .png"
      className={style.Input}
      id={id}
      onChange={handleChange}
      type="file"
    />
  </label>
);

const ComposedSettingsFile = compose(
  withHandlers({
    handleChange: ({ onChange }: SettingsFilePropTypes): Function => (
      event: React.SyntheticEvent
    ): void => {
      const files: FileList = get(event, 'target.files');

      if (files && files.length > 0) {
        onChange && onChange(files[0]);
      }
    }
  })
)(SettingsFile);

export default props => (
  <Field {...props} type="file" withoutLabel>
    <ComposedSettingsFile />
  </Field>
);

export type SettingsFilePropTypes = {
  error: ?string,
  icon: string,
  id: string,
  info: string,
  handleChange: ([File]) => void,
  label: string,
  onChange: Function,
  value: any
};
