import * as React from 'react';
import { Query } from 'react-apollo';

// Constants
import { BASE } from '@constants/purposes';

// GraphQL
import getAccountsQuery from '../graphql/getAccounts.graphql';
import { get } from 'lodash';

// Style
import style from './Accounts.scss';

// Views
import { Account } from '@views/Dashboard';

const UserAccounts = ({
  id
}: UserAccountsPropTypes): React.Element<typeof Query> => (
  <Query query={getAccountsQuery} variables={{ id }}>
    {({ data }): React.Element<'div'> => {
      const accounts: [] = get(data, 'getAccounts', []);
      const currencies: [] = get(data, 'getCurrencies', []);

      const formattedAccounts: [] = currencies
        .map(({ id, ...currency }) => ({
          ...accounts.find(({ currencyId }) => currencyId === id),
          ...currency,
          currencyId: id
        }))
        .filter(({ purposes }) => purposes.indexOf(BASE) === -1);

      return (
        <div className={style.Root}>
          {formattedAccounts.map(
            (account: Object, index: number): React.Element<typeof Account> => (
              <Account
                {...account}
                key={index}
                currencies={formattedAccounts}
                readOnly
              />
            )
          )}
        </div>
      );
    }}
  </Query>
);

export default UserAccounts;
export type UserAccountsPropTypes = {
  id: number
};
