import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Style
import style from './Group.scss';

const SettingsGroup = ({
  children,
  icon,
  title
}: SettingsGroupPropTypes): React.Element<'div'> => (
  <div
    className={classNames(style.Root, {
      [style.RootVariantSingle]: children.length === 1,
      [style.RootVariantDouble]: children.length === 2,
      [style.RootVariantTriple]: children.length === 3
    })}
  >
    {title && (
      <div className={style.Title}>
        {icon && <i className={classNames(icon, style.Icon)} />}
        <FormattedMessage defaultMessage={title} id={title} />
      </div>
    )}

    <div className={style.Container}>{children}</div>
  </div>
);

export default SettingsGroup;
export type SettingsGroupPropTypes = {
  children: React.Node,
  icon: ?string,
  title: ?string
};
