import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, Input } from '@components/Form';

// Ducks
import { SIGN_CONFIRM_FORM } from '@views/Sign/ducks';

// Style
import style from './Form.scss';

// Type
import type { SignBaseFormPropTypes } from './BaseForm';
import { FormattedMessage } from 'react-intl';

const SignConfirmForm = ({
  action,
  error,
  handleSubmit,
  id,
  secondary,
  submitting
}: SignBaseFormPropTypes): React.Element<typeof Form> => (
  <Form error={error} onSubmit={handleSubmit}>
    <Input
      disabled={submitting}
      label="sign.field.code"
      name="code"
      placeholder="sign.field.code.placeholder"
    />

    <div className={style.Actions}>
      <Button
        className={style.Submit}
        fullWidth
        loaded={submitting}
        type="submit"
      >
        <FormattedMessage defaultMessage={action} id={`sign.${id}.action`} />
      </Button>

      <div className={style.Secondary}>
        <FormattedMessage
          defaultMessage={get(secondary, 'title')}
          id={`sign.${id}.secondary.title`}
        />
        &nbsp;
        <Link to={get(secondary, 'link.to')}>
          <FormattedMessage
            defaultMessage={get(secondary, 'link.title')}
            id={`sign.${id}.secondary.link`}
          />
        </Link>
      </div>
    </div>
  </Form>
);

export default compose(
  reduxForm({
    form: SIGN_CONFIRM_FORM,
    asyncValidate: asyncValidator(
      yup.object().shape({
        code: yup
          .string()
          .required('error.form.required')
          .length(6, 'error.form.code')
      })
    )
  })
)(SignConfirmForm);
