export const MENU = [
  {
    title: 'settings.menu.profile',
    to: 'profile'
  },
  {
    title: 'settings.menu.security',
    to: 'security'
  },
  {
    title: 'settings.menu.verify',
    to: 'verify'
  }
];
