import classNames from 'classnames';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Style
import style from './Block.scss';

const DashboardBlock = ({
  className,
  component: Component,
  id,
  pollInterval,
  query,
  show,
  subTitle,
  title
}: DashboardBlockPropTypes): React.Element<'div'> => (
  <Query pollInterval={pollInterval} query={query}>
    {({ data, loading }) => {
      const isShow: boolean = !show || (show && !loading && show(data));

      return (
        isShow && (
          <div className={classNames(className, style.Root)}>
            <h3 className={style.Title}>
              <FormattedMessage defaultMessage={title} id={`dashboard.${id}`} />

              {subTitle && <span className={style.Subtitle}>{subTitle}</span>}
            </h3>

            <div className={style.Container}>
              {loading ? 'Loading' : <Component data={data} />}
            </div>
          </div>
        )
      );
    }}
  </Query>
);

export default DashboardBlock;
export type DashboardBlockPropTypes = {
  className: ?string,
  component: React.Node,
  id: string,
  pollInterval: ?number,
  query: string,
  title: ?string
};
