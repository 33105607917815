import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { compose, withProps } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import Button from '@components/Button';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Sponsor.scss';

const DashboardSponsor = ({
  avatar,
  email,
  firstname,
  skype,
  surname,
  telegram
}: DashboardSponsorPropTypes): React.Element<'div'> => {
  // Setup
  const dispatch = useDispatch();

  // Handlers
  const handleClick = React.useCallback(
    () => dispatch(openModal('changeSponsorModal')),
    [dispatch]
  );

  return (
    <div className={style.Root}>
      <div className={style.Header}>
        <Avatar className={style.Avatar} value={avatar} />

        <div className={style.Info}>
          <div className={style.Name}>{`${firstname || ''} ${surname ||
            ''}`}</div>

          <div className={style.Field}>
            <FormattedMessage defaultMessage="E-mail" id="common.email" />
            :&nbsp;
            <a className={style.Link} href={`mailto:${email}`}>
              {email}
            </a>
          </div>

          {telegram && (
            <div className={style.Field}>
              Telegram:&nbsp;
              <a
                className={style.Link}
                href={`tg://resolve?domain=${telegram}`}
              >
                {telegram}
              </a>
            </div>
          )}

          {skype && (
            <div className={style.Field}>
              Skype:&nbsp;
              <a className={style.Link} href={`skype:${skype}`}>
                {skype}
              </a>
            </div>
          )}
        </div>
      </div>

      {/* <div className={style.Actions}>
        <Button fullWidth onClick={handleClick} size="small">
          <FormattedMessage
            defaultMessage="Change Sponsor"
            id="dashboard.sponsor.change.trigger"
          />
        </Button>
      </div> */}
    </div>
  );
};

export default compose(
  withProps(({ data }) => get(data, 'currentUser.sponsor'))
)(DashboardSponsor);

export type DashboardSponsorPropTypes = {
  avatar: ?number,
  email: string,
  firstname: ?string,
  skype: ?string,
  surname: ?string,
  telegram: ?string
};
