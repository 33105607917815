import { get } from 'lodash';
import * as React from 'react';
import { compose, withHandlers, withState } from 'recompose';

// Components
import Avatar from '@components/Avatar';
import { Field } from '@components/Form';

// Style
import style from './Avatar.scss';

const SettingsAvatar = ({
  handleChange,
  id,
  preview,
  value
}): React.Element<'label'> => (
  <label className={style.Root} htmlFor={id}>
    <Avatar className={style.Avatar} value={preview || value} />

    <input
      accept=".jpg, .jpeg, .png"
      className={style.Input}
      id={id}
      onChange={handleChange}
      type="file"
    />
  </label>
);

const ComposedSettingsAvatar = compose(
  withState('preview', 'setPreview', null),
  withHandlers({
    handleChange: ({ onChange, setPreview }) => (event): void => {
      const file: File = get(event, 'target.files.0');

      if (file) {
        const reader: FileReader = new FileReader();

        reader.onload = () => {
          setPreview(reader.result);
          onChange && onChange(file);
        };

        reader.readAsDataURL(file);
      }
    }
  })
)(SettingsAvatar);

export default props => (
  <Field {...props} type="file" withoutLabel>
    <ComposedSettingsAvatar />
  </Field>
);
