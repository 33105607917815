import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Responsive from 'react-responsive';

// Components
import Avatar from '@components/Avatar';
import Status from './Status';

// Containers
import { TRANSFER_MODAL_ID } from '@containers/Transfer';

// Constants
import { INTERNAL, IN, OUT } from '@constants/purposes';

// Services
import { openModal } from '@services/modals';

// Style
import style from './Field.scss';

const TransactionsField = ({
  confirmations,
  confirmationsNeeded,
  currentUserId,
  from: {
    currencyFrom,
    explorer: explorerFrom,
    rateFrom = 0,
    valueFrom = 0,
    userFrom
  } = {},
  to: {
    addressTo,
    currencyTo,
    explorer: explorerTo,
    rateTo = 0,
    valueTo = 0,
    userTo
  } = {},
  status,
  type,
  updatedAt
}: TransactionFieldPropTypes): React.Element<'div'> => {
  const dispatch = useDispatch();

  const handleAvatarClick = user =>
    user &&
    currentUserId !== user.id &&
    dispatch(
      openModal(TRANSFER_MODAL_ID, {
        partner: {
          attributes: { email: user.email },
          image: user.avatar && `${window.API_URL}/files/link/${user.avatar}`,
          name: `${user.firstname} ${user.surname}`,
          userIdTo: user.id
        }
      })
    );

  return (
    <div className={style.Root}>
      <div className={style.Left}>
        {valueFrom !== undefined && type !== 'INTERNAL' && (
          <React.Fragment>
            <div
              className={style.User}
              onClick={() => handleAvatarClick(userFrom)}
            >
              {userFrom && (
                <>
                  <Avatar
                    className={style.Avatar}
                    value={get(userFrom, 'avatar')}
                  />

                  <div className={style.Name}>{`${get(
                    userFrom,
                    'firstname',
                    'Unnamed'
                  )}`}</div>
                </>
              )}
            </div>

            <div className={classNames(style.Calc, style.CalcColorNegative)}>
              <div className={style.Value}>
                {`-${valueFrom.toFixed(
                  Math.max(0, 5 - Math.round(valueFrom).toString().length)
                )}`}
                <span className={style.Currency}>{currencyFrom}</span>
              </div>

              <div className={style.Rate}>{`-${(rateFrom * valueFrom).toFixed(
                2
              )}$`}</div>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className={style.Center}>
        <div className={style.Type}>
          {[IN, OUT].indexOf(type) > -1 ? (
            <a
              className={style.Link}
              href={explorerFrom || explorerTo}
              rel="noopener noreferrer"
              target="_blank"
            >
              {type}
              <i className="fas fa-external-link" />
            </a>
          ) : (
            type
          )}
        </div>

        <i className={classNames('fal fa-long-arrow-right', style.Icon)} />

        <div className={style.Date}>
          {moment(updatedAt).format('DD MMM HH:mm')}
        </div>

        <Responsive maxWidth={768}>
          <div className={style.Status}>
            <Status
              confirmations={confirmations}
              confirmationsNeeded={confirmationsNeeded}
              type={type}
              value={status}
            />
          </div>
        </Responsive>
      </div>

      <div className={style.Right}>
        {addressTo && (
          <a className={style.Address} href="/#">
            <i className="fas fa-wallet" />
            {addressTo.substr(0, 6)}...{addressTo.substr(-6)}
          </a>
        )}

        {valueTo !== undefined && (
          <React.Fragment>
            <div className={classNames(style.Calc, style.CalcColorPositive)}>
              <div className={style.Value}>
                {`+${valueTo.toFixed(
                  Math.max(0, 5 - Math.round(valueTo).toString().length)
                )}`}
                <span className={style.Currency}>{currencyTo}</span>
              </div>

              <div className={style.Rate}>{`+${(rateTo * valueTo).toFixed(
                2
              )}$`}</div>
            </div>

            <div
              className={style.User}
              onClick={() => handleAvatarClick(userTo)}
            >
              {userTo && (
                <>
                  <Avatar
                    className={style.Avatar}
                    value={get(userTo, 'avatar')}
                  />

                  <div className={style.Name}>{`${get(
                    userTo,
                    'firstname',
                    'Unnamed'
                  )}`}</div>
                </>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default TransactionsField;
export type TransactionFieldPropTypes = {
  currencyFrom: ?string,
  currencyTo: ?string,
  rateFrom: number,
  rateTo: number,
  type: string,
  userFrom: ?number,
  userTo: ?number,
  updatedAt: Date,
  valueFrom: number,
  valueTo: number
};
