import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './Tabs.scss';

const Tabs = ({ onSelect, tabs = [], value }) => {
  const handleTabClick = React.useCallback(
    event => onSelect && onSelect(event.currentTarget.dataset.tabValue),
    [onSelect]
  );

  return (
    <div className={styles.Root} role="tablist">
      {tabs.map(item => (
        <div
          key={item.value}
          className={classNames(styles.Tab, {
            [styles.TabIsSelected]: item.value === value
          })}
          data-tab-value={item.value}
          onClick={handleTabClick}
          role="tab"
          tabIndex={0}
        >
          <FormattedMessage defaultMessage={item.label} id={item.label} />
        </div>
      ))}
    </div>
  );
};

export default Tabs;
