import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Field } from '@components/Form';

// Styles
import styles from './Checkbox.scss';

const FormCheckbox = ({
  checked,
  children,
  disabled,
  error,
  id,
  label,
  name,
  onChange,
  value,
  ...props
}) => (
  <div
    className={classNames(styles.Root, {
      [styles.RootIsChecked]: checked,
      [styles.RootIsDisabled]: disabled
    })}
  >
    <label className={styles.Label} htmlFor={id}>
      <div className={styles.Control}>
        <i className={classNames(styles.Icon, 'fas fa-check')} />
        <input
          className={styles.Input}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
          value={value}
        />
      </div>

      <div className={styles.Title}>
        {children || <FormattedMessage defaultMessage={label} id={label} />}
      </div>
    </label>
  </div>
);

export default ({ children, ...props }) => (
  <Field {...props} type="checkbox" withoutLabel>
    <FormCheckbox>{children}</FormCheckbox>
  </Field>
);
