import classNames from 'classnames';
import * as React from 'react';
import Select from 'react-select';

// Components
import { Field } from '@components/Form';

// Style
import style from './Select.scss';

const FormSelect = ({
  children,
  disabled,
  error,
  id,
  multiple,
  name,
  onChange,
  options,
  value
}: FormSelectPropTypes): React.Element<'div'> => (
  <div
    className={classNames(style.Root, {
      [style.RootIsDisabled]: !!disabled,
      [style.RootIsErred]: !!error
    })}
  >
    {multiple || options ? (
      <Select
        isMulti={multiple}
        menuPlacement="top"
        name={name}
        onChange={value =>
          onChange(multiple ? value.map(({ value }) => value) : value.value)
        }
        options={options}
        placeholder=""
        value={
          value
            ? multiple
              ? (value || []).map(value => ({
                  label: options.find(item => item.value === value).label,
                  value
                }))
              : {
                  label: options.find(item => item.value === value).label,
                  value
                }
            : null
        }
      />
    ) : (
      <select
        className={style.Select}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      >
        {children}
      </select>
    )}
  </div>
);

export default ({ children, ...props }) => (
  <Field {...props}>
    <FormSelect>{children}</FormSelect>
  </Field>
);

export type FormSelectPropTypes = {
  disabled: ?boolean,
  error: ?string,
  id: string,
  name: string,
  onChange: Function,
  value: boolean | number | string
};

export const CustomSelect = FormSelect;
