import classNames from 'classnames';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';

// Components
import Button from '@components/Button';
import Form, { errorHandler, Input } from '@components/Form';

// Ducks
import { WITHDRAW_REQUEST_FORM_ID, WITHDRAW_REQUEST_MODAL_ID } from '../ducks';

// GraphQL
import cancelWithdrawMutation from '../graphql/cancelWithdraw.graphql';
import finishWithdrawMutation from '../graphql/finishWithdraw.graphql';

// Services
import { closeModal } from '@services/modals';

// Style
import style from './Form.scss';

const TYPE = {
  APPROVE: 'APPROVE',
  CANCEL: 'CANCEL',
  DISAPPROVE: 'DISAPPROVE'
};

const WithdrawsForm = ({
  accountantMode,
  error,
  handleSubmit,
  onSubmit,
  submitting,
  ...props
}: WithdrawsFormPropTypes): React.Element<typeof Error> => (
  <Form error={error}>
    {accountantMode && <Input label="TxHash" name="txhash" />}
    <Input label="common.field.comment" name="comment" />

    <div
      className={classNames(style.Actions, {
        [style.ActionsVariantSolo]: !accountantMode
      })}
    >
      <Button
        color="danger"
        fullWidth={!accountantMode}
        loaded={submitting}
        onClick={handleSubmit(values =>
          onSubmit(
            { ...values, type: accountantMode ? TYPE.DISAPPROVE : TYPE.CANCEL },
            { ...props, accountantMode }
          ))}
      >
        <FormattedMessage
          defaultMessage={accountantMode ? 'Reject' : 'Cancel'}
          id={`withdraws.form.actions.${accountantMode ? 'reject' : 'cancel'}`}
        />
      </Button>

      {accountantMode && (
        <Button
          color="success"
          loaded={submitting}
          onClick={handleSubmit(values =>
            onSubmit(
              { ...values, type: TYPE.APPROVE },
              { ...props, accountantMode }
            ))}
        >
          <FormattedMessage
            defaultMessage="Approve"
            id="withdraws.form.actions.approve"
          />
        </Button>
      )}
    </div>
  </Form>
);

export default compose(
  connect(
    null,
    { closeModal }
  ),
  graphql(cancelWithdrawMutation, { name: 'cancelWithdraw' }),
  graphql(finishWithdrawMutation, { name: 'finishWithdraw' }),
  reduxForm({
    form: WITHDRAW_REQUEST_FORM_ID,
    onSubmit: (
      { comment, txhash, type, withdrawId },
      { accountantMode, cancelWithdraw, closeModal, finishWithdraw }
    ) => {
      if (type === TYPE.APPROVE && !txhash) {
        return new Promise((resolve, reject) => reject()).catch(() => {
          throw new SubmissionError({ txhash: 'error.form.required' });
        });
      }

      if (type === TYPE.DISAPPROVE && !comment) {
        return new Promise((resolve, reject) => reject()).catch(() => {
          throw new SubmissionError({ comment: 'error.form.required' });
        });
      }

      const promise =
        type === TYPE.CANCEL
          ? cancelWithdraw({
              variables: {
                comment,
                withdrawId: parseInt(withdrawId, 10)
              }
            })
          : finishWithdraw({
              variables: {
                comment,
                txhash,
                withdrawId: parseInt(withdrawId, 10)
              }
            });

      return promise
        .then(() => closeModal(WITHDRAW_REQUEST_MODAL_ID))
        .catch(errorHandler);
    }
  })
)(WithdrawsForm);

export type WithdrawsFormPropTypes = {
  accountantMode: boolean
};
