import { get } from 'lodash';
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Responsive from 'react-responsive';

// Components
import Block from '@components/Block';
import Button from '@components/Button';

import Field from './components/Field';
import Table from './components/Table';

// Constants
import { IN } from '@constants/purposes';
import { PENDING } from '@constants/statuses';

// GraphQL
import getTransactionsQuery from '@graphql/getTransactions.graphql';

// Style
import style from './Transactions.scss';

const Transactions = ({
  id = 0,
  limit,
  onMoreClick,
  pageSize = 10,
  showPagination = true
}): React.Element<typeof Query> => (
  <Query
    partialRefetch
    pollInterval={30000}
    query={getTransactionsQuery}
    variables={{ id }}
  >
    {({ data, loading }): React.Element<typeof Block> => {
      const currentUserId = get(data, 'currentUser.id', -1);
      const currencies: [Object] = get(data, 'getCurrencies', []);
      const transactions: [Object] = get(data, 'getTransactions', [])
        .sort(({ createdAt: a }, { createdAt: b }) =>
          a < b ? 1 : a > b ? -1 : 0)
        .slice(0, limit || get(data, 'getTransactions.length', 0));

      const formattedTransactions: [Object] = transactions.map(item => {
        const { status, type } = item;
        const { currencyTo } = item.to;
        const newItem = item;

        if (status === PENDING && type === IN) {
          newItem.confirmationsNeeded = get(
            currencies.find(({ id }) => id === currencyTo),
            'confirmations'
          );
        }

        return newItem;
      });

      return (
        <Block
          className={style.Root}
          title={
            <FormattedMessage
              defaultMessage="Transaction List ({count})"
              id="transactions.title"
              values={{ count: formattedTransactions.length }}
            />
          }
        >
          <Responsive minWidth={769}>
            <Table
              currentUserId={currentUserId}
              data={formattedTransactions}
              loading={loading}
              pageSize={pageSize}
              showPagination={!limit && showPagination}
            />
          </Responsive>

          <Responsive maxWidth={768}>
            {formattedTransactions.map(
              (transaction, index: number): React.Element<typeof Field> => (
                <Field
                  {...transaction}
                  key={index}
                  currentUserId={currentUserId}
                />
              )
            )}
          </Responsive>

          {limit && (
            <div className={style.Actions}>
              <Button fullWidth onClick={onMoreClick}>
                <FormattedMessage
                  defaultMessage="Show more transactions"
                  id="dashboard.transactions.more"
                />
              </Button>
            </div>
          )}
        </Block>
      );
    }}
  </Query>
);

export default Transactions;
