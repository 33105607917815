import Cookies from 'js-cookie';

// Constants
import {
  REFRESH_TOKEN_COOKIE_ID,
  TOKEN_COOKIE_ID
} from '@services/session/constants';

// Types
import { LOGOUT, SET_TOKEN } from '@services/session/types';

const initialValues = {
  refreshToken: Cookies.get(REFRESH_TOKEN_COOKIE_ID),
  token: Cookies.get(TOKEN_COOKIE_ID)
};

export default (
  state: SessionStateTypes = initialValues,
  action: SessionActionType = {}
): SessionStateTypes => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        refreshToken: null,
        token: null
      };
    case SET_TOKEN:
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token
      };
    default:
      return state;
  }
};

export type SessionActionType = {
  payload: number | string | Object,
  type: string
};

export type SessionStateTypes = {
  refreshToken?: string,
  token?: string
};
