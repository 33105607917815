import { set } from 'lodash';
import { SubmissionError } from 'redux-form';

export default ({ graphQLErrors, message }): void => {
  const errors = {};

  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach(({ message }) => {
      set(errors, '_error', message);
    });
  } else if (message) {
    set(errors, '_error', message);
  }

  throw new SubmissionError(errors);
};
