import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';

// Components
import Block from './components/Block';

// Containers
import Accounts from './containers/Accounts';
import Links from './containers/Links';
import Ref from './containers/Ref';
import Sponsor from './containers/Sponsor';
import Summary from './containers/Summary';

// Contexts
import CurrentUserContext from '@contexts/CurrentUser';

// GraphQL
import getRefQuery from '@graphql/getRef.graphql';
import getSponsorQuery from '@graphql/getSponsor.graphql';
import getSummaryQuery from '@graphql/getSummary.graphql';

// Modals
import ChangeSponsor from './modals/ChangeSponsor';

// Style
import style from './Dashboard.scss';

// Views
import Products from '@views/Products';
import Transactions from '@views/Transactions';

const Dashboard = ({ history }) => (
  <CurrentUserContext.Consumer>
    {({ currentUser }) => (
      <div className={style.Root}>
        <Links />

        <div className={classNames(style.Accounts, style.Row)}>
          <Accounts />
        </div>

        <div className={style.Row}>
          <Products widget />
        </div>

        <div className={style.Row}>
          <Block
            className={style.Sponsor}
            component={Sponsor}
            id="sponsor"
            query={getSponsorQuery}
            show={data => !!get(data, 'currentUser.sponsor')}
            title="Sponsor"
          />

          <Block
            className={style.Ref}
            component={Ref}
            id="ref"
            query={getRefQuery}
            title="Invite new partners"
          />

          <Block
            className={style.Summary}
            component={Summary}
            id="summary"
            query={getSummaryQuery}
            subTitle={get(currentUser, 'plan.name')}
            title="Monthly Goal"
          />
        </div>

        <div className={style.Row}>
          <Transactions
            limit={5}
            onMoreClick={() => history.push('/transactions')}
          />
        </div>

        <ChangeSponsor />
      </div>
    )}
  </CurrentUserContext.Consumer>
);

export default Dashboard;
