import { get } from 'lodash';
import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, { asyncValidator, Input } from '@components/Form';

// Ducks
import { CONFIRM_FORM_ID, CONFIRM_MODAL_ID } from '../ducks';

// GraphQL
import confirmBuyMutation from '@graphql/confirmBuy.graphql';
import confirmTransferMutation from '@graphql/confirmTransfer.graphql';
import confirmWithdrawMutation from '@graphql/confirmWithdraw.graphql';
import getAccountsQuery from '@graphql/getAccounts.graphql';
import getTransactionsQuery from '@graphql/getTransactions.graphql';
import getWithdrawRequestsQuery from '@graphql/getWithdrawRequests.graphql';

// Services
import { closeModal, closeModals } from '@services/modals';

// Style
import style from './Form.scss';

const PartnersConfirm = ({
  error,
  handleCancelClick,
  handleSubmit,
  isFailed,
  submitting,
  tries
}) => (
  <Form
    error={
      error && (
        <FormattedMessage
          defaultMessage={error}
          id={error}
          values={{ tries }}
        />
      )
    }
    onSubmit={handleSubmit}
  >
    {!isFailed && <Input label="common.field.code" name="code" />}

    <div className={style.Actions}>
      {isFailed ? (
        <Button fullWidth onClick={handleCancelClick}>
          <FormattedMessage defaultMessage="Cancel" id="common.action.close" />
        </Button>
      ) : (
        <Button fullWidth loaded={submitting} type="submit">
          <FormattedMessage defaultMessage="Confirm" id="sign.confirm.action" />
        </Button>
      )}
    </div>
  </Form>
);

export default compose(
  withRouter,
  connect(
    null,
    { closeModal, closeModals }
  ),
  graphql(confirmBuyMutation, { name: 'confirmBuy' }),
  graphql(confirmTransferMutation, { name: 'confirmTransfer' }),
  graphql(confirmWithdrawMutation, { name: 'confirmWithdraw' }),
  withState('isFailed', 'setFailState', false),
  withState('tries', 'setTriesCount', 0),
  withHandlers({
    handleCancelClick: ({ closeModal }) => () => closeModal(CONFIRM_MODAL_ID)
  }),
  reduxForm({
    form: CONFIRM_FORM_ID,
    asyncValidate: asyncValidator(
      yup.object().shape({
        code: yup.string().required()
      })
    ),
    onSubmit: (
      { userId, ...values },
      dispatch,
      {
        buyMode,
        closeModal,
        closeModals,
        confirmBuy,
        confirmTransfer,
        confirmWithdraw,
        history,
        setFailState,
        setTriesCount,
        withdrawMode
      }
    ) => {
      const mutate = buyMode
        ? confirmBuy
        : withdrawMode
        ? confirmWithdraw
        : confirmTransfer;

      return mutate({
        refetchQueries: [
          {
            query: getWithdrawRequestsQuery,
            variables: { id: parseInt(userId, 10) }
          },
          {
            query: getTransactionsQuery,
            variables: { id: 0 }
          },
          {
            query: getAccountsQuery
          }
        ],
        variables: values
      }).then(({ data }) => {
        const mutation = buyMode
          ? 'confirmBuy'
          : withdrawMode
          ? 'confirmWithdraw'
          : 'confirmTransfer';

        const tries = get(data, `${mutation}.confirm.tries`, 0);
        const tx = get(data, `${mutation}.tx`);
        const withdraw = get(data, `${mutation}.withdraw`);

        if (!tx && !withdraw) {
          if (tries <= 0) {
            setFailState(true);

            throw new SubmissionError({
              _error: `confirm.form.error.fail`
            });
          } else {
            setTriesCount(tries);

            throw new SubmissionError({
              _error: 'confirm.form.error.tries'
            });
          }
        } else {
          closeModals();
          history.push(withdraw ? '/withdraws' : '/transactions');
        }
      });
    }
  })
)(PartnersConfirm);
