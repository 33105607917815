import { get } from 'lodash';
import QRCode from 'qrcode.react';
import * as React from 'react';
import { graphql, Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Responsive from 'react-responsive';
import { compose, withHandlers, withState } from 'recompose';

// Components
import Account from '../components/Account';
import Button from '@components/Button';
import Modal from '@components/Modal';

// Constants
import { BASE } from '@constants/purposes';

// Ducks
import { BUY_MODAL_ID } from '../ducks';

// GraphQL
import createAccountMutation from '@graphql/createAccount.graphql';
import getAccountsQuery from '@graphql/getAccounts.graphql';

// Style
import style from './Accounts.scss';

const DashboardAccounts = ({
  tempAddress,
  // Handlers
  handleCopyClick,
  handleClose,
  handleCreateClick,
  // State
  isCopied,
  isLoaded
}: DashboardAccountsPropTypes): React.Element<typeof Query> => (
  <Query query={getAccountsQuery}>
    {({ data }): React.Element<'div'> => {
      const accounts: [] = get(data, 'getAccounts', []);
      const currencies: [] = get(data, 'getCurrencies', []);

      const formattedAccounts: [] = currencies
        .filter(({ active }) => !!active)
        .map(({ id, ...currency }) => ({
          ...accounts.find(({ currencyId }) => currencyId === id),
          ...currency,
          currencyId: id
        }))
        .filter(({ purposes }) => purposes.indexOf(BASE) === -1);

      return (
        <div className={style.Root}>
          {formattedAccounts.map((account: Object, index: number) => (
            <React.Fragment key={index}>
              <Responsive minWidth={768}>
                <Account {...account} currencies={formattedAccounts} />
              </Responsive>

              <Responsive maxWidth={767}>
                <Account
                  {...account}
                  currencies={formattedAccounts}
                  variant="mobile"
                />
              </Responsive>
            </React.Fragment>
          ))}

          <Modal id={BUY_MODAL_ID} onClose={handleClose}>
            {({ address: propAddress, currencyId, name }) => {
              const address = tempAddress || propAddress;

              return (
                <div className={style.Modal}>
                  <div className={style.Title}>
                    <FormattedMessage
                      defaultMessage="Buy ODDB for {currencyId}"
                      id="dashboard.accounts.title"
                      values={{ currencyId: name }}
                    />
                  </div>

                  <div className={style.Description}>
                    <FormattedMessage
                      defaultMessage="To buy {currencyId}, transfer tokens to this address"
                      id="dashboard.accounts.description"
                      values={{ currencyId: name }}
                    />
                  </div>

                  {address ? (
                    <React.Fragment>
                      <div className={style.QRCode}>
                        <QRCode size={120} value={address} />
                      </div>

                      <div className={style.Divider}>
                        <div className={style.Or}>
                          <FormattedMessage
                            defaultMessage="OR"
                            id="dashboard.accounts.or"
                          />
                        </div>
                      </div>

                      <div className={style.Address}>
                        <Button
                          icon="fal fa-copy"
                          onClick={() => handleCopyClick(address)}
                        >
                          <FormattedMessage
                            defaultMessage={isCopied ? 'Copied!' : address}
                            id={isCopied ? 'common.copied' : address}
                          />
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <Button
                      className={style.Create}
                      fullWidth
                      icon="fal fa-eye"
                      loaded={isLoaded}
                      onClick={() => handleCreateClick(currencyId)}
                    >
                      <FormattedMessage
                        defaultMessage="Show Address"
                        id="dashboard.accounts.show"
                      />
                    </Button>
                  )}
                </div>
              );
            }}
          </Modal>
        </div>
      );
    }}
  </Query>
);

export default compose(
  graphql(createAccountMutation, { name: 'createAccount' }),
  withState('isCopied', 'setCopyState', false),
  withState('isLoaded', 'setLoadStatus', false),
  withState('tempAddress', 'setTempAddress', null),
  withHandlers({
    handleCopyClick: ({ setCopyState }): Function => (
      address: string
    ): void => {
      const $input = document.createElement('input');

      $input.value = address;
      document.body.appendChild($input);

      $input.select();
      $input.setSelectionRange(0, 99999);

      document.execCommand('copy');
      document.body.removeChild($input);

      setCopyState(true);
      setTimeout(() => setCopyState(false), 1000);
    },
    handleClose: ({ setLoadStatus, setTempAddress }): Function => (): void => {
      setLoadStatus(false);
      setTempAddress(null);
    },
    handleCreateClick: ({ createAccount, setLoadStatus, setTempAddress }) => (
      currencyId: string
    ): void => {
      setLoadStatus(true);

      createAccount({
        refetchQueries: [{ query: getAccountsQuery }],
        variables: { currencyId }
      }).then(({ data }) => {
        const address: string = get(data, 'createAccount.address');

        setLoadStatus(false);
        setTempAddress(address);
      });
    }
  })
)(DashboardAccounts);

export type DashboardAccountsPropTypes = {
  // Handlers
  handleCopyClick: Function,
  handleClose: Function,
  handleCreateClick: Function,
  isCopied: boolean,
  isLoaded: boolean,
  tempAddress: ?string
};
