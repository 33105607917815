import * as React from 'react';

// Components
import Modal from '@components/Modal';

// Ducks
import { DOCS_MODAL_ID } from './ducks';

// Style
import style from './Docs.scss';

const Docs = () => (
  <Modal
    classNames={{ container: style.Container, title: style.Title }}
    id={DOCS_MODAL_ID}
  >
    {({ src, title }) => (
      <div className={style.Root}>
        <iframe height="100%" src={src} title={title} width="100%" />
      </div>
    )}
  </Modal>
);

export default Docs;
