import moment from 'moment';
import * as React from 'react';
import { graphql, Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';

// Components
import Button from '@components/Button';
import Form, {
  asyncValidator,
  errorHandler,
  Input,
  Select
} from '@components/Form';
import File from '../components/File';
import Group from '../components/Group';

// Constants
import { VERIFICATION_TYPE_FULL } from '@constants/verification';

// Containers
import { VERIFY_MODAL_ID } from '@containers/Verify';

// Graphql
import getVerificationsQuery from '@graphql/getVerifications.graphql';
import updateVerifyMutation from '../graphql/updateVerify.graphql';

// Ducks
import {
  SETTINGS_VERIFY_FORM_ID,
  VERIFY_TYPE_DRIVER_LICENSE,
  VERIFY_TYPE_FOREIN_PASSPORT,
  VERIFY_TYPE_OTHER,
  VERIFY_TYPE_PASSPORT
} from '../ducks';

// Services
import { openModal } from '@services/modals';

// Style
import style from './common.scss';

const TYPES = [
  {
    id: VERIFY_TYPE_PASSPORT,
    title: 'Passport'
  },
  {
    id: VERIFY_TYPE_DRIVER_LICENSE,
    title: 'Driver license'
  },
  {
    id: VERIFY_TYPE_FOREIN_PASSPORT,
    title: 'Forein passport'
  },
  {
    id: VERIFY_TYPE_OTHER,
    title: 'Other'
  }
];

const SettingsVerifyForm = ({
  error,
  handleFullVerifyClick,
  handleSubmit,
  submitting
}: SettingsVerifyFormPropTypes): React.Element<typeof Form> => (
  <Query query={getVerificationsQuery}>
    {({ data, loading }) => (
      <Form
        classNames={{ error: style.Error }}
        error={error}
        onSubmit={handleSubmit}
      >
        <Group icon="fal fa-passport" title="settings.verify.group.document">
          <Select
            disabled={submitting}
            label="settings.field.document_type"
            name="documentType"
          >
            {TYPES.map(({ id, title }) => (
              <FormattedMessage
                key={id}
                defaultMessage={title}
                id={`settings.verify.type.${id.toLowerCase()}`}
              >
                {title => <option value={id}>{title}</option>}
              </FormattedMessage>
            ))}
          </Select>

          <Input
            disabled={submitting}
            label="settings.field.document_number"
            name="documentNumber"
          />

          <Input
            disabled={submitting}
            label="settings.field.document_till"
            mask="99.99.9999"
            name="documentTill"
          />
        </Group>

        <div className={style.Documents}>
          <File
            icon="fal fa-portrait"
            info="settings.verify.files.photo.info"
            label="settings.verify.files.photo.title"
            name="photo"
          />

          <File
            icon="fal fa-passport"
            info="settings.verify.files.document.info"
            label="settings.verify.files.document.title"
            name="document"
          />

          <File
            icon="fal fa-check"
            info="settings.verify.files.document_with_face.info"
            label="settings.verify.files.document_with_face.title"
            name="documentWithFace"
          />

          <File
            icon="fal fa-check-double"
            info="settings.verify.files.document_address.info"
            label="settings.verify.files.document_address.title"
            name="documentAddress"
          />
        </div>

        <div className={style.Actions}>
          <Button
            color="success"
            icon="far fa-badge-check"
            loaded={submitting}
            onClick={handleFullVerifyClick}
          >
            <FormattedMessage
              defaultMessage="Full verify"
              id="settings.verify.full"
            />
          </Button>

          <Button loaded={submitting} type="submit">
            <FormattedMessage
              defaultMessage="Submit"
              id="settings.verify.submit"
            />
          </Button>
        </div>
      </Form>
    )}
  </Query>
);

export default compose(
  connect(
    null,
    { openModal }
  ),
  graphql(updateVerifyMutation, { name: 'updateVerify' }),
  withHandlers({
    handleFullVerifyClick: ({ openModal }) => () =>
      openModal(VERIFY_MODAL_ID, {
        title: 'verify.modal.title.full',
        type: VERIFICATION_TYPE_FULL
      })
  }),
  reduxForm({
    form: SETTINGS_VERIFY_FORM_ID,
    asyncValidate: asyncValidator(
      yup.object().shape({
        document: yup.mixed(),
        documentAddress: yup.mixed(),
        documentNumber: yup
          .string()
          .nullable()
          .required('error.form.required'),
        documentWithFace: yup.mixed(),
        documentTill: yup
          .string()
          .nullable()
          .test(
            'dateValidate',
            'error.form.date',
            (value: string): boolean =>
              !value || /^[0-9]{2}.[0-9]{2}.[0-9]{4}$/.test(value)
          )
          .test(
            'date',
            'error.form.date',
            (value: string): boolean =>
              !value || moment(value, 'DD.MM.YYYY').isValid()
          ),
        documentType: yup
          .string()
          .nullable()
          .required('error.form.required')
      })
    ),
    onSubmit: (
      { documentTill, ...values },
      dispatch,
      { updateVerify }
    ): Promise => {
      const files = [
        'avatar',
        'document',
        'documentAddress',
        'documentWithFace',
        'photo'
      ];
      const filteredValues = {};

      Object.keys(values).forEach(key => {
        const value = values[key];

        if (files.indexOf(key) > -1) {
          if (value && typeof value !== 'number') {
            if (!filteredValues.files) {
              filteredValues.files = [];
            }

            filteredValues.files.push({
              file: value,
              purpose:
                key === 'documentAddress'
                  ? 'DOCUMENT_ADDRESS'
                  : key === 'documentWithFace'
                  ? 'DOCUMENT_WITH_FACE'
                  : key.toUpperCase()
            });
          }
        } else {
          filteredValues[key] = value;
        }
      });

      if (documentTill) {
        filteredValues.documentTill = moment(documentTill, 'DD.MM.YYYY').format(
          'YYYY-MM-DD'
        );
      }

      return (
        updateVerify({
          variables: filteredValues
        })
          // eslint-disable-next-line
          .then(console.log)
          .catch(errorHandler)
      );
    },

    shouldAsyncValidate: () => true
  })
)(SettingsVerifyForm);

export type SettingsVerifyFormPropTypes = {
  error: ?string,
  handleSubmit: Function,
  submitting: boolean
};
